import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { resizeImage } from '../../../utils/image';
import Cropper from '../Cropper';
import Button from '../../button/Button';
import ButtonGroup from '../../button/ButtonGroup';
import TitleModal from '../../modal/TitleModal';

const CropImageModal = ({
  src,
  mime,
  alt,
  width,
  height,
  cropBoxResizable = false,
  onCrop,
  onClose,
  close,
}) => {
  const { t } = useTranslation();
  const [instance, setIntance] = useState(null);
  const [resizedImage, setResizedImage] = useState(null);

  const handleCrop = useCallback(() => {
    onCrop(instance.getCroppedCanvas().toDataURL());
    close();
  }, [onCrop, instance, close]);

  const handleInitialized = useCallback((instance) => {
    setIntance(instance);
  }, []);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    close();
  };

  useEffect(() => {
    resizeImage(
      src,
      mime,
      width > height ? width : height,
      'cover',
      (resizedImageUrl) => {
        setResizedImage(resizedImageUrl);
      },
    );
  }, [src, mime, height, width]);

  return (
    <>
      <TitleModal.Header handleClose={handleClose}>
        {t('CropImage')}
      </TitleModal.Header>
      <TitleModal.Body>
        {resizedImage && (
          <Cropper
            src={resizedImage}
            alt={alt}
            width={width}
            height={height}
            cropBoxResizable={cropBoxResizable}
            onInitialized={handleInitialized}
          />
        )}
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={handleClose}>
            {t('button:Cancel')}
          </Button>
          <Button success onClick={handleCrop}>
            {t('button:Confirm')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

export default CropImageModal;
