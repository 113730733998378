import { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { Button, ButtonGroup } from '../../components/button';
import {
  FormItem,
  Input,
  Label,
  Textarea,
  Checkbox,
  Searchbox,
} from '../../components/form';
import { Column, Grid } from '../../components/grid';
import { Heading1, Heading2 } from '../../components/heading';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionToolbar,
} from '../../components/section';
import { Table } from '../../components/table';
import { showErrorMessage, showSuccessMessage } from '../../module/message';
import {
  getTerminalGroupInfo,
  getTerminalOfGroup,
  modifyTerminalGroup,
  getTerminalList,
} from '../../module/terminal';
import handleApiResponse from '../../utils/api/handleApiResponse';

const TerminalGroupDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { userInformation } = useContext(AuthenticateContext);
  const [isOnModify, setIsOnModify] = useState(false);
  const [data, setData] = useState({
    name: '',
    remarks: '',
  });
  const [groupList, setGroupList] = useState([]);
  const [groupListSelected, setGroupListSelected] = useState([]);
  const [groupListTotal, setGroupListTotal] = useState(1);
  const [groupListParams, setGroupListParams] = useState({
    page: 1,
    limit: 10,
    sn: '',
  });
  const [allList, setAllList] = useState([]);
  const [allListSelected, setAllListSelected] = useState([]);
  const [allListTotal, setAllListTotal] = useState(1);
  const [allListParams, setAllListParams] = useState({
    page: 1,
    limit: 10,
    sn: '',
  });

  const handleChangeValue = (value, key) => {
    setData((previous) => {
      const newData = { ...previous };
      newData[key] = value;

      return newData;
    });
  };

  const handleMove = () => {
    if (allListSelected.length === 0) {
      showErrorMessage({ message: t('error:PleaseSelectAtLeastOneTerminal') });
      return;
    }

    const groupListIds = groupList.map((group) => group.id);
    const validAllListSelectedIds = allListSelected.filter(
      (groupId) => !groupListIds.includes(groupId),
    );

    const newGroupList = [
      ...groupList,
      ...allList.filter((terminal) => {
        return validAllListSelectedIds.includes(terminal.id);
      }),
    ];

    setGroupList(newGroupList);
  };

  const handleRemove = () => {
    if (groupListSelected.length === 0) {
      showErrorMessage({ message: t('error:PleaseSelectAtLeastOneTerminal') });
      return;
    }

    const newGroupList = groupList.filter((terminal) => {
      return !groupListSelected.includes(terminal.id);
    });

    setGroupList(newGroupList);
  };

  const handleModify = () => {
    if (!data.name) {
      showErrorMessage({ message: t('error:PleaseFillUpName') });
      return;
    }

    if (groupList.length === 0) {
      showErrorMessage({ message: t('error:PleaseSelectAtLeastOneTerminal') });
      return;
    }

    const terminals = groupList.map((terminal) => {
      return terminal.id;
    });

    const requestData = {
      id: parseInt(id),
      name: data.name.trim(),
      terminals: terminals,
    };

    if (data.remarks) {
      requestData.remarks = data.remarks.trim();
    }

    handleApiResponse(modifyTerminalGroup(requestData), () => {
      handleGetTerminalOfGroupList();
      handleGetTerminalGroupInfo();
      setIsOnModify(false);
      showSuccessMessage({ message: t('success:ModifyTerminalGroup') });
    });
  };

  // get all terminals list
  const handleGetAllTerminalList = useCallback(() => {
    const requestData = {
      page: allListParams.page,
      limit: allListParams.limit,
    };

    if (allListParams.sn) {
      requestData.sn = allListParams.sn;
    }

    handleApiResponse(getTerminalList(requestData), (response) => {
      const { list, total } = response.data.data;
      setAllList(list);
      setAllListTotal(total);
    });
  }, [allListParams]);

  // get group terminals list
  const handleGetTerminalOfGroupList = useCallback(() => {
    const requestData = {
      terminalGroupId: id,
      page: groupListParams.page,
      limit: groupListParams.limit,
    };

    if (groupListParams.sn) {
      requestData.sn = groupListParams.sn;
    }

    handleApiResponse(getTerminalOfGroup(requestData), (response) => {
      const { list, total } = response.data.data;
      setGroupList(list);
      setGroupListTotal(total);
    });
  }, [id, groupListParams]);

  // get terminals group info
  const handleGetTerminalGroupInfo = useCallback(() => {
    handleApiResponse(getTerminalGroupInfo(id), (response) => {
      const { data } = response.data;
      setData(data);
    });
  }, [id]);

  // get all terminals list
  useEffect(() => {
    handleGetAllTerminalList();
  }, [handleGetAllTerminalList]);

  // get group terminals list
  useEffect(() => {
    handleGetTerminalOfGroupList();
  }, [handleGetTerminalOfGroupList]);

  // get terminals group info
  useEffect(() => {
    handleGetTerminalGroupInfo();
  }, [handleGetTerminalGroupInfo]);

  return (
    <>
      <Section noPadding>
        <SectionHeader>
          <Heading1>{t('TerminalGroup')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionBody noPadding>
              <Grid columns={12}>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='name' required={isOnModify}>
                      {t('Name')}
                    </Label>
                    <Input
                      id='name'
                      type='text'
                      value={data.name}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'name')
                      }
                      maxLength='64'
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='description'>{t('Description')}</Label>
                    <Textarea
                      id='description'
                      rows='4'
                      value={data.remarks}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'remarks')
                      }
                      maxLength='128'
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={5} laptop={12}>
                  <TerminalList
                    title={t('GroupTerminals')}
                    isOnModify={isOnModify}
                    data={groupList}
                    total={groupListTotal}
                    listParams={groupListParams}
                    setListParams={setGroupListParams}
                    selectedTerminal={groupListSelected}
                    setSelectedTerminal={setGroupListSelected}
                  />
                </Column>
                <Column desktop={2} laptop={12}>
                  <ButtonGroup vertical height100 alignCenter>
                    <Button onClick={handleMove} disabled={!isOnModify}>
                      <FaAngleDoubleLeft />
                      {t('button:Attach')}
                    </Button>
                    <Button
                      grey
                      alignRight
                      onClick={handleRemove}
                      disabled={!isOnModify}
                    >
                      {t('button:Detach')}
                      <FaAngleDoubleRight />
                    </Button>
                  </ButtonGroup>
                </Column>
                <Column desktop={5} laptop={12}>
                  <TerminalList
                    title={t('AllTerminals')}
                    isOnModify={isOnModify}
                    data={allList}
                    total={allListTotal}
                    listParams={allListParams}
                    setListParams={setAllListParams}
                    selectedTerminal={allListSelected}
                    setSelectedTerminal={setAllListSelected}
                  />
                </Column>
              </Grid>
            </SectionBody>
          </Section>
        </SectionBody>
        <ButtonGroup alignRight>
          {isOnModify ? (
            <>
              <Button
                danger
                onClick={() => {
                  setIsOnModify(false);
                }}
              >
                {t('button:Cancel')}
              </Button>
              <Button success onClick={handleModify}>
                {t('button:Save')}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => navigate(-1)}>{t('button:Back')}</Button>
              {userInformation.permissions.includes(
                'terminal_group.update',
              ) && (
                <Button
                  warning
                  onClick={() => {
                    setIsOnModify(true);
                  }}
                >
                  {t('button:Modify')}
                </Button>
              )}
            </>
          )}
        </ButtonGroup>
      </Section>
    </>
  );
};

const TerminalList = ({
  title,
  isOnModify,
  data,
  total,
  listParams,
  setListParams,
  selectedTerminal,
  setSelectedTerminal,
}) => {
  const { t } = useTranslation();
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const handleSelectedChange = useCallback(
    (id) => {
      const newSelectedTerminal = [...selectedTerminal];
      const index = selectedTerminal.indexOf(id);
      if (index === -1) {
        newSelectedTerminal.push(id);
      } else {
        newSelectedTerminal.splice(index, 1);
      }

      setSelectedTerminal(newSelectedTerminal);
    },
    [selectedTerminal, setSelectedTerminal],
  );

  const handleSelectAll = useCallback(() => {
    if (isSelectedAll) {
      setSelectedTerminal([]);
    } else {
      const newSelectedTerminal = [];
      data.forEach((terminal) => {
        newSelectedTerminal.push(terminal.id);
      });
      setSelectedTerminal(newSelectedTerminal);
    }
    setIsSelectedAll(!isSelectedAll);
  }, [data, isSelectedAll, setSelectedTerminal]);

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: (
          <Checkbox
            noMargin
            onChange={handleSelectAll}
            checked={isSelectedAll}
            disabled={!isOnModify}
          />
        ),
        fieldName: 'id',
        sortable: false,
        width: '15%',
        render(data) {
          return (
            <Checkbox
              noMargin
              onChange={() => {
                handleSelectedChange(data);
              }}
              checked={selectedTerminal.includes(data)}
              disabled={!isOnModify}
            />
          );
        },
      },
      {
        title: t('HardwareSerialNumber'),
        fieldName: 'sn',
        sortable: false,
        width: '30%',
      },
    ];
    return columns;
  }, [
    t,
    isOnModify,
    handleSelectAll,
    handleSelectedChange,
    isSelectedAll,
    selectedTerminal,
  ]);

  return (
    <Section>
      <SectionHeader inline>
        <Heading2 whiteColor>{title}</Heading2>
        <SectionToolbar>
          <Searchbox
            border
            disabled={!isOnModify}
            onSearch={(keyword) => {
              setListParams((previousParams) => {
                return {
                  ...previousParams,
                  page: 1,
                  sn: keyword,
                };
              });
            }}
          />
        </SectionToolbar>
      </SectionHeader>
      <SectionBody>
        <Table
          columns={tableColumns}
          data={data}
          currentPage={listParams.page}
          limit={listParams.limit}
          limitChange={(limit) => {
            setListParams((previousParams) => {
              return {
                ...previousParams,
                page: 1,
                limit: limit,
              };
            });
          }}
          total={total}
          pageChange={(page) => {
            setListParams((previousParams) => {
              return {
                ...previousParams,
                page: page,
              };
            });
          }}
          select={{ type: 'multiple', matcher: 'id' }}
          selected={selectedTerminal}
        />
      </SectionBody>
    </Section>
  );
};

export default TerminalGroupDetail;
