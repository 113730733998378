import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage } from '../../module/message';
import { createBottlerGroup } from '../../module/bottler';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { Heading1 } from '../../components/heading';
import { Section, SectionBody, SectionHeader } from '../../components/section';
import { Grid, Column } from '../../components/grid';
import { FormItem, Input, Label, Textarea } from '../../components/form';
import { Button, ButtonGroup } from '../../components/button';

const CreateBottlerGroup = () => {
  const { t: trans } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: '',
    remark: '',
  });

  const handleChangeValue = (value, key) => {
    setData((previous) => {
      const newData = { ...previous };
      newData[key] = value;

      return newData;
    });
  };

  const handleCreate = () => {
    if (!data.name.trim()) {
      showErrorMessage({ message: trans('error:PleaseFillUpName') });
      return;
    }

    const requestData = {
      name: data.name.trim(),
    };

    if (data.remark) {
      requestData.remark = data.remark;
    }

    handleApiResponse(createBottlerGroup(requestData), () => {
      showSuccessMessage({ message: trans('success:CreateBottlerGroup') });
      navigate(-1);
    });
  };

  return (
    <>
      <Section noPadding>
        <SectionHeader>
          <Heading1>{trans('CreateBottlerGroup')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionBody noPadding>
              <Grid columns={12}>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='name' required>
                      {trans('Name')}
                    </Label>
                    <Input
                      id='name'
                      type='text'
                      value={data.name}
                      onChange={(event) =>
                        handleChangeValue(event.target.value, 'name')
                      }
                      maxLength='100'
                    />
                  </FormItem>
                </Column>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='description'>{trans('Description')}</Label>
                    <Textarea
                      id='description'
                      rows='4'
                      value={data.remark}
                      onChange={(event) =>
                        handleChangeValue(event.target.value, 'remark')
                      }
                      maxLength='200'
                    />
                  </FormItem>
                </Column>
              </Grid>
            </SectionBody>
          </Section>
        </SectionBody>
        <ButtonGroup alignRight>
          <Button
            danger
            onClick={() => {
              navigate(-1);
            }}
          >
            {trans('button:Cancel')}
          </Button>
          <Button success onClick={handleCreate}>
            {trans('button:Create')}
          </Button>
        </ButtonGroup>
      </Section>
    </>
  );
};

export default CreateBottlerGroup;
