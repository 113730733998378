import { useState, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FaRegArrowAltCircleDown } from 'react-icons/fa';
import {
  getTerminalImportTemplate,
  importTerminal,
} from '../../module/terminal';
import { downloadFile } from '../../module/download';
import { showErrorMessage, showSuccessMessage } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { Heading1 } from '../../components/heading';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionToolbar,
} from '../../components/section';
import { FileDropzone } from '../../components/dropzone';
import { Button, ButtonGroup } from '../../components/button';

const ImportTerminal = () => {
  const { t: trans } = useTranslation();
  const navigate = useNavigate();
  const [cancelToken, setCancelToken] = useState(null);
  const [dropzoneState, setDropzoneState] = useState({
    isUploading: false,
    isRemoving: false,
  });
  const [addedFile, setAddedFile] = useState(false);

  const handleDownloadTemplate = () => {
    handleApiResponse(getTerminalImportTemplate(), (response) => {
      const { url } = response.data.data;

      if (url) {
        handleApiResponse(downloadFile(url), (response) => {
          const urlSegment = url.split('/');
          const fileName = urlSegment[urlSegment.length - 1];
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
      }
    });
  };

  const handleAddedFile = useCallback(
    (file) => {
      const format = ['.xlsx'];
      const reg = file.name.match(/\.\w+$/);
      if (!format.includes(reg[0].toLowerCase())) {
        showErrorMessage({
          title: trans('error:InputError'),
          message: trans('error:ImportTerminalFileType'),
        });

        setDropzoneState((previous) => {
          return { ...previous, isRemoving: true };
        });
        return;
      }

      setAddedFile(true);
    },
    [trans],
  );

  const handleRemovedFile = useCallback(() => {
    setAddedFile(false);
    setDropzoneState((previous) => {
      return { ...previous, isRemoving: false };
    });
  }, []);

  const handleCancelFile = useCallback(() => {
    cancelToken.cancel();
    setAddedFile(false);
    setDropzoneState((previous) => {
      return { ...previous, isUploading: false };
    });
  }, [cancelToken]);

  const handleUploading = useCallback(
    (chunk) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', chunk);

        importTerminal(formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          cancelToken: cancelToken.token,
          onUploadProgress: (progressEvent) => {
            if (progressEvent.loaded >= progressEvent.total) {
              resolve();
            }
          },
        })
          .then((response) => {
            if (response) {
              showSuccessMessage({ message: trans('success:Import') });
              navigate(-1);
            } else {
              setDropzoneState((previous) => {
                return { ...previous, isRemoving: true };
              });
            }
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              reject();
              showErrorMessage({ title: error.title, message: error.message });
            }
          });
      });
    },
    [trans, navigate, cancelToken],
  );

  const handleComplete = useCallback(() => {
    setDropzoneState((previous) => {
      return { ...previous, isUploading: false };
    });
  }, []);

  const handleError = useCallback(() => {
    setDropzoneState((previous) => {
      return { ...previous, isUploading: false };
    });
  }, []);

  return (
    <>
      <Section noPadding>
        <SectionHeader inline>
          <Heading1>{trans('ImportTerminal')}</Heading1>
          <SectionToolbar>
            <Button onClick={handleDownloadTemplate}>
              <FaRegArrowAltCircleDown />
              {trans('button:DownloadTemplateFile')}
            </Button>
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse>
            <SectionBody noPadding>
              <FileDropzone
                message={trans('DropzoneFile')}
                accept='.xlsx'
                translation={{
                  Cancel: trans('Cancel'),
                  FileIcon: trans('FileIcon'),
                  RemoveFile: trans('RemoveFile'),
                  Retry: trans('Retry'),
                }}
                chunking={false}
                state={dropzoneState}
                onAddedFile={handleAddedFile}
                onRemovedFile={handleRemovedFile}
                onCanceledFile={handleCancelFile}
                onUploading={handleUploading}
                onCompleted={handleComplete}
                onError={handleError}
              />
            </SectionBody>
          </Section>
        </SectionBody>
        <ButtonGroup alignRight>
          <Button
            danger
            onClick={() => {
              navigate(-1);
            }}
          >
            {trans('button:Cancel')}
          </Button>
          <Button
            success
            disabled={!addedFile}
            onClick={() => {
              setCancelToken(axios.CancelToken.source());
              setDropzoneState((previous) => {
                return { ...previous, isUploading: true };
              });
            }}
          >
            {trans('button:Import')}
          </Button>
        </ButtonGroup>
      </Section>
    </>
  );
};

export default ImportTerminal;
