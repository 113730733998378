import styled from 'styled-components';
import IconFigure from '../icon/IconFigure';
import IconButton from '../button/IconButton';
import { HiMenu } from 'react-icons/hi';

const MenuIcon = ({ toggleSidebar }) => {
  return (
    <Wrapper onClick={toggleSidebar}>
      <IconFigure>
        <HiMenu />
      </IconFigure>
    </Wrapper>
  );
};

const Wrapper = styled(IconButton)`
  margin: 0;
  margin-right: calc(var(--spacing) - 0.5rem);

  svg {
    color: var(--font-on-background);
    font-size: 24px;
  }
`;

export default MenuIcon;
