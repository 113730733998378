import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MdSettings } from 'react-icons/md';
import { getProfile } from '../../module/profile';
import handleApiResponse from '../../utils/api/handleApiResponse';
import {
  Section,
  SectionHeader,
  SectionBody,
  SectionToolbar,
} from '../../components/section';
import { Heading1, Heading2 } from '../../components/heading';
import { ImageFigure } from '../../components/figure';
import { ImageFluid } from '../../components/image';
import { Button } from '../../components/button';
import { Paragraph } from '../../components/text';
import defaultUserPic from '../../assets/images/default-user.jpg';

const Profile = () => {
  const { t: trans } = useTranslation();
  const [information, setInformation] = useState({
    username: '',
    email: '',
    telephone: '',
    roles: [],
    avatar: '',
  });

  useEffect(() => {
    handleApiResponse(getProfile(), (response) => {
      const { data } = response.data;
      setInformation({
        username: data.username,
        email: data.email,
        telephone: data.telephone,
        roles: data.roles,
        avatar: data.avatar,
      });
    });
  }, []);

  return (
    <>
      <Section noPadding>
        <SectionHeader>
          <Heading1>{trans('Profile')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <CustomSection noPadding>
            <SectionHeader>
              <Section backgroundReverse noPaddingTop>
                <CustomSectionBody>
                  <ImageFigure roundedCircle>
                    <ImageFluid
                      src={information.avatar || defaultUserPic}
                      roundedCircle
                    />
                  </ImageFigure>
                  <Container>
                    <Left>
                      <span className='name'>{information.username}</span>
                    </Left>
                  </Container>
                </CustomSectionBody>
              </Section>
            </SectionHeader>
            <SectionBody>
              <CustomSection noPaddingTop>
                <SectionHeader inline>
                  <Heading2>{trans('Information')}</Heading2>
                  <SectionToolbar>
                    <Link to={'/profile/update'}>
                      <Button warning>
                        <MdSettings />
                        {trans('button:Modify')}
                      </Button>
                    </Link>
                  </SectionToolbar>
                </SectionHeader>
                <SectionBody>
                  <Paragraph>
                    {trans('Email')}:&nbsp;{information.email}&nbsp;
                  </Paragraph>
                  <Paragraph>
                    {trans('Telephone')}:&nbsp;{information.telephone}
                  </Paragraph>
                  <Paragraph>
                    {trans('Role')}:&nbsp;
                    {information.roles.length > 0 && information.roles[0]}
                  </Paragraph>
                </SectionBody>
              </CustomSection>
            </SectionBody>
          </CustomSection>
        </SectionBody>
      </Section>
    </>
  );
};

const CustomSection = styled(Section)`
  background: var(--color-background3);
`;

const CustomSectionBody = styled(SectionBody)`
  display: flex;
  align-items: center;

  > :first-child {
    flex: 0 0 auto;
  }
`;

const Container = styled.div`
  display: flex;
  margin-left: 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Left = styled.div`
  flex: 0 0 50%;

  .name {
    display: block;
    font-size: var(--font-h3);
    font-weight: bold;
    line-height: 1.25;
  }
`;

export default Profile;
