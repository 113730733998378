import { useCallback } from 'react';

const useLimitChange = (setState) => {
  return useCallback(
    (limit) => {
      setState((previous) => {
        if (typeof previous === 'object') {
          return {
            ...previous,
            limit,
            page: 1,
          };
        }

        return limit;
      });
    },
    [setState],
  );
};

export default useLimitChange;
