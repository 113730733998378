import { Helmet } from 'react-helmet';

const DocumentTitleDescription = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name='description' content={description} />}
    </Helmet>
  );
};

export default DocumentTitleDescription;
