import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import escaperegexp from 'lodash.escaperegexp';
import {
  getAutomatedReportDetails,
  getAutomatedReportMailLogs,
} from '../../../module/report';
import { downloadFile } from '../../../module/download';
import handleApiResponse from '../../../utils/api/handleApiResponse';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionToolbar,
  SectionToolbarItem,
} from '../../../components/section';
import { Heading1 } from '../../../components/heading';
import { Input, Label } from '../../../components/form';
import { Button, ButtonLink, ButtonGroup } from '../../../components/button';
import { Table } from '../../../components/table';
import { useLimitChange, usePageChange } from '../../../components/table/hooks';

const AutomatedReportMailLogs = () => {
  const { t: trans } = useTranslation();
  const { automatedReportId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: '',
  });
  const [listData, setListData] = useState([]);
  const [filteredListData, setFilteredListData] = useState([]);
  const [currentListData, setCurrentListData] = useState([]);
  const [listParameters, setListParameters] = useState({
    page: 1,
    limit: 10,
    search: '',
  });
  const [keyword, setKeyword] = useState('');

  const onLimitChange = useLimitChange(setListParameters);
  const onPageChange = usePageChange(setListParameters);

  const handleGetAutomatedReportDetails = useCallback(() => {
    handleApiResponse(
      getAutomatedReportDetails(automatedReportId),
      (response) => {
        const { data } = response.data;
        setData({ name: data.name });
      },
    );
  }, [automatedReportId]);

  const handleGetMailLogs = useCallback(() => {
    handleApiResponse(
      getAutomatedReportMailLogs(automatedReportId),
      (response) => {
        const { List } = response.data.data;
        setListData(List);
      },
    );
  }, [automatedReportId]);

  const handleDownloadFile = (url) => {
    handleApiResponse(downloadFile(url), (response) => {
      const urlSegment = url.split('/');
      const fileName = urlSegment[urlSegment.length - 1];
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  useEffect(() => {
    const start = (listParameters.page - 1) * listParameters.limit;
    const end = listParameters.page * listParameters.limit;
    const tmpListData = filteredListData.slice(start, end);

    setCurrentListData(tmpListData);
  }, [filteredListData, listParameters.page, listParameters.limit]);

  useEffect(() => {
    const reg = new RegExp(escaperegexp(listParameters.search.trim()), 'i');
    const filterList = listData.filter((data) => {
      return data.mails.some((mail) => {
        return reg.test(mail);
      });
    });

    setListParameters((previousParams) => {
      return {
        ...previousParams,
        page: 1,
      };
    });

    setFilteredListData(filterList);
  }, [listParameters.search, listData]);

  useEffect(() => {
    handleGetMailLogs();
  }, [handleGetMailLogs]);

  useEffect(() => {
    handleGetAutomatedReportDetails();
  }, [handleGetAutomatedReportDetails]);

  return (
    <>
      <Section noPadding>
        <SectionHeader inline>
          <Heading1>
            {trans('AutomatedReportLogs')} - {data.name}
          </Heading1>
          <SectionToolbar>
            <SectionToolbarItem>
              <Label
                htmlFor='receivedEmail'
                style={{ flex: '1 0 auto', margin: 0 }}
              >
                {trans('ReceivedEmail')}
              </Label>
              <Input
                id='receivedEmail'
                type='text'
                value={keyword}
                onChange={(event) => {
                  setKeyword(event.target.value);
                }}
              />
            </SectionToolbarItem>
            <SectionToolbarItem>
              <Button
                onClick={() => {
                  setListParameters((previous) => {
                    return { ...previous, search: keyword };
                  });
                }}
              >
                {trans('button:Search')}
              </Button>
            </SectionToolbarItem>
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            columns={[
              {
                title: trans('SendAt'),
                fieldName: 'sendAt',
              },
              {
                title: trans('ReceivedEmail'),
                fieldName: 'mails',
                render(data) {
                  return data.join(', ');
                },
              },
              {
                title: trans('ReportFile'),
                fieldName: 'attachFile',
                render(data) {
                  const urlSegment = data.split('/');
                  const fileName = urlSegment[urlSegment.length - 1];
                  return (
                    <ButtonLink
                      onClick={() => {
                        handleDownloadFile(data);
                      }}
                    >
                      {fileName}
                    </ButtonLink>
                  );
                },
              },
            ]}
            data={currentListData}
            currentPage={listParameters.page}
            limit={listParameters.limit}
            total={filteredListData.length}
            onLimitChange={onLimitChange}
            onPageChange={onPageChange}
          />
        </SectionBody>
      </Section>
      <ButtonGroup alignRight>
        <Button onClick={() => navigate(-1)}>{trans('button:Back')}</Button>
      </ButtonGroup>
    </>
  );
};

export default AutomatedReportMailLogs;
