import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getRoleDetails } from '../../module/role';
import { showErrorMessage } from '../../module/message';
import { Section, SectionBody, SectionHeader } from '../../components/section';
import { Heading1 } from '../../components/heading';
import Tab from '../../components/Tab';
import RoleInformation from './detail/Information';
import RolePermission from './detail/Permission';

const RoleDetail = () => {
  const { t: trans } = useTranslation();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState('information');
  const [informationData, setInformationData] = useState({
    name: '',
    remark: '',
  });
  const [savedInformationData, setSavedInformationData] =
    useState(informationData);
  const [permissionIDs, setPermissionIDs] = useState([]);
  const [savedPermissionIDs, setSavedPermissionIDs] = useState([]);

  const handleGetRoleDetails = useCallback(() => {
    getRoleDetails(id)
      .then((response) => {
        if (response) {
          const { data } = response.data;
          setInformationData({
            name: data.name,
            remark: data.remark,
          });
          setSavedInformationData({
            name: data.name,
            remark: data.remark,
          });
          setPermissionIDs(data.permissionIds);
          setSavedPermissionIDs(data.permissionIds);
        }
      })
      .catch((error) => {
        showErrorMessage({ title: error.name, message: error.message });
      });
  }, [id]);

  useEffect(() => {
    handleGetRoleDetails();
  }, [handleGetRoleDetails]);

  return (
    <Section noPadding>
      <SectionHeader>
        <Heading1>
          {trans('Role')} - {informationData.name}
        </Heading1>
      </SectionHeader>
      <SectionBody>
        <Tab selected={selectedTab} onChange={setSelectedTab}>
          <Tab.List>
            <Tab.Tab name='information'>{trans('Information')}</Tab.Tab>
            <Tab.Tab name='permissions'>{trans('Permissions')}</Tab.Tab>
          </Tab.List>

          <Tab.Panel name='information'>
            <RoleInformation
              roleId={id}
              informationData={informationData}
              setInformationData={setInformationData}
              savedInformationData={savedInformationData}
              handleGetRoleDetails={handleGetRoleDetails}
            />
          </Tab.Panel>

          <Tab.Panel name='permissions'>
            <RolePermission
              roleId={id}
              permissionIDs={permissionIDs}
              setPermissionIDs={setPermissionIDs}
              savedPermissionIDs={savedPermissionIDs}
              handleGetRoleDetails={handleGetRoleDetails}
            />
          </Tab.Panel>
        </Tab>
      </SectionBody>
    </Section>
  );
};

export default RoleDetail;
