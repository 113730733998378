import styled from 'styled-components';
import BaseTableHeadCell from './BaseTableHeadCell';

const TableHeadCell = styled(BaseTableHeadCell)`
  &:first-child {
    padding-left: var(--spacing);
  }

  &:last-child {
    padding-right: var(--spacing);
  }
`;

export default TableHeadCell;
