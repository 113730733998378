import styled from 'styled-components';
import BaseTableHeadCell from './BaseTableHeadCell';

const GridTableHeadCell = styled(BaseTableHeadCell)`
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background: ${({ backgroundReverse }) => {
    return backgroundReverse
      ? 'var(--color-background1)'
      : 'var(--color-background2)';
  }};
`;

export default GridTableHeadCell;
