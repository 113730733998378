import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage } from '../../module/message';
import { createUser } from '../../module/user';
import { getRoleSimpleList } from '../../module/role';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { isValidEmail } from '../../utils/check';
import { Section, SectionHeader, SectionBody } from '../../components/section';
import { Heading1, Heading2 } from '../../components/heading';
import { Grid, Column } from '../../components/grid';
import { FormItem, Input, Label } from '../../components/form';
import { Select } from '../../components/Select';
import { Button, ButtonGroup } from '../../components/button';

const UserInvite = () => {
  const { t: trans } = useTranslation();
  const navigate = useNavigate();
  const [roleList, setRoleList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [roleSimpleList, setRoleSimpleList] = useState([]);
  const [data, setData] = useState({
    username: '',
    email: '',
    roleId: '',
  });

  const handleChangeValue = (value, key) => {
    const newData = { ...data };
    newData[key] = value;

    setData(newData);
  };

  const handleInvite = () => {
    if (!data.username.trim()) {
      showErrorMessage({ message: trans('error:PleaseFillUpUsername') });
      return;
    }

    if (!data.email.trim()) {
      showErrorMessage({ message: trans('error:PleaseFillUpEmailAddress') });
      return;
    }

    if (!isValidEmail(data.email.trim())) {
      showErrorMessage({
        message: trans('error:PleaseFillUpValidEmailAddress'),
      });
      return;
    }

    if (!data.roleId) {
      showErrorMessage({ message: trans('error:PleaseSelectARole') });
      return;
    }

    const InviteUserData = {
      username: data.username.trim(),
      email: data.email.trim(),
      roleIds: [data.roleId],
    };

    handleApiResponse(createUser(InviteUserData), () => {
      showSuccessMessage({ message: trans('success:Invite') });
      navigate(-1);
    });
  };

  const handleGetRoleList = useCallback((currentPage) => {
    const parameters = {
      page: currentPage,
      limit: 100,
    };
    handleApiResponse(getRoleSimpleList(parameters), (response) => {
      const { list, total } = response.data.data;

      setRoleList((previous) => {
        const newRoleList = [...previous.data, ...list];

        return {
          data: newRoleList,
          currentPage: currentPage + 1,
          lastPage: Math.ceil(total / 100),
        };
      });
    });
  }, []);

  useEffect(() => {
    if (roleList.currentPage <= roleList.lastPage) {
      handleGetRoleList(roleList.currentPage);
    } else {
      setRoleSimpleList(
        roleList.data.map((role) => {
          return { id: role.id, text: role.name };
        }),
      );
    }
  }, [
    handleGetRoleList,
    roleList.data,
    roleList.currentPage,
    roleList.lastPage,
  ]);

  return (
    <Section noPadding>
      <SectionHeader>
        <Heading1>{trans('InviteUser')}</Heading1>
      </SectionHeader>
      <SectionBody>
        <Section backgroundReverse noPaddingBottom>
          <SectionBody noPadding>
            <Heading2 whiteColor marginBottom>
              {trans('BasicInformation')}
            </Heading2>
            <Grid columns={2}>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='username' required>
                    {trans('UserName')}
                  </Label>
                  <Input
                    type='text'
                    id='username'
                    value={data.username}
                    onChange={(event) =>
                      handleChangeValue(event.target.value, 'username')
                    }
                  />
                </FormItem>
              </Column>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='email' required>
                    {trans('Email')}
                  </Label>
                  <Input
                    type='text'
                    id='email'
                    value={data.email}
                    onChange={(event) =>
                      handleChangeValue(event.target.value, 'email')
                    }
                  />
                </FormItem>
              </Column>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='role' required>
                    {trans('Role')}
                  </Label>
                  <Select
                    fullWidth
                    options={roleSimpleList}
                    selected={data.roleId}
                    onSelect={(roleId) => {
                      handleChangeValue(roleId, 'roleId');
                    }}
                  />
                </FormItem>
              </Column>
            </Grid>
          </SectionBody>
        </Section>

        <ButtonGroup alignRight>
          <Button
            danger
            onClick={() => {
              navigate(-1);
            }}
          >
            {trans('button:Cancel')}
          </Button>
          <Button success onClick={handleInvite}>
            {trans('button:Invite')}
          </Button>
        </ButtonGroup>
      </SectionBody>
    </Section>
  );
};

export default UserInvite;
