import { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  Filler,
  Tooltip,
  CategoryScale,
  LinearScale,
  defaults,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import customTooltip from './utils/customTooltip';

ChartJS.register(
  LineElement,
  PointElement,
  Filler,
  Tooltip,
  CategoryScale,
  LinearScale,
);
const LineChart = ({ height = 300, data, options = {} }) => {
  const theme = useContext(ThemeContext);

  // fix: read value from theme
  defaults.font.family = theme.fontFamily;
  defaults.font.size = 16;
  defaults.color = theme.fontOnBg;
  defaults.plugins.tooltip.external = customTooltip(theme);

  const customOptions = useMemo(() => {
    return {
      suggestedMax: options.suggestedMax ? options.suggestedMax : 10,
      tooltipTitlePrefix: '',
      tooltipLabelPrefix: '',
    };
  }, [options]);

  if (options.tooltips) {
    const tooltipsOptions = options.tooltips;
    customOptions.tooltipTitlePrefix = tooltipsOptions.titlePrefix || '';
    customOptions.tooltipLabelPrefix = tooltipsOptions.labelPrefix || '';
  }

  const defaultOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          intersect: false,
          filter(context) {
            return context.datasetIndex === 0 && context.label !== 'null';
          },
          callbacks: {
            title(context) {
              const content = context[0]
                ? context[0].label.match(/(.{1,20})/g)
                : '';

              return `${customOptions.tooltipTitlePrefix}${content}`;
            },
            label(context) {
              const content =
                context.dataset.data[context.dataIndex].toString();

              return `${customOptions.tooltipLabelPrefix}${content}`;
            },
            labelColor(context) {
              const { borderColor } = context.dataset;

              return {
                borderColor,
                backgroundColor: borderColor,
              };
            },
            labelTextColor() {
              return theme.fontOnPrimary;
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          beginAtZero: true,
          suggestedMax: customOptions.suggestedMax,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            callback(value) {
              if (value % 1 === 0) {
                return value;
              }

              return null;
            },
          },
        },
      },
    };
  }, [customOptions, theme.fontOnPrimary]);

  const Chart = useMemo(() => {
    return <Line height={height} data={data} options={defaultOptions} />;
  }, [height, data, defaultOptions]);

  return Chart;
};

export default LineChart;
