import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const SelectedMultiple = ({ content, onClick, disabled }) => {
  return (
    <Selected>
      {!disabled && (
        <span onClick={onClick} data-testid='removeOption'>
          <FaTimes />
        </span>
      )}
      {content}
    </Selected>
  );
};

const Selected = styled.div`
  align-items: center;
  display: flex;
  background: var(--color-background4);
  border-radius: var(--border-radius-l);
  padding: 0.25rem calc(var(--spacing-xs) + 4px);
  margin: 4px 10px 4px 0;
  color: var(--font-on-primary);
  font-weight: var(--font-normal);
  word-break: break-all;

  > span {
    display: flex;
    align-items: center;

    > svg {
      margin-right: var(--spacing-xs);
      color: var(--font-on-primary);
    }
  }
`;

export default SelectedMultiple;
