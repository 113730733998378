import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';
import AuthenticateContext from '../../../provider/context/authenticate.context';
import { getPermissionsList, modifyRole } from '../../../module/role';
import { showSuccessMessage, showErrorMessage } from '../../../module/message';
import handleApiResponse from '../../../utils/api/handleApiResponse';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionToolbar,
} from '../../../components/section';
import { Heading2 } from '../../../components/heading';
import { Button, ButtonGroup } from '../../../components/button';
import { Tooltip } from '../../../components/tooltip';
import Collapse from '../../../components/animate/Collapse';
import { Checkbox } from '../../../components/form';
import { PermissionTable } from '../../../components/table';

const RolePermission = ({
  roleId,
  permissionIDs,
  setPermissionIDs,
  savedPermissionIDs,
  handleGetRoleDetails,
}) => {
  const { t: trans } = useTranslation();
  const navigate = useNavigate();
  const { userInformation } = useContext(AuthenticateContext);
  const [isOnModify, setIsOnModify] = useState(false);
  const [selectedModules, setSelectedModules] = useState([]);
  const [permissionList, setPermissionList] = useState([]);

  const handleSelectModule = (moduleName) => {
    const targetModule = permissionList.filter((module) => {
      return module.menuName === moduleName;
    });

    if (targetModule.length > 0) {
      setPermissionIDs((previous) => {
        const newPermissionIDs = [...previous];
        const index = selectedModules.indexOf(moduleName);
        if (index === -1) {
          // select module
          targetModule.forEach((module) => {
            module.permissions.forEach((permission) => {
              if (!newPermissionIDs.includes(permission.id)) {
                newPermissionIDs.push(permission.id);
              }
            });
          });

          setSelectedModules([...selectedModules, moduleName]);
        } else {
          // deselect module
          targetModule.forEach((module) => {
            module.permissions.forEach((permission) => {
              const index = newPermissionIDs.indexOf(permission.id);
              if (index !== -1) {
                newPermissionIDs.splice(index, 1);
              }
            });
          });

          const newSelectedModules = [...selectedModules];
          newSelectedModules.splice(index, 1);
          setSelectedModules(newSelectedModules);
        }

        return newPermissionIDs;
      });
    }
  };

  const handleSelectFeature = (moduleName, featureName) => {
    const targetModule = permissionList.filter((module) => {
      return module.menuName === moduleName;
    });
    if (targetModule.length > 0) {
      setPermissionIDs((previous) => {
        const newPermissionIDs = [...previous];

        targetModule.forEach((module) => {
          module.permissions.forEach((permission) => {
            if (
              permission.actionName === featureName &&
              !newPermissionIDs.includes(permission.id)
            ) {
              newPermissionIDs.push(permission.id);
            }
          });
        });

        return newPermissionIDs;
      });
    }
  };

  const handleTogglePermission = (id) => {
    setPermissionIDs((previous) => {
      const newPermissionIDs = [...previous];
      const index = newPermissionIDs.indexOf(id);
      if (index !== -1) {
        newPermissionIDs.splice(index, 1);
      } else {
        newPermissionIDs.push(id);
      }

      return newPermissionIDs;
    });
  };

  const handleCancel = () => {
    setPermissionIDs(savedPermissionIDs);
    setIsOnModify(false);
  };

  const handleModify = () => {
    if (permissionIDs.length <= 0) {
      showErrorMessage({ message: trans('error:PleaseSelectPermissions') });
      return;
    }

    const requestData = {
      permissionIds: permissionIDs,
    };
    handleApiResponse(modifyRole(roleId, requestData), () => {
      showSuccessMessage({ message: trans('success:ModifyRole') });
      setIsOnModify(false);
      handleGetRoleDetails();
    });
  };

  useEffect(() => {
    handleApiResponse(getPermissionsList(), (response) => {
      const { list } = response.data.data;
      setPermissionList(list);
    });
  }, []);

  return (
    <>
      {permissionList.map((module) => {
        return (
          <PermissionSection
            key={module.id}
            isOnModify={isOnModify}
            permissionIDs={permissionIDs}
            module={module}
            selectedModules={selectedModules}
            handleSelectModule={handleSelectModule}
            handleSelectFeature={handleSelectFeature}
            handleTogglePermission={handleTogglePermission}
          />
        );
      })}

      <ButtonGroup alignRight>
        {isOnModify ? (
          <>
            <Button danger onClick={handleCancel}>
              {trans('button:Cancel')}
            </Button>
            <Button success onClick={handleModify}>
              {trans('button:Save')}
            </Button>
          </>
        ) : (
          <>
            <Button danger onClick={() => navigate(-1)}>
              {trans('button:Back')}
            </Button>
            {userInformation.permissions.includes('role.update') && (
              <Button
                warning
                onClick={() => {
                  setIsOnModify(true);
                }}
              >
                {trans('button:Modify')}
              </Button>
            )}
          </>
        )}
      </ButtonGroup>
    </>
  );
};

const PermissionSection = ({
  isOnModify,
  permissionIDs,
  module,
  selectedModules,
  handleSelectModule,
  handleSelectFeature,
  handleTogglePermission,
}) => {
  const { t: trans } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <Section backgroundReverse>
      <SectionHeader inline>
        <Heading2>
          <Checkbox
            htmlFor={module.menuName}
            text={module.menuName}
            checked={selectedModules.includes(module.menuName)}
            onChange={() => handleSelectModule(module.menuName)}
            fontSizeBig
            disabled={!isOnModify}
          />
        </Heading2>
        <SectionToolbar>
          {show ? (
            <Tooltip title={trans('Hide')}>
              <Button reverse onClick={() => setShow(!show)} iconOnly>
                <MdKeyboardArrowDown />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={trans('Show')}>
              <Button reverse onClick={() => setShow(!show)} iconOnly>
                <MdKeyboardArrowRight />
              </Button>
            </Tooltip>
          )}
        </SectionToolbar>
      </SectionHeader>
      <Collapse in={show} duration={500}>
        <SectionBody>
          <PermissionTable>
            <PermissionTableBody
              isOnModify={isOnModify}
              permissionIDs={permissionIDs}
              permissions={module.permissions}
              handleSelectFeature={(featureName) => {
                handleSelectFeature(module.menuName, featureName);
              }}
              handleTogglePermission={handleTogglePermission}
            />
          </PermissionTable>
        </SectionBody>
      </Collapse>
    </Section>
  );
};

const PermissionTableBody = ({
  isOnModify,
  permissionIDs,
  permissions,
  handleSelectFeature,
  handleTogglePermission,
}) => {
  return (
    <tbody>
      {permissions.map((permission) => {
        return (
          <tr key={permission.actionName}>
            <PermissionTable.TD
              clickable={isOnModify}
              bold
              onClick={() => handleSelectFeature(permission.actionName)}
            >
              {permission.actionName}
            </PermissionTable.TD>
            <PermissionTable.TD alignCenter key={permission.id}>
              <Checkbox
                htmlFor={permission.id}
                checked={permissionIDs.includes(permission.id)}
                onChange={() => handleTogglePermission(permission.id)}
                disabled={!isOnModify}
              />
            </PermissionTable.TD>
          </tr>
        );
      })}
    </tbody>
  );
};

export default RolePermission;
