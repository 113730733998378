import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

const Resizer = ({ container }) => {
  const [parentNode, setParentNode] = useState(null);

  const handleMouseMove = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      const horizontalScrollOffset = container
        ? container.scrollLeft
        : document.documentElement.scrollLeft;
      const targetWidth =
        horizontalScrollOffset +
        event.clientX -
        parentNode.getBoundingClientRect().left;
      parentNode.style.width = `${targetWidth}px`;
    },
    [container, parentNode],
  );

  const handleMouseUp = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      setParentNode(null);
    },
    [handleMouseMove],
  );

  const initResize = ({ target }) => {
    setParentNode(target.parentNode);
  };

  useEffect(() => {
    if (parentNode) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [parentNode, handleMouseMove, handleMouseUp]);

  return (
    <ColResize
      onClick={(event) => {
        event.stopPropagation();
      }}
      onMouseDown={initResize}
    />
  );
};

const ColResize = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  height: 100%;

  &::after {
    content: '';
    width: 8px;
    margin-right: -4px;
    cursor: col-resize;
  }

  &:hover::after {
    background: var(--color-hover);
  }
`;

export default Resizer;
