import { useRef, useEffect } from 'react';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

const CropperWrapper = ({
  src,
  alt = 'picture',
  width,
  height,
  cropBoxResizable = false,
  onInitialized,
  dragMode = 'move',
}) => {
  const imageRef = useRef(null);

  useEffect(() => {
    const node = imageRef.current;

    if (node !== null) {
      const cropper = new Cropper(node, {
        dragMode,
        viewMode: 1,
        initialAspectRatio: 9 / 16,
        background: true,
        cropBoxResizable,
        data: {
          width,
          height,
        },
      });

      if (onInitialized) {
        onInitialized(cropper);
      }
    }

    return () => {
      node?.cropper?.destroy();
    };
  }, [onInitialized, dragMode, width, height, cropBoxResizable]);

  return (
    <div>
      <img src={src} alt={alt} ref={imageRef} crossOrigin='anonymous' />
    </div>
  );
};

export default CropperWrapper;
