import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './provider/i18next/i18n';
import { AppContextProvider } from './provider/context/app.context';
import { AuthenticateContextProvider } from './provider/context/authenticate.context';
import App from './page/App';
import './assets/css/global.css';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppContextProvider>
        <AuthenticateContextProvider>
          <App />
        </AuthenticateContextProvider>
      </AppContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
