import { callApi } from '../services/api';

const getRoleList = (data) => {
  return callApi.get('/roles', { params: data });
};

const getRoleSimpleList = ({ page, limit }) => {
  const params = { page, limit };

  return callApi.get('/roles/simple', { params });
};

const createRole = (data) => {
  return callApi.post('/roles', data);
};

const getRoleDetails = (id) => {
  return callApi.get(`/roles/${id}`);
};

const modifyRole = (id, data) => {
  return callApi.patch(`/roles/${id}`, data);
};

const deleteRole = (id) => {
  return callApi.delete(`/roles/${id}`);
};

const getPermissionsList = () => {
  return callApi.get('/menus');
};

export {
  getRoleList,
  getRoleSimpleList,
  createRole,
  getRoleDetails,
  modifyRole,
  deleteRole,
  getPermissionsList,
};
