import TabContext from './subComponent/TabContext';

const Tab = ({ children, selected, onChange }) => {
  return (
    <TabContext.Provider value={{ selected, onChange }}>
      {children}
    </TabContext.Provider>
  );
};

export default Tab;
