import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { COUPON_TYPE, COUPON_CATEGORY } from '../../provider/constant';
import { checkNumberRule, isInteger } from '../../utils/check/index';
import { showErrorMessage, showSuccessMessage } from '../../module/message';
import { createCoupon, getTimezoneList } from '../../module/coupon';
import { getTerminalGroupList } from '../../module/terminal';
import handleApiResponse from '../../utils/api/handleApiResponse';
import {
  Section,
  SectionHeader,
  SectionBody,
} from '../../components/section/index';
import { Heading1, Heading2 } from '../../components/heading/index';
import { Grid, Column } from '../../components/grid/index';
import { Select } from '../../components/Select/index';
import { FormItem, Input, Label, Textarea } from '../../components/form/index';
import { Button, ButtonGroup } from '../../components/button/index';
import { DatetimePickerV2 } from '../../components/datetimePicker/index';
import { formatDate } from '../../utils/format/index';

const CouponCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [timezoneList, setTimezoneList] = useState([]);
  const [terminalGrouptData, setTerminalGrouptData] = useState([]);
  const [data, setData] = useState({
    title: '',
    couponCategory: 1,
    startDate: formatDate(new Date().setHours(0, 0, 0, 0)),
    endDate: formatDate(new Date()),
    couponType: 1,
    // 減免金額
    discountAmount: '',
    // 折扣價格百分比
    discountFactor: '',
    total: '',
    remarks: '',
    minAmount: '',
    limitReceive: '',
    validStartDate: formatDate(new Date().setHours(0, 0, 0, 0)),
    validEndDate: formatDate(new Date()),
    timezoneId: '',
    terminalGroup: [],
  });

  const couponCategoryOption = useMemo(() => {
    return COUPON_CATEGORY.map((status) => {
      return { id: status.id, text: t(status.text) };
    });
  }, [t]);

  const couponTypeOption = useMemo(() => {
    return COUPON_TYPE.map((status) => {
      return { id: status.id, text: t(status.text) };
    });
  }, [t]);

  const terminalGroupList = useMemo(() => {
    return terminalGrouptData.map((terminalGroup) => {
      return {
        id: terminalGroup.id,
        text: terminalGroup.name,
      };
    });
  }, [terminalGrouptData]);

  const handleChangeValue = (value, key, type = null) => {
    const newData = { ...data };

    if (key === 'terminalGroup') {
      if (type === 'delete') {
        const index = newData[key].indexOf(value);
        if (index > -1) {
          newData[key].splice(index, 1);
          newData[key] = [...newData[key]];
        }
      }

      if (type === 'add' && !newData[key].includes(value)) {
        newData[key] = [...newData[key], value];
      }
    } else {
      if (key === 'couponType' && value === 1) {
        // couponType為1(固定金額)
        newData.discountAmount = '';
        newData.minAmount = '';
      } else if (key === 'couponType' && value === 2) {
        // couponType為2(百分比)
        newData.discountFactor = '';
        newData.minAmount = '0';
      } else if (key === 'couponType' && value === 3) {
        // couponType為3(免費贈送)
        newData.discountFactor = '100';
        newData.minAmount = '0';
      }

      newData[key] = value;
    }

    setData(newData);
  };

  const handleCreate = () => {
    if (!data.title.trim()) {
      showErrorMessage({ message: t('error:PleaseFillUpName') });
      return;
    }

    if (!data.timezoneId) {
      showErrorMessage({ message: t('error:PleaseSelectTimezone') });
      return;
    }

    if (!data.couponCategory) {
      showErrorMessage({ message: t('error:PleaseSelectCouponCategory') });
      return;
    }

    if (!data.couponType) {
      showErrorMessage({ message: t('error:PleaseSelectCouponType') });
      return;
    }

    if (data.couponType === 1 && !data.discountAmount.trim()) {
      showErrorMessage({ message: t('error:PleaseFillUpDiscountValue') });
      return;
    }

    if (data.couponType === 1 && isNaN(data.discountAmount.trim())) {
      showErrorMessage({
        message: t('error:DiscountValueMustBeNumber'),
      });
      return;
    }

    if (data.couponType === 1 && checkNumberRule(data.discountAmount.trim())) {
      showErrorMessage({
        message: t('error:DiscountValueOnlySupportsTwoDecimalPlaces'),
      });
      return;
    }

    if (data.couponType === 2 && !data.discountFactor.trim()) {
      showErrorMessage({ message: t('error:PleaseFillUpDiscountValue') });
      return;
    }

    if (data.couponType === 2 && isNaN(data.discountFactor.trim())) {
      showErrorMessage({
        message: t('error:DiscountValueMustBeNumber'),
      });
      return;
    }

    if (data.couponType === 2 && !isInteger(data.discountFactor.trim())) {
      showErrorMessage({
        message: t('error:DiscountValueMustBeInteger'),
      });
      return;
    }

    if (!data.minAmount) {
      showErrorMessage({ message: t('error:PleaseFillUpMinimumAmount') });
      return;
    }

    if (isNaN(data.minAmount.trim())) {
      showErrorMessage({
        message: t('error:MinimumAmountMustBeNumber'),
      });
      return;
    }

    if (checkNumberRule(data.minAmount.trim())) {
      showErrorMessage({
        message: t('error:MinimumAmountOnlySupportsTwoDecimalPlaces'),
      });
      return;
    }

    if (!data.startDate) {
      showErrorMessage({ message: t('error:PleaseSelectStartDate') });
      return;
    }

    if (!data.endDate) {
      showErrorMessage({ message: t('error:PleaseSelectEndDate') });
      return;
    }

    if (!data.validStartDate) {
      showErrorMessage({ message: t('error:PleaseSelectValidStartDate') });
      return;
    }

    if (!data.validEndDate) {
      showErrorMessage({ message: t('error:PleaseSelectValidEndDate') });
      return;
    }

    if (data.couponCategory === 1 && !data.limitReceive.trim()) {
      showErrorMessage({
        message: t('error:PleaseFillUpLimitReceiveQuantity'),
      });
      return;
    }

    if (data.couponCategory === 1 && isNaN(data.limitReceive.trim())) {
      showErrorMessage({
        message: t('error:LimitReceiveQuantityMustBeNumber'),
      });
      return;
    }

    if (data.couponCategory === 1 && !isInteger(data.limitReceive.trim())) {
      showErrorMessage({
        message: t('error:LimitReceiveQuantityMustBeInteger'),
      });
      return;
    }

    if (!data.total.trim()) {
      showErrorMessage({ message: t('error:PleaseFillUpQuantity') });
      return;
    }

    if (isNaN(data.total.trim())) {
      showErrorMessage({
        message: t('error:QuantityMustBeNumber'),
      });
      return;
    }

    if (!isInteger(data.total.trim())) {
      showErrorMessage({ message: t('error:QuantityMustBeInteger') });
      return;
    }

    const createCoupnData = {
      title: data.title.trim(),
      couponCategory: data.couponCategory,
      startDate: data.startDate,
      endDate: data.endDate,
      couponType: data.couponType,
      total: +data.total.trim(),
      limitReceive: data.couponCategory === 2 ? 1 : +data.limitReceive,
      validStartDate: data.validStartDate,
      validEndDate: data.validEndDate,
      timezoneId: +data.timezoneId,
      minAmount: +data.minAmount.trim() * 100,
      discountAmount: +data.discountAmount * 100,
      discountFactor: +data.discountFactor,
    };

    if (data.terminalGroup) {
      createCoupnData.terminalGroup = data.terminalGroup;
    }

    if (data.remarks) {
      createCoupnData.remarks = data.remarks;
    }

    handleApiResponse(createCoupon(createCoupnData), () => {
      showSuccessMessage({ message: t('success:CreateCoupon') });
      navigate(-1);
    });
  };

  useEffect(() => {
    handleApiResponse(getTimezoneList({ status: 0 }), (response) => {
      const { data } = response.data;

      setTimezoneList(
        data.map(({ id, name, offset }) => {
          return {
            id,
            text: `${name} ${offset}`,
          };
        }),
      );
    });
  }, []);

  // get terminal group list
  useEffect(() => {
    const requestData = {
      page: 1,
      limit: 10,
    };

    handleApiResponse(getTerminalGroupList(requestData), (response) => {
      const { list } = response.data.data;
      setTerminalGrouptData(list);
    });
  }, []);

  return (
    <>
      <Section noPadding>
        <SectionHeader>
          <Heading1>{t('CreateCoupon')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionBody noPadding>
              <Heading2 whiteColor marginBottom>
                {t('BasicInformation')}
              </Heading2>
              <Grid columns={2}>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='title' required>
                      {t('Name')}
                    </Label>
                    <Input
                      type='text'
                      id='title'
                      value={data.title}
                      onChange={(event) =>
                        handleChangeValue(event.target.value, 'title')
                      }
                      maxLength='128'
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='timezone' required>
                      {t('Timezone')}
                    </Label>
                    <Select
                      fullWidth
                      options={timezoneList}
                      selected={data.timezoneId}
                      onSelect={(id) => {
                        handleChangeValue(id, 'timezoneId');
                      }}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='couponCategory' required>
                      {t('CouponCategory')}
                    </Label>
                    <Select
                      fullWidth
                      options={couponCategoryOption}
                      selected={data.couponCategory}
                      onSelect={(couponCategory) =>
                        handleChangeValue(couponCategory, 'couponCategory')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='couponType' required>
                      {t('CouponType')}
                    </Label>
                    <Select
                      fullWidth
                      options={couponTypeOption}
                      selected={data.couponType}
                      onSelect={(couponType) => {
                        handleChangeValue(couponType, 'couponType');
                      }}
                    />
                  </FormItem>
                </Column>
                {data.couponType === 1 && (
                  <Column desktop={1}>
                    <FormItem>
                      <Label htmlFor='discountAmount' required>
                        {t('DiscountValue')}
                      </Label>
                      <Input
                        type='text'
                        id='discountAmount'
                        value={data.discountAmount}
                        onChange={(event) =>
                          handleChangeValue(
                            event.target.value,
                            'discountAmount',
                          )
                        }
                      />
                    </FormItem>
                  </Column>
                )}
                {(data.couponType === 2 || data.couponType === 3) && (
                  <Column desktop={1}>
                    <FormItem>
                      <Label htmlFor='discountFactor' required>
                        {t('DiscountValue')}
                      </Label>
                      <Input
                        type='text'
                        id='discountFactor'
                        value={
                          data.couponType === 3 ? '100' : data.discountFactor
                        }
                        onChange={(event) => {
                          handleChangeValue(
                            event.target.value,
                            'discountFactor',
                          );
                        }}
                        disabled={data.couponType === 3}
                      />
                    </FormItem>
                  </Column>
                )}
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='minAmount' required>
                      {t('RequiredMinimumAmount')}
                    </Label>
                    <Input
                      type='text'
                      id='minAmount'
                      value={data.minAmount}
                      onChange={(event) =>
                        handleChangeValue(event.target.value, 'minAmount')
                      }
                      disabled={data.couponType === 2 || data.couponType === 3}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='startDate' required>
                      {t('StartDate')}
                    </Label>
                    <DatetimePickerV2
                      selected={data.startDate}
                      onConfirm={(startDate) => {
                        handleChangeValue(startDate, 'startDate');
                      }}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='endDate' required>
                      {t('EndDate')}
                    </Label>
                    <DatetimePickerV2
                      selected={data.endDate}
                      onConfirm={(endDate) => {
                        handleChangeValue(endDate, 'endDate');
                      }}
                      minDate={formatDate(
                        new Date(data.startDate).setSeconds(1),
                      )}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='validStartDate' required>
                      {t('ValidStartDate')}
                    </Label>
                    <DatetimePickerV2
                      selected={data.validStartDate}
                      onConfirm={(startDate) => {
                        handleChangeValue(startDate, 'validStartDate');
                      }}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='validEndDate' required>
                      {t('ValidEndDate')}
                    </Label>
                    <DatetimePickerV2
                      selected={data.validEndDate}
                      onConfirm={(endtDate) => {
                        handleChangeValue(endtDate, 'validEndDate');
                      }}
                      minDate={data.validStartDate}
                    />
                  </FormItem>
                </Column>
                {data.couponCategory === 1 && (
                  <Column desktop={1}>
                    <FormItem>
                      <Label htmlFor='limitReceive' required>
                        {t('LimitReceiveQuantity')}
                      </Label>
                      <Input
                        type='text'
                        id='total'
                        value={data.limitReceive}
                        onChange={(event) =>
                          handleChangeValue(event.target.value, 'limitReceive')
                        }
                      />
                    </FormItem>
                  </Column>
                )}

                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='total' required>
                      {t('Quantity')}
                    </Label>
                    <Input
                      type='text'
                      id='total'
                      value={data.total}
                      onChange={(event) =>
                        handleChangeValue(event.target.value, 'total')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='terminalGroup'>{t('TerminalGroup')}</Label>
                    <Select
                      multiple
                      id='terminalGroup'
                      fullWidth
                      options={terminalGroupList}
                      selected={data.terminalGroup}
                      onSelect={(terminalGroup) => {
                        handleChangeValue(
                          terminalGroup,
                          'terminalGroup',
                          'add',
                        );
                      }}
                      onDeselect={(terminalGroup) =>
                        handleChangeValue(
                          terminalGroup,
                          'terminalGroup',
                          'delete',
                        )
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={2}>
                  <FormItem>
                    <Label htmlFor='remarks'>{t('UsageInstructions')}</Label>
                    <Textarea
                      id='remarks'
                      rows='4'
                      value={data.remarks}
                      onChange={(event) =>
                        handleChangeValue(event.target.value, 'remarks')
                      }
                      maxLength='512'
                    />
                  </FormItem>
                </Column>
              </Grid>
            </SectionBody>
          </Section>

          <ButtonGroup alignRight>
            <Button
              danger
              onClick={() => {
                navigate(-1);
              }}
            >
              {t('button:Cancel')}
            </Button>
            <Button success onClick={handleCreate}>
              {t('button:Submit')}
            </Button>
          </ButtonGroup>
        </SectionBody>
      </Section>
    </>
  );
};

export default CouponCreate;
