import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '../../components/button';
import { TitleModal } from '../../components/modal';
import { FormItem, Label, Input } from '../../components/form';

const ConfirmLocationModal = ({
  choseAddress,
  setChoseAddress,
  setGeolocationStep,
  close,
}) => {
  const { t: trans } = useTranslation();
  const [address, setAddress] = useState(choseAddress);

  const handleBack = () => {
    setGeolocationStep('map');
    close();
  };

  const handleConfirmLocation = () => {
    setChoseAddress(address);
    setGeolocationStep('done');
    close();
  };

  return (
    <>
      <TitleModal.Header handleClose={close}>
        {trans('ConfirmLocation')}
      </TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <FormItem>
          <Label htmlFor='adjustAddress'>{trans('Address')}</Label>
          <Input
            type='text'
            id='adjustAddress'
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </FormItem>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={handleBack}>
            {trans('button:Back')}
          </Button>
          <Button success onClick={handleConfirmLocation}>
            {trans('button:Confirm')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

export default ConfirmLocationModal;
