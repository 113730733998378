import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import escaperegexp from 'lodash.escaperegexp';
import { Button, ButtonGroup } from '../../components/button';
import {
  FormItem,
  Input,
  Label,
  Textarea,
  Checkbox,
  Searchbox,
} from '../../components/form';
import { Column, Grid } from '../../components/grid';
import { Heading1, Heading2 } from '../../components/heading';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionToolbar,
} from '../../components/section';
import { Table } from '../../components/table';
import { showErrorMessage, showSuccessMessage } from '../../module/message';
import { createTerminalGroup, getTerminalList } from '../../module/terminal';
import handleApiResponse from '../../utils/api/handleApiResponse';

const CreateTerminalGroup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: '',
    remarks: '',
  });
  const [groupList, setGroupList] = useState([]);
  const [filteredGroupList, setFilteredGroupList] = useState([]);
  const [currentGroupList, setCurrentGroupList] = useState([]);
  const [groupListSelected, setGroupListSelected] = useState([]);
  const [groupListParams, setGroupListParams] = useState({
    page: 1,
    limit: 10,
    sn: '',
  });
  const [allList, setAllList] = useState([]);
  const [total, setTotal] = useState(1);
  const [allListSelected, setAllListSelected] = useState([]);
  const [allListParams, setAllListParams] = useState({
    page: 1,
    limit: 10,
    sn: '',
  });

  const handleChangeValue = (value, key) => {
    setData((previous) => {
      const newData = { ...previous };
      newData[key] = value;

      return newData;
    });
  };

  const handleAttach = () => {
    if (allListSelected.length === 0) {
      showErrorMessage({ message: t('error:PleaseSelectAtLeastOneTerminal') });
      return;
    }

    const groupListIds = groupList.map((group) => group.id);
    const validAllListSelectedIds = allListSelected.filter(
      (groupId) => !groupListIds.includes(groupId),
    );

    const newGroupList = [
      ...groupList,
      ...allList.filter((terminal) => {
        return validAllListSelectedIds.includes(terminal.id);
      }),
    ];

    setGroupList(newGroupList);
  };

  const handleDetach = () => {
    if (groupListSelected.length === 0) {
      showErrorMessage({ message: t('error:PleaseSelectAtLeastOneTerminal') });
      return;
    }

    const newGroupList = groupList.filter((terminal) => {
      return !groupListSelected.includes(terminal.id);
    });

    setGroupList(newGroupList);
  };

  const handleCreate = () => {
    if (!data.name) {
      showErrorMessage({ message: t('error:PleaseFillUpName') });
      return;
    }

    if (groupList.length === 0) {
      showErrorMessage({ message: t('error:PleaseSelectAtLeastOneTerminal') });
      return;
    }

    const terminals = groupList.map((terminal) => {
      return terminal.id;
    });

    const requestData = {
      name: data.name.trim(),
      terminals: terminals,
    };

    if (data.remarks) {
      requestData.remarks = data.remarks;
    }

    handleApiResponse(createTerminalGroup(requestData), () => {
      showSuccessMessage({ message: t('success:CreateTerminalGroup') });
      navigate(-1);
    });
  };

  const handleGetTerminalList = useCallback(() => {
    const requestData = {
      page: allListParams.page,
      limit: allListParams.limit,
    };

    if (allListParams.sn) {
      requestData.sn = allListParams.sn;
    }

    handleApiResponse(getTerminalList(requestData), (response) => {
      const { list, total } = response.data.data;
      setAllList(list);
      setTotal(total);
    });
  }, [allListParams]);

  // limit group list terminal current page data
  useEffect(() => {
    const start = (groupListParams.page - 1) * groupListParams.limit;
    const end = groupListParams.page * groupListParams.limit;
    const tmpListData = filteredGroupList.slice(start, end);

    setCurrentGroupList(tmpListData);
  }, [filteredGroupList, groupListParams.page, groupListParams.limit]);

  // filter group list terminal
  useEffect(() => {
    const reg = new RegExp(escaperegexp(groupListParams.sn), 'i');

    const searchColumn = ['sn'];
    const filterList = groupList.filter((data) => {
      return searchColumn.some((column) => {
        return reg.test(data[column]);
      });
    });

    setGroupListParams((previousParams) => {
      return {
        ...previousParams,
        page: 1,
      };
    });

    setFilteredGroupList(filterList);
  }, [groupListParams.sn, groupList]);

  // get terminal list
  useEffect(() => {
    handleGetTerminalList();
  }, [handleGetTerminalList]);

  return (
    <>
      <Section noPadding>
        <SectionHeader>
          <Heading1>{t('CreateTerminalGroup')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionBody noPadding>
              <Grid columns={12}>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='name' required>
                      {t('Name')}
                    </Label>
                    <Input
                      id='name'
                      type='text'
                      value={data.name}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'name')
                      }
                      maxLength='64'
                    />
                  </FormItem>
                </Column>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='description'>{t('Description')}</Label>
                    <Textarea
                      id='description'
                      rows='4'
                      value={data.remarks}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'remarks')
                      }
                      maxLength='128'
                    />
                  </FormItem>
                </Column>
                <Column desktop={5} laptop={12}>
                  <TerminalList
                    title={t('GroupTerminals')}
                    data={currentGroupList}
                    total={filteredGroupList.length}
                    listParams={groupListParams}
                    setListParams={setGroupListParams}
                    selectedTerminal={groupListSelected}
                    setSelectedTerminal={setGroupListSelected}
                  />
                </Column>
                <Column desktop={2} laptop={12}>
                  <ButtonGroup vertical height100 alignCenter>
                    <Button onClick={handleAttach}>
                      <FaAngleDoubleLeft />
                      {t('button:Attach')}
                    </Button>
                    <Button onClick={handleDetach}>
                      {t('button:Detach')}
                      <FaAngleDoubleRight />
                    </Button>
                  </ButtonGroup>
                </Column>
                <Column desktop={5} laptop={12}>
                  <TerminalList
                    title={t('AllTerminals')}
                    data={allList}
                    total={total}
                    listParams={allListParams}
                    setListParams={setAllListParams}
                    selectedTerminal={allListSelected}
                    setSelectedTerminal={setAllListSelected}
                  />
                </Column>
              </Grid>
            </SectionBody>
          </Section>
        </SectionBody>
        <ButtonGroup alignRight>
          <Button
            danger
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('button:Cancel')}
          </Button>
          <Button success onClick={handleCreate}>
            {t('button:Submit')}
          </Button>
        </ButtonGroup>
      </Section>
    </>
  );
};

const TerminalList = ({
  title,
  data,
  total,
  listParams,
  setListParams,
  selectedTerminal,
  setSelectedTerminal,
}) => {
  const { t } = useTranslation();
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const handleSelectedChange = useCallback(
    (id) => {
      const newSelectedTerminal = [...selectedTerminal];
      const index = selectedTerminal.indexOf(id);
      if (index === -1) {
        newSelectedTerminal.push(id);
      } else {
        newSelectedTerminal.splice(index, 1);
      }

      setSelectedTerminal(newSelectedTerminal);
    },
    [selectedTerminal, setSelectedTerminal],
  );

  const handleSelectAll = useCallback(() => {
    if (isSelectedAll) {
      setSelectedTerminal([]);
    } else {
      const newSelectedTerminal = [];
      data.forEach((terminal) => {
        newSelectedTerminal.push(terminal.id);
      });
      setSelectedTerminal(newSelectedTerminal);
    }
    setIsSelectedAll(!isSelectedAll);
  }, [data, isSelectedAll, setSelectedTerminal]);

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: (
          <Checkbox
            noMargin
            onChange={handleSelectAll}
            checked={isSelectedAll}
          />
        ),
        fieldName: 'id',
        sortable: false,
        width: '15%',
        render(data) {
          return (
            <Checkbox
              noMargin
              onChange={() => {
                handleSelectedChange(data);
              }}
              checked={selectedTerminal.includes(data)}
            />
          );
        },
      },
      {
        title: t('HardwareSerialNumber'),
        fieldName: 'sn',
        sortable: false,
        width: '30%',
      },
    ];
    return columns;
  }, [
    t,
    handleSelectAll,
    handleSelectedChange,
    isSelectedAll,
    selectedTerminal,
  ]);

  return (
    <Section>
      <SectionHeader inline>
        <Heading2 whiteColor>{title}</Heading2>
        <SectionToolbar>
          <Searchbox
            border
            onSearch={(keyword) => {
              setListParams((previousParams) => {
                return {
                  ...previousParams,
                  page: 1,
                  sn: keyword,
                };
              });
            }}
          />
        </SectionToolbar>
      </SectionHeader>
      <SectionBody>
        <Table
          columns={tableColumns}
          data={data}
          currentPage={listParams.page}
          limit={listParams.limit}
          limitChange={(limit) => {
            setListParams((previousParams) => {
              return {
                ...previousParams,
                page: 1,
                limit: limit,
              };
            });
          }}
          total={total}
          pageChange={(page) => {
            setListParams((previousParams) => {
              return {
                ...previousParams,
                page: page,
              };
            });
          }}
          select={{ type: 'multiple', matcher: 'id' }}
          selected={selectedTerminal}
        />
      </SectionBody>
    </Section>
  );
};

export default CreateTerminalGroup;
