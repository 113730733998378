import { useRef, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthenticateContext from '../../provider/context/authenticate.context';
import Aside from './Aside';
import AsideHeader from './AsideHeader';
import styled, { css } from 'styled-components';
import headerStyle from '../../styles/header.style';
import media from '../styles/mixin/media';
import { Link } from 'react-router-dom';

const Sidebar = ({ $display, toggleSidebar }) => {
  const { t } = useTranslation();
  const { userInformation } = useContext(AuthenticateContext);
  const [hoverDisplay, setHoverDisplay] = useState(false);
  const sidebarRef = useRef(null);

  const handleMouseOver = () => {
    if ($display === true) return;

    setHoverDisplay(true);
  };

  const handleMouseOut = (e) => {
    if ($display === true) return;

    if (sidebarRef.current.contains(e.relatedTarget)) return;

    setHoverDisplay(false);
  };

  return (
    <>
      <Aside
        ref={sidebarRef}
        $display={$display || hoverDisplay}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <AsideHeader toggleSidebar={toggleSidebar} />
        <Menu>
          <MenuList path='/dashboard' name={t('Dashboard')} />

          {userInformation &&
            userInformation.menus &&
            Array.isArray(userInformation.menus) && (
              <>
                {userInformation.menus.includes('coupon') && (
                  <MenuList path='/coupon' name={t('Coupon')} />
                )}

                {userInformation.menus.includes('terminal group') && (
                  <MenuList path='/terminal-group' name={t('TerminalGroup')} />
                )}

                {userInformation.menus.includes('report') && (
                  <MenuList path='/report' name={t('Report')} />
                )}

                {userInformation.menus.includes('bottler group') && (
                  <MenuList path='/bottler-group' name={t('BottlerGroup')} />
                )}

                {userInformation.menus.includes('sales center') && (
                  <MenuList path='/sales-center' name={t('SalesCenter')} />
                )}

                {userInformation.menus.includes('terminal') && (
                  <MenuList path='/terminal' name={t('Terminal')} />
                )}

                {userInformation.menus.includes('role') && (
                  <MenuList path='/role' name={t('Role')} />
                )}

                {userInformation.menus.includes('user') && (
                  <MenuList path='/user' name={t('User')} />
                )}
              </>
            )}
        </Menu>
      </Aside>
    </>
  );
};

const MenuList = ({ path, name, active }) => {
  return (
    <MenuItem active={active}>
      <MenuItemLink to={path}>
        <span>{name}</span>
      </MenuItemLink>
    </MenuItem>
  );
};

const Menu = styled.ul`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  span {
    color: var(--font-on-primary);
    font-size: var(--font-body1);
  }

  li {
    transition: 0.3s;

    &:hover {
      background: var(--color-hover);
    }
  }

  ${media.tablet`
    height: calc(100% - ${headerStyle.height});
  `};
`;

const MenuItem = styled.li`
  ${(props) =>
    props.active === true &&
    css`
      background: var(--color-active);
    `};
`;

const MenuItemLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 var(--spacing);
  height: 48px;

  img {
    width: 24px;
    height: 24px;
    margin-right: var(--spacing);
    flex: 0 0 24px;
  }
`;

export default Sidebar;
