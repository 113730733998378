const appendElementToBody = (id, tagName = 'div') => {
  let result = document.getElementById(id);

  if (!result) {
    result = document.createElement(tagName);
    result.setAttribute('id', id);
    document.body.appendChild(result);
  }

  return result;
};

export default appendElementToBody;
