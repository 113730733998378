import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ImProfile } from 'react-icons/im';
import { FaSignOutAlt } from 'react-icons/fa';
import AuthenticateContext from '../../provider/context/authenticate.context';
import Dropdown, {
  DropdownItem,
  DropdownLinkOption,
  DropdownButtonOption,
} from '../dropdown';
import { IconButton } from '../button';
import IconFigure from '../icon/IconFigure';
import ImageFluid from '../image/ImageFluid';
import DefaultUser from '../../assets/images/default-user.jpg';

const UserInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userInformation, removeAuthenticate } =
    useContext(AuthenticateContext);

  const handleLogout = () => {
    removeAuthenticate();
    navigate('/login');
  };

  return (
    <Dropdown fixed>
      <Dropdown.Toggle>
        <Information>
          <IconButton>
            <IconFigure size='large'>
              <ImageFluid
                crossOrigin='anonymous'
                src={DefaultUser}
                alt='avatar'
                roundedCircle
              />
            </IconFigure>
          </IconButton>
          <p>{userInformation ? userInformation.username : 'User'}</p>
        </Information>
      </Dropdown.Toggle>
      <Dropdown.Content>
        <DropdownItem>
          <DropdownLinkOption to='/profile'>
            <ImProfile />
            {t('Profile')}
          </DropdownLinkOption>
        </DropdownItem>

        <DropdownItem onClick={handleLogout}>
          <DropdownButtonOption>
            <FaSignOutAlt />
            {t('Logout')}
          </DropdownButtonOption>
        </DropdownItem>
      </Dropdown.Content>
    </Dropdown>
  );
};

const Information = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--font-on-background);
`;

export default UserInfo;
