import { useState, forwardRef, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FaPen, FaTimes } from 'react-icons/fa';
import { MdCrop } from 'react-icons/md';
import { dataURLtoFile, getBase64DataUrlFromFile } from '../../utils/file';
import { showErrorMessage } from '../../module/message';
import HiddenFileInput from '../file/HiddenFileInput';
import ImageFigure from '../figure/ImageFigure';
import ImageFluid from './ImageFluid';

const UploadImage = forwardRef(
  (
    {
      id,
      src,
      alt = 'user avatar',
      roundedCircle = false,
      cropperHandler,
      onBlur,
      onClear,
      allowClear = false,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const refWrapper = useRef();
    const [currentSrc, setCurrentSrc] = useState(src);
    const [currentFile, setCurrentFile] = useState(null);
    const [focusCount, setFocusCount] = useState(0);

    const handleAvatarRemove = () => {
      setCurrentSrc(src);
      ref.current.value = '';
      setCurrentFile(null);
    };

    const restoreFile = useCallback(() => {
      if (!currentFile) return;
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(currentFile);
      ref.current.files = dataTransfer.files;
    }, [ref, currentFile]);

    const handleImageFile = useCallback(
      (file, src) => {
        const dataTransfer = new DataTransfer();
        const changedImageFile = dataURLtoFile(src, file.name);
        dataTransfer.items.add(changedImageFile);
        setCurrentSrc(src);
        setCurrentFile(changedImageFile);
        ref.current.files = dataTransfer.files;
      },
      [ref],
    );

    const handleOnChange = (files) => {
      if (files.length === 0) {
        // close file selector or cancel upload file need to restore to last uploaded file
        restoreFile();
      } else {
        const fileType = files[0].name
          .slice(files[0].name.lastIndexOf('.'))
          .toLowerCase();

        if (['.jpeg', '.bmp', '.jpg', '.png'].includes(fileType)) {
          if (files[0].size < 2097152) {
            const srcUrl = URL.createObjectURL(files[0]);
            handleCrop(srcUrl, files[0]);
          } else {
            ref.current.value = '';
            restoreFile();
            showErrorMessage({
              title: t('error:Upload'),
              message: t('error:UploadSize', {
                size: '2MB',
              }),
            });
          }
        } else {
          ref.current.value = '';
          restoreFile();
          showErrorMessage({
            title: t('error:Upload'),
            message: t('error:AvatarFileType'),
          });
        }
      }
    };

    const handleCrop = useCallback(
      (src, file) => {
        cropperHandler({
          src,
          file,
          width: 160,
          height: 160,
          onCrop: (croppedSrc) => {
            handleImageFile(file, croppedSrc);
          },
          onClose: () => {
            getBase64DataUrlFromFile(file, (dataUrl) => {
              handleImageFile(file, dataUrl);
            });
          },
        });
      },
      [cropperHandler, handleImageFile],
    );

    const handleFocus = (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (event.target.tagName === 'BUTTON') {
        setFocusCount(focusCount + 1);
      }
    };

    const handleBlur = (event) => {
      event.preventDefault();
      event.stopPropagation();

      // if click figure,after click button
      if (refWrapper.current.contains(event.relatedTarget)) return;
      if (event.target === refWrapper.current && onBlur) {
        onBlur();
      }
    };

    useEffect(() => {
      setCurrentSrc(src);
    }, [src, ref]);

    // if click button icon,can focus figure element
    useEffect(() => {
      if (focusCount === 2) {
        refWrapper.current.focus();
        setFocusCount(0);
      }
    }, [ref, focusCount]);

    return (
      <Wrapper
        ref={refWrapper}
        roundedCircle={roundedCircle}
        onBlur={handleBlur}
        onFocus={handleFocus}
        tabIndex='0'
      >
        {currentSrc && (
          <ImageFluid
            crossOrigin='anonymous'
            src={currentSrc}
            alt={alt}
            rounded={true}
            roundedCircle={roundedCircle}
          />
        )}

        {ref && (
          <ButtonGroup roundedCircle={roundedCircle}>
            {ref.current &&
              ref.current.files &&
              ref.current.files.length !== 0 && (
                <CropButton
                  roundedCircle={roundedCircle}
                  onClick={() => {
                    handleCrop(currentSrc, ref.current.files[0]);
                  }}
                >
                  <MdCrop />
                </CropButton>
              )}

            <ModifyButton
              roundedCircle={roundedCircle}
              onClick={() => {
                ref.current.click();
              }}
            >
              <FaPen />
            </ModifyButton>
          </ButtonGroup>
        )}

        <HiddenFileInput
          ref={ref && ref}
          id={id}
          accept='.jpeg, .bmp, .jpg, .png'
          onChange={(event) => handleOnChange(event.target.files)}
        />

        {ref &&
          ref.current &&
          ref.current.files &&
          ref.current.files.length !== 0 && (
            <RemoveIcon
              onClick={handleAvatarRemove}
              roundedCircle={roundedCircle}
            >
              <FaTimes />
            </RemoveIcon>
          )}

        {allowClear && src && ref && !ref.current?.files?.length > 0 && (
          <RemoveIcon onClick={onClear} roundedCircle={roundedCircle}>
            <FaTimes />
          </RemoveIcon>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled(ImageFigure)`
  position: relative;
  padding: var(--spacing-xs);
`;

const ButtonGroup = styled.div`
  position: absolute;
  top: -16px;
  right: -12px;

  ${({ rounded }) =>
    rounded &&
    `
      top: 0;
      right: 0;
    `}
`;

const ActionButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border: none;
`;

const CropButton = styled(ActionButton)`
  color: var(--font-on-primary);
  background: var(--color-primary);
  border-radius: var(--border-radius);

  ${({ roundedCircle }) =>
    roundedCircle &&
    `
      position: relative;
      top: -16px;
    `}
`;

const ModifyButton = styled(ActionButton)`
  color: var(--font-on-primary);
  background: var(--color-primary);
  border-radius: var(--border-radius);
  margin-left: var(--spacing-xs);

  ${({ roundedCircle }) =>
    roundedCircle &&
    `
      position: relative;
      top: 4px;
    `}
`;

const RemoveIcon = styled(ActionButton)`
  position: absolute;
  color: var(--font-on-danger);
  background: var(--color-danger);
  border-radius: var(--border-radius);
  bottom: -12px;
  right: -12px;

  ${({ roundedCircle }) =>
    roundedCircle &&
    `
      bottom: 0;
      right: 4px;
    `}
`;

export default UploadImage;
