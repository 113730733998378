import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { COUPON_USAGELOG_RESULT } from '../../../provider/constant';
import { getCouponUsageLog } from '../../../module/coupon';
import handleApiResponse from '../../../utils/api/handleApiResponse';
import { useNavigate, useParams } from 'react-router-dom';
import { Table } from '../../../components/table/index';
import { useLimitChange, usePageChange } from '../../../components/table/hooks';
import {
  Section,
  SectionHeader,
  SectionBody,
} from '../../../components/section/index';
import { Heading1 } from '../../../components/heading/index';
import { Button, ButtonGroup } from '../../../components/button/index';

const RedeemCodeDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [RedeemCodeDetailData, setRedeemCodeDetailData] = useState([]);
  const [total, setTotal] = useState(1);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
  });
  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);

  const handleGetCouponUsageLog = useCallback(() => {
    const requestData = {
      id: id,
      page: listParams.page,
      limit: listParams.limit,
    };
    handleApiResponse(getCouponUsageLog(requestData), (response) => {
      const { list, total } = response.data.data;
      setRedeemCodeDetailData(list);
      setTotal(total);
    });
  }, [id, listParams]);

  useEffect(() => {
    handleGetCouponUsageLog();
  }, [handleGetCouponUsageLog]);

  return (
    <>
      <Section noPadding>
        <SectionHeader>
          <Heading1>{t('CouponUsageHistory')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse>
            <SectionBody noPadding>
              <Table
                backgroundReverse
                columns={[
                  {
                    title: t('PromoCode'),
                    fieldName: 'uniqueCode',
                  },
                  {
                    title: t('HardwareSerialNumber'),
                    fieldName: 'terminalSN',
                  },
                  {
                    title: t('Result'),
                    fieldName: 'result',
                    render(result) {
                      if (result >= 0 && result < 4) {
                        return t(COUPON_USAGELOG_RESULT[result].text);
                      } else {
                        return t('Unknown');
                      }
                    },
                  },

                  {
                    title: t('TotalAmount'),
                    fieldName: 'totalAmount',
                  },
                  {
                    title: t('TotalDiscountedAmount'),
                    fieldName: 'discountAmount',
                  },
                  {
                    title: t('TotalPaymentAmount'),
                    fieldName: 'paymentAmount',
                  },
                  {
                    title: t('ExchangeTime'),
                    fieldName: 'createdAt',
                  },
                ]}
                data={RedeemCodeDetailData}
                currentPage={listParams.page}
                limit={listParams.limit}
                onLimitChange={onLimitChange}
                total={total}
                sortColumn={listParams.sort_column}
                sortType={listParams.sort_type}
                onPageChange={onPageChange}
              />
            </SectionBody>
          </Section>
        </SectionBody>
      </Section>
      <ButtonGroup alignRight>
        <Button onClick={() => navigate(-1)}>{t('button:Back')}</Button>
      </ButtonGroup>
    </>
  );
};

export default RedeemCodeDetail;
