import styled from 'styled-components';
import media from '../../styles/mixin/media';

const Input = styled.input`
  width: 100%;
  height: calc(1.5rem * 2 + 1.5rem);
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(234, 233, 240, 0.8);
  padding: 1.5rem 0;
  color: #fff;
  font-size: 1.25rem;
  background-color: transparent !important;
  outline: none;
  font-family: Helvetica;

  ${({ hidden }) => hidden && 'display: none;'}


  ${({ alignCenter }) => alignCenter && 'text-align: center;'}

  ${media.mobile`
    height: calc(1.125rem * 2 + 0.9375rem);
    font-size: 0.9375rem;
    padding: 1.125rem 0;
  `}
`;

export default Input;
