import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '../../components/button';
import { FormItem, Input, Label } from '../../components/form';
import { Column, Grid } from '../../components/grid';
import { Heading1, Heading2 } from '../../components/heading';
import { Section, SectionBody, SectionHeader } from '../../components/section';
import { Select } from '../../components/Select';
import { getPromotionsAnalyze } from '../../module/analyses';
import { couponList } from '../../module/coupon';
import { showErrorMessage } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';

const Promotions = () => {
  const { t } = useTranslation();
  const [analyseData, setAnalyseData] = useState(null);
  const [promotionsList, setPromotionsList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedPromotion, setSelectedPromotion] = useState('');
  const [searchedpromotionTitle, setSearchedpromotionTitle] = useState('');
  const [isFetchingCouponList, setIsFetchingCouponList] = useState(false);
  const [isFirstTimeGetList, setIsFirstTimeGetList] = useState(true);

  const handleGetPromotionsList = useCallback(() => {
    const requestData = {
      page,
      limit: 10,
    };

    handleApiResponse(couponList(requestData), (response) => {
      const { list, total } = response.data.data;
      setTotal(total);
      setIsFetchingCouponList(false);
      if (page === 1) {
        setPromotionsList(list.map(({ id, title: text }) => ({ id, text })));
        setIsFirstTimeGetList(false);
      } else {
        const newPromotionsList = list.map(({ id, title: text }) => ({
          id,
          text,
        }));

        setPromotionsList((previous) => [...previous, ...newPromotionsList]);
      }
    });
  }, [page]);

  const handleOnScroll = (event) => {
    if (isFetchingCouponList) return;
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (
      scrollHeight - (scrollTop + clientHeight) <= 60 &&
      total !== promotionsList.length
    ) {
      setPage(page + 1);
      setIsFetchingCouponList(true);
      return true;
    } else if (page > 1) return true;
    return false;
  };

  const handleSearch = () => {
    if (!selectedPromotion) {
      showErrorMessage({ message: t('error:PleaseSelectPromotions') });
      return;
    }

    const requestData = { couponId: selectedPromotion };

    handleApiResponse(getPromotionsAnalyze(requestData), (response) => {
      const { data } = response.data;
      setAnalyseData(data);

      const { text } = promotionsList?.filter(
        ({ id }) => id === selectedPromotion,
      )[0];
      setSearchedpromotionTitle(text);
    });
  };

  const handleReset = () => {
    setAnalyseData(null);
    setSelectedPromotion('');
    setSearchedpromotionTitle('');
  };

  useEffect(() => {
    if (page === 1) return;
    handleGetPromotionsList();
  }, [handleGetPromotionsList, page]);

  useEffect(() => {
    if (!isFirstTimeGetList) return;
    handleGetPromotionsList();
  }, [handleGetPromotionsList, isFirstTimeGetList]);

  return (
    <>
      <Section noPadding>
        <SectionHeader>
          <Heading1>{t('PromotionsAnalyze')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse>
            <SectionBody noPadding>
              <Grid columns={2}>
                <Column desktop={2}>
                  <FormItem>
                    <Label>{t('Promotions')}</Label>
                    <Select
                      fullWidth
                      options={promotionsList}
                      selected={selectedPromotion}
                      onSelect={setSelectedPromotion}
                      onScroll={handleOnScroll}
                      isFirstTimeGetList={isFirstTimeGetList}
                    />
                  </FormItem>
                </Column>
              </Grid>

              <ButtonGroup alignCenter>
                <Button danger onClick={handleReset}>
                  {t('button:Reset')}
                </Button>
                <Button onClick={handleSearch}>{t('button:Search')}</Button>
              </ButtonGroup>
            </SectionBody>
          </Section>

          {analyseData !== null && (
            <Section backgroundReverse>
              <SectionHeader>
                <Heading2>{searchedpromotionTitle}</Heading2>
              </SectionHeader>
              <SectionBody>
                <Grid columns={4}>
                  <Column desktop={2}>
                    <FormItem>
                      <Label htmlFor='totalAmount'>{t('TotalAmount')}</Label>
                      <Input
                        type='text'
                        id='totalAmount'
                        value={analyseData.totalAmount}
                        disabled
                      />
                    </FormItem>
                  </Column>
                  <Column desktop={2}>
                    <FormItem>
                      <Label htmlFor='totalDiscountedAmount'>
                        {t('TotalDiscountedAmount')}
                      </Label>
                      <Input
                        type='text'
                        id='totalDiscountedAmount'
                        value={analyseData.discountAmount}
                        disabled
                      />
                    </FormItem>
                  </Column>
                  <Column desktop={2}>
                    <FormItem>
                      <Label htmlFor='totalPaymentAmount'>
                        {t('TotalPaymentAmount')}
                      </Label>
                      <Input
                        type='text'
                        id='totalPaymentAmount'
                        value={analyseData.paymentAmount}
                        disabled
                      />
                    </FormItem>
                  </Column>
                </Grid>
              </SectionBody>
            </Section>
          )}
        </SectionBody>
      </Section>
    </>
  );
};

export default Promotions;
