import generateRange from '../utils/array/generateRange';

const LANGUAGES = [
  { id: 'en', text: 'English' },
  { id: 'zh-TW', text: '繁體中文' },
];

const COUPON_TYPE = [
  { id: 1, text: 'FixedAmount' },
  { id: 2, text: 'Percentage' },
  { id: 3, text: 'Free' },
];

const COUPON_CATEGORY = [
  { id: 1, text: 'Coupon' },
  { id: 2, text: 'PromoCode' },
];

const COUPON_STATUS = [
  { id: 0, text: 'NotStarted' },
  { id: 1, text: 'Ongoing' },
  { id: 2, text: 'Ended' },
];

const USER_STATUS = [
  { id: 0, text: 'Enabled' },
  { id: 1, text: 'Disabled' },
];

const DISCOUNT_CODE = [
  { id: 0, text: 'Unused' },
  { id: 1, text: 'Used' },
];

const DISCOUNT_CODE_STATUS = [
  { id: 0, text: 'All' },
  { id: 1, text: 'Used' },
  { id: 2, text: 'Unused' },
];

const COUPON_USAGELOG_RESULT = [
  { id: 0, text: 'Sucess' },
  { id: 1, text: 'PaymentFailed' },
  { id: 2, text: 'CouponFailed' },
  { id: 3, text: 'UserCanceled' },
  { id: 4, text: 'PaymentTimeOutCanceled' },
];

const REPORT_COLUMNS_TYPE = [
  {
    id: 'string',
    text: 'String',
  },
  {
    id: 'number',
    text: 'Ｎumber',
  },
  {
    id: 'boolean',
    text: 'Boolean',
  },
];

const AUTOMATED_REPORT_TYPE = [
  {
    id: 1,
    text: 'Daily',
  },
  {
    id: 2,
    text: 'Weekly',
  },
  {
    id: 4,
    text: 'Bi-weekly',
  },
  {
    id: 3,
    text: 'Monthly',
  },
];

const AUTOMATED_REPORT_SEND_WEEK = [
  {
    id: 1,
    text: 'Monday',
  },
  {
    id: 2,
    text: 'Tuesday',
  },
  {
    id: 3,
    text: 'Wednesday',
  },
  {
    id: 4,
    text: 'Thursday',
  },
  {
    id: 5,
    text: 'Friday',
  },
  {
    id: 6,
    text: 'Saturday',
  },
  {
    id: 7,
    text: 'Sunday',
  },
];

const AUTOMATED_REPORT_SEND_HOUR = generateRange(0, 23).map((hour) => {
  const hourText = hour.toString();
  const formattedHour = hourText.length === 1 ? `0${hourText}` : hourText;
  return { id: formattedHour, text: formattedHour };
});

const AUTOMATED_REPORT_SEND_DAY = generateRange(1, 31).map((day) => {
  return { id: day, text: day.toString() };
});

export {
  LANGUAGES,
  COUPON_TYPE,
  COUPON_CATEGORY,
  COUPON_STATUS,
  USER_STATUS,
  DISCOUNT_CODE,
  DISCOUNT_CODE_STATUS,
  COUPON_USAGELOG_RESULT,
  REPORT_COLUMNS_TYPE,
  AUTOMATED_REPORT_TYPE,
  AUTOMATED_REPORT_SEND_WEEK,
  AUTOMATED_REPORT_SEND_HOUR,
  AUTOMATED_REPORT_SEND_DAY,
};
