const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const [, mime] = arr[0].match(/:(.*?);/);
  const bstr = atob(arr[1]);
  let index = bstr.length;
  const u8arr = new Uint8Array(index);
  while (index > 0) {
    index -= 1;
    u8arr[index] = bstr.charCodeAt(index);
  }

  return new File([u8arr], filename, { type: mime });
};

export default dataURLtoFile;
