import styled from 'styled-components';

const Heading3 = styled.h3`
  font-size: var(--font-h3);
  word-break: break-word;
  white-space: pre-wrap;

  ${({ center }) => center && 'text-align: center;'}

  ${({ fontWeightNormal }) => fontWeightNormal && 'font-weight: normal;'}

  ${({ marginBottomS }) => marginBottomS && 'margin-bottom: var(--spacing-s);'}

  ${({ marginBottomXS }) =>
    marginBottomXS && 'margin-bottom: var(--spacing-xs);'}

  ${({ paddingBottomXS }) =>
    paddingBottomXS && 'padding-bottom: var(--spacing-xs);'}
`;

export default Heading3;
