import button from './button.json';
import error from './error.json';
import placeholder from './placeholder.json';
import success from './success.json';
import translation from './translation.json';

const en = {
  button: button,
  error: error,
  placeholder: placeholder,
  success: success,
  translation: translation,
};

export default en;
