import { useCallback } from 'react';

const usePageChange = (setState) => {
  return useCallback(
    (page) => {
      setState((previous) => {
        if (typeof previous === 'object') {
          return {
            ...previous,
            page,
          };
        }

        return page;
      });
    },
    [setState],
  );
};

export default usePageChange;
