import { useCallback } from 'react';
import TableSort from '../subComponent/TableSort';

const useGenerateSort = (sortColumn, sortType) => {
  return useCallback(
    (fieldName) => {
      let isActiveAsc = false;
      let isActiveDesc = false;
      if (Array.isArray(sortColumn) && Array.isArray(sortType)) {
        const sortColumnIndex = sortColumn.indexOf(fieldName);
        if (sortColumnIndex !== -1) {
          if (sortType[sortColumnIndex] === 'asc') {
            isActiveAsc = true;
          }

          if (sortType[sortColumnIndex] === 'desc') {
            isActiveDesc = true;
          }
        }
      } else if (fieldName === sortColumn && sortType === 'asc') {
        isActiveAsc = true;
      } else if (fieldName === sortColumn && sortType === 'desc') {
        isActiveDesc = true;
      }

      return (
        <TableSort>
          <TableSort.UpIcon $active={isActiveAsc} />
          <TableSort.DownIcon $active={isActiveDesc} />
        </TableSort>
      );
    },
    [sortColumn, sortType],
  );
};

export default useGenerateSort;
