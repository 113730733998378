import { useContext, cloneElement } from 'react';
import DropdownContext from './subComponent/DropdownContext';

const DropdownToggle = ({ children, ...props }) => {
  const { display, setDisplay, setInitialized, setToggleElement, update } =
    useContext(DropdownContext);

  const handleToggle = () => {
    setInitialized(true);
    setDisplay(!display);

    if (update) {
      update();
    }
  };

  return (
    <div onClick={handleToggle} {...props}>
      {cloneElement(children, {
        ref: setToggleElement,
      })}
    </div>
  );
};

export default DropdownToggle;
