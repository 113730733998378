import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import Input from './Input';
import Label from './Label';
import { MdDone } from 'react-icons/md';

const Checkbox = forwardRef(
  (
    { htmlFor, text, disabled, checked, onChange, noMargin, fontSizeBig },
    ref,
  ) => {
    return (
      <StyledLabel
        htmlFor={htmlFor}
        disabled={disabled}
        ref={ref}
        noMargin={noMargin}
        fontSizeBig={fontSizeBig}
      >
        <StyledCheckbox
          checked={checked}
          type='checkbox'
          id={htmlFor}
          disabled={disabled}
          onChange={onChange}
          fontSizeBig={fontSizeBig}
        />
        <span>
          <Unchecked />
          <Checked>
            <MdDone />
          </Checked>
          <Text>{text}</Text>
        </span>
      </StyledLabel>
    );
  },
);

const StyledCheckbox = styled(Input)`
  display: none;

  &:checked + span {
    > div:last-of-type {
      display: block;
      color: var(--color-primary);
    }
    > div:first-of-type {
      display: none;
    }
  }
`;

const StyledLabel = styled(Label)`
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;

  > span {
    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.5;
        cursor: not-allowed;
      `}
    display: flex;
    align-items: center;

    > div {
      margin-right: var(--spacing-xs);
      width: 24px;
      height: 24px;
      border-radius: 4px;
    }

    > div:last-of-type {
      display: none;
    }
  }

  ${({ fontSizeBig }) => fontSizeBig && 'font-size: var(--font-h3);'}

  ${({ noMargin }) => noMargin && 'margin: 0;'}
`;

const Unchecked = styled.div`
  flex: 0 0 auto;
  border: var(--border-width) solid var(--border-color);
`;

const Checked = styled.div`
  flex: 0 0 auto;
  border: var(--border-width) solid var(--color-primary);
  background: var(--color-primary);
  position: relative;

  > svg {
    position: absolute;
    color: var(--color-white);
    font-size: var(--font-h3);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Text = styled.p`
  word-break: break-all;
`;

export default Checkbox;
