import { callApi } from '../services/api';

const couponList = ({ page, limit }) => {
  const params = { page, limit };
  return callApi.get('/coupons', { params });
};

const createCoupon = (data) => {
  return callApi.post('/coupons', data);
};

const deleteCoupon = (id) => {
  const params = { couponId: id };
  return callApi.delete('/coupons', { params });
};

const getCoupon = (id) => {
  return callApi.get('/coupons/info', { params: { couponId: id } });
};

const getDiscountCodeList = (data) => {
  return callApi.get('/coupons/redeem-codes', { params: data });
};

const assignDiscountCode = (data) => {
  return callApi.post('/members/redeem-codes', data);
};

const getTimezoneList = (data) => {
  return callApi.get('/timezone', { params: data });
};

const modifyCouponInfo = (data) => {
  return callApi.put('/coupons', data);
};

const exportDiscountCodeLog = (data) => {
  return callApi.post('/coupons/usage-logs/export', data);
};

const checkDiscountCodeLogStatus = (data) => {
  return callApi.get('/exports-status', { params: data });
};

const downdloadDiscountCodeTemplate = () => {
  return callApi.get('/templates/redeem-code', { responseType: 'blob' });
};

const ImportDiscountCodeList = (data, config) => {
  return callApi.post('/members/redeem-codes/import', data, {
    ...config,
  });
};

const deleteMemberCoupon = (id) => {
  const params = { id };
  return callApi.delete('/coupons/member-coupons', { params });
};

const resendRedeemCode = (data) => {
  return callApi.post('/members/redeem-codes/resend', data);
};

const getCouponUsageLog = (data) => {
  return callApi.get('/coupons/usage-logs', { params: data });
};

// get promotions analyze
const getPromotionsAnalyze = (data) => {
  return callApi.get('/coupons/analyses', { params: data });
};
export {
  couponList,
  createCoupon,
  deleteCoupon,
  getCoupon,
  getDiscountCodeList,
  assignDiscountCode,
  getTimezoneList,
  modifyCouponInfo,
  exportDiscountCodeLog,
  checkDiscountCodeLogStatus,
  downdloadDiscountCodeTemplate,
  ImportDiscountCodeList,
  deleteMemberCoupon,
  resendRedeemCode,
  getCouponUsageLog,
  getPromotionsAnalyze,
};
