const customTooltip = (theme) => {
  // optimize: render in react way
  return (context) => {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<table></table>';
      document.body.appendChild(tooltipEl);
    }

    // Hide tooltip, if no tooltip or no data
    const tooltipModel = context.tooltip;
    if (
      !tooltipModel ||
      !tooltipModel.dataPoints ||
      !tooltipModel.dataPoints[0] ||
      tooltipModel.opacity === 0
    ) {
      tooltipEl.style.display = 'none';
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    // Set Text
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map((bodyItem) => {
        return bodyItem.lines;
      });

      let innerHtml = '<thead>';
      titleLines.forEach((title) => {
        innerHtml += `<tr><th>${title}</th></tr>`;
      });
      innerHtml += '</thead><tbody>';

      bodyLines.forEach((body) => {
        innerHtml += `<tr><td>${body}</td></tr>`;
      });
      innerHtml += '</tbody>';

      const tableRoot = tooltipEl.querySelector('table');
      tableRoot.innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.fontFamily = theme.fontFamily;
    tooltipEl.style.fontSize = theme.fontBody1;
    tooltipEl.style.display = 'block';
    tooltipEl.style.position = 'absolute';

    [tooltipEl.style.color] = tooltipModel.labelTextColors;
    tooltipEl.style.background = tooltipModel.labelColors[0].backgroundColor;
    tooltipEl.style.borderRadius = theme.borderRadius;
    tooltipEl.style.textAlign = 'center';
    tooltipEl.style.padding = `${theme.spacerXS} ${theme.spacerS}`;
    tooltipEl.style.pointerEvents = 'none';

    tooltipEl.style.top = `${
      position.top + window.pageYOffset + tooltipModel.caretY + 8
    }px`;

    // determine position is left or right
    if (tooltipModel.caretX + tooltipEl.offsetWidth <= context.chart.width) {
      tooltipEl.style.borderTopLeftRadius = 0;
      tooltipEl.style.left = `${
        position.left + window.pageXOffset + tooltipModel.caretX + 8
      }px`;
    } else {
      tooltipEl.style.borderTopRightRadius = 0;
      tooltipEl.style.left = `${
        position.left +
        window.pageXOffset +
        tooltipModel.caretX -
        tooltipModel.width -
        16 * 2
      }px`;
    }
  };
};

export default customTooltip;
