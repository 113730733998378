import styled, { css } from 'styled-components';

const IconButton = styled.div.attrs(() => ({
  role: 'button',
}))`
  width: 40px;
  height: 40px;
  transition: 0.3s;
  position: relative;
  border-radius: 0.5rem;
  color: ${({ disabled }) => {
    return disabled ? '#5b5f6f' : '#fffff';
  }};
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  margin: auto;

  ${({ size }) =>
    size === 'small' &&
    css`
      width: 32px;
      height: 32px;
    `}

  &:hover {
    ${({ disabled }) => !disabled && 'rgba(89, 126, 247, 0.2)'}
  }

  > svg {
    font-size: 1.5rem;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${({ size }) => size === 'small' && 'font-size: 1rem;'}

    ${({ size }) => size === 'large' && 'font-size: 2rem;'}
  }
`;

export default IconButton;
