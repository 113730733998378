const resizeImage = (url, mime, size, mode, callback) => {
  let canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = () => {
    let { height, width } = img;

    if (mode === 'contain') {
      if (height > width) {
        width = width * (size / height);
        height = size;
      } else {
        height = height * (size / width);
        width = size;
      }
    }

    if (mode === 'cover') {
      if (height > width) {
        height = height * (size / width);
        width = size;
      } else {
        width = width * (size / height);
        height = size;
      }
    }

    canvas.height = height;
    canvas.width = width;
    ctx.drawImage(img, 0, 0, width, height);
    callback(canvas.toDataURL(mime));
    canvas = null;
  };
  img.src = url;
};

export default resizeImage;
