import { css } from 'styled-components';

const backgroundColor = css`
  ${({ primary }) => primary && 'background-color: var(--color-primary);'}

  ${({ success }) => success && 'background-color: var(--color-success);'}

  ${({ warning }) => warning && 'background-color: var(--color-warning);'}

  ${({ danger }) => danger && 'background-color: var(--color-danger);'}
`;

export default backgroundColor;
