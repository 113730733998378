import { useState, useRef, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { FaAngleDown } from 'react-icons/fa';

const Select = ({ options, selected, onSelect }) => {
  const wrapperRef = useRef(null);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [display, setDisplay] = useState(false);
  const [value, setValue] = useState([]);

  const handleOnSelect = useCallback(
    (e, value) => {
      e.stopPropagation();

      onSelect(value);
      wrapperRef.current.blur();
    },
    [onSelect],
  );

  const selectedItem = useCallback(() => {
    return value.length > 0 && <span>{value[0].text}</span>;
  }, [value]);

  const optionsItem = useMemo(() => {
    const seletedItems = [];
    const items =
      options &&
      options.map((option) => {
        let active = '';
        if (selected === option.id) {
          seletedItems.push(option);
          active = true;
        }

        return (
          <OptionItem
            onClick={(e) => {
              handleOnSelect(e, option.id);
            }}
            key={option.id}
            active={active}
            role='option'
          >
            {option.text}
          </OptionItem>
        );
      });

    setValue(seletedItems);
    return items;
  }, [options, selected, handleOnSelect]);

  const handleDisplay = useCallback(() => {
    setDisplay(true);
    setWrapperHeight(wrapperRef.current.clientHeight);
  }, []);

  const handleHide = useCallback(() => {
    setDisplay(false);
  }, []);

  const handleOnClick = () => {
    if (display === true) handleHide();
    else handleDisplay();
  };

  return (
    <Wrapper
      onClick={handleOnClick}
      onBlur={handleHide}
      tabIndex='0'
      ref={wrapperRef}
      role='combobox'
    >
      <span data-testid='value'>{selected && selectedItem()}</span>
      <FaAngleDown />

      <Option $display={display} wrapperHeight={wrapperHeight} role='listbox'>
        {optionsItem}
      </Option>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: calc(1.5rem * 2 + 1.5rem);
  border-bottom: 1px solid rgba(234, 233, 240, 0.8);
  color: #fff;
  font-size: 1.25rem;
  padding: 1.5rem 0;

  > svg {
    position: absolute;
    right: 8px;
    top: calc(50%);
    transform: translateY(-50%);
  }
`;

const Option = styled.div`
  display: ${(props) => (props.$display ? 'block' : 'none')};
  position: absolute;
  background: var(--color-background3);
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 50%);
  width: 100%;
  left: 0;
  top: ${({ wrapperHeight }) => wrapperHeight + 8}px;
  max-height: 200px;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(212, 208, 246, 1);
    border: 1px solid rgba(212, 208, 246, 1);
    border-radius: 25px;
  }

  &::-webkit-scrollbar-track {
    background-color: #222143;
    border-radius: 25px;
  }
`;

const OptionItem = styled.div`
  max-width: 100%;
  word-break: break-all;
  padding: 16px 5px 16px 18px;
  transition: 0.3s;

  &:hover {
    background: var(--color-hover);
  }

  ${({ active }) => active === true && 'background: var(--color-hover);'}
`;

export default Select;
