import { useEffect } from 'react';
import L from 'leaflet';
import { useMapEvents } from 'react-leaflet';

const MapInteractor = ({ changeLatlng }) => {
  const map = useMapEvents({
    click: (event) => {
      const { lat, lng } = event.latlng;
      changeLatlng({ lat: lat.toString(), lng: lng.toString() });
    },
  });

  useEffect(() => {
    const bounds = L.latLngBounds([
      [90, 180],
      [-90, -180],
    ]);
    const wantedZoom = map.getBoundsZoom(bounds, true);

    const center = bounds.getCenter();
    map.setView(center);
    map.setZoom(wantedZoom);
  }, [map]);

  return null;
};

export default MapInteractor;
