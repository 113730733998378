import { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  defaults,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import customTooltip from './utils/customTooltip';
import padEmptyChartBar from './utils/padEmptyChartBar';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);
const BarChart = ({ height = 300, data, options = {} }) => {
  const theme = useContext(ThemeContext);

  // fix: read value from theme
  defaults.font.family = theme.fontFamily;
  defaults.font.size = 16;
  defaults.color = theme.fontOnBg;
  defaults.plugins.tooltip.external = customTooltip(theme);

  const customOptions = useMemo(() => {
    return {
      onlyFirstTooltips:
        options.onlyFirstTooltips === undefined
          ? true
          : options.onlyFirstTooltips,
      maxBarNumber:
        options.maxBarNumber === undefined ? 5 : options.maxBarNumber,
      stacked: options.stacked === undefined ? true : options.stacked,
      suggestedMax:
        options.suggestedMax === undefined ? 10 : options.suggestedMax,
      showLegend: options.showLegend === undefined ? false : options.showLegend,
      rotateLabel:
        options.rotateLabel === undefined ? true : options.rotateLabel,
    };
  }, [options]);

  const defaultOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
      plugins: {
        padEmptyChartBar: {
          maxBarNumber: customOptions.maxBarNumber,
        },
        legend: {
          position: 'bottom',
          display: customOptions.showLegend,
        },
        tooltip: {
          enabled: false,
          intersect: false,
          filter(context) {
            return (
              !customOptions.onlyFirstTooltips ||
              (context.datasetIndex === 0 && context.label !== 'null')
            );
          },
          callbacks: {
            title(context) {
              if (!context[0]) {
                return '';
              }

              if (context[0].dataset.customTitle) {
                return context[0].dataset.customTitle[
                  context[0].dataIndex
                ].toString();
              }

              return context[0].label.match(/(.{1,20})/g);
            },
            label(context) {
              const label = context.dataset.data[context.dataIndex];

              if (context.dataset.customLabel) {
                return context.dataset.customLabel[
                  context.dataIndex
                ].toString();
              }

              return label.toString();
            },
            labelTextColor() {
              return theme.fontOnPrimary;
            },
          },
        },
      },
      scales: {
        x: {
          stacked: customOptions.stacked,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            minRotation: customOptions.rotateLabel ? 15 : 0,
            callback(value) {
              const label = this.getLabelForValue(value);
              return label && label.length > 11
                ? `${label.substr(0, 11)}...`
                : label;
            },
          },
        },
        y: {
          beginAtZero: true,
          suggestedMax: customOptions.suggestedMax,
          stacked: customOptions.stacked,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            callback(value) {
              if (value % 1 === 0) {
                return value;
              }

              return null;
            },
          },
        },
      },
    };
  }, [customOptions, theme.fontOnPrimary]);

  const Chart = useMemo(() => {
    return (
      <Bar
        height={height}
        data={data}
        options={defaultOptions}
        plugins={[padEmptyChartBar]}
      />
    );
  }, [height, data, defaultOptions]);

  return Chart;
};

export default BarChart;
