import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 1fr));
  margin: 0 calc(var(--spacing) * -1 / 2);

  > * {
    grid-column-end: span ${({ columns }) => columns};
  }

  ${({ alignCenter }) => alignCenter && 'align-items: center;'}
`;

export default Grid;
