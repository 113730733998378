const datetimeTFormat = (timestamp) => {
  if (typeof timestamp === 'string') {
    let [year, month, dayAndTime] = timestamp.split('-');
    if (year.length === 1) year = `000${year}`;
    if (year.length === 2) year = `00${year}`;
    if (year.length === 3) year = `0${year}`;
    if (month.length < 2) month = `0${month}`;

    let [day, time] = dayAndTime.split(' ');
    let [hour, minutes, second] = time.split(':');
    if (day.length < 2) day = `0${day}`;
    if (hour.length < 2) hour = `0${hour}`;
    if (minutes.length < 2) minutes = `0${minutes}`;
    if (second.length < 2) second = `0${second}`;
    return `${year}-${month}-${day}T${hour}:${minutes}:${second}`;
  }

  const date = new Date(timestamp);
  let second = `${date.getSeconds()}`;
  let minutes = `${date.getMinutes()}`;
  let hour = `${date.getHours()}`;
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;
  let year = `${date.getFullYear()}`;

  if (year.length === 1) year = `000${year}`;
  if (year.length === 2) year = `00${year}`;
  if (year.length === 3) year = `0${year}`;
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  if (hour.length < 2) hour = `0${hour}`;
  if (minutes.length < 2) minutes = `0${minutes}`;
  if (second.length < 2) second = `0${second}`;

  return `${year}-${month}-${day}T${hour}:${minutes}:${second}`;
};

export default datetimeTFormat;
