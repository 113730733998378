// Currently, some style is all same for all viewport size
// Common color style
const colorStyle = {
  colorWhite: '#ffffff',
  colorDark: '#000000',
  colorGrey: '#5b5f6f',
  colorPrimary: '#597EF7',
  colorSuccess: '#11D8CD',
  colorWarning: '#FFCA7A',
  colorDanger: '#F495A1',
  colorHover: 'rgba(89, 126, 247, 0.2)',
  colorActive: 'rgba(89, 126, 247, 0.2)',
  scrollBarColor: 'rgba(89, 126, 247, 0.3)',
  bgColor1: '#13142e',
  bgColor2: '#222143',
  bgColor3: '#2d2c4c',
  bgColor4: '#2d2a5d',
  backdropBg: 'rgba(0, 0, 0, 0.5)',
};

// Common font style
const fontStyle = {
  fontH1: '2.25rem',
  fontH2: '1.5rem',
  fontH3: '1.25rem',
  fontBody1: '1rem',
  fontBody2: '0.875rem',
  fontButton: '0.875rem',
  fontCaption: '0.75rem',
  fontFamily: 'Helvetica',
  fontWeightNormal: '400',
  fontWeightBold: 'bold',
  fontOnPrimary: colorStyle.colorWhite,
  fontOnSuccess: colorStyle.colorWhite,
  fontOnWarning: colorStyle.colorWhite,
  fontOnDanger: colorStyle.colorWhite,
  fontOnBg: colorStyle.colorWhite,
  fontMutedColor: 'rgba(255, 255, 255, 0.5)',
};

// Theme stylesheet
const darkTheme = {
  desktop: {
    ...fontStyle,
    ...colorStyle,

    boxShadow: '0px 0px 8px rgba(91, 95, 111, 0.5)',
    borderColor: '#5b5f6f',
    borderWidth: '1px',
    borderRadiusS: '0.5rem',
    borderRadius: '1rem',
    borderRadiusL: '2rem',
    borderRadiusRound: '50%',

    spacerXS: '0.5rem',
    spacerS: '1rem',
    spacer: '1.5rem',
    spacerL: '2rem',
  },
  tablet: {
    ...fontStyle,
    ...colorStyle,

    boxShadow: '0px 0px 8px rgba(91, 95, 111, 0.5)',
    borderColor: '#5b5f6f',
    borderWidth: '1px',
    borderRadiusS: '0.5rem',
    borderRadius: '1rem',
    borderRadiusL: '2rem',
    borderRadiusRound: '50%',

    spacerXS: '0.5rem',
    spacerS: '0.75rem',
    spacer: '1rem',
    spacerL: '1.25rem',
  },
  mobile: {
    ...fontStyle,
    ...colorStyle,

    boxShadow: '0px 0px 8px rgba(91, 95, 111, 0.5)',
    borderColor: '#5b5f6f',
    borderWidth: '1px',
    borderRadiusS: '0.5rem',
    borderRadius: '1rem',
    borderRadiusL: '2rem',
    borderRadiusRound: '50%',

    spacerXS: '0.5rem',
    spacerS: '0.75rem',
    spacer: '1rem',
    spacerL: '1.25rem',
  },
};

export default darkTheme;
