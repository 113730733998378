import { callApi } from '../services/api';

// terminal
const getTerminalList = (data) => {
  return callApi.get('/terminals', { params: data });
};

const getTerminalImportTemplate = () => {
  return callApi.get('/terminals/templates');
};

const importTerminal = (data, config) => {
  return callApi.post('/terminals/import', data, {
    ...config,
  });
};

const createTerminal = (data) => {
  return callApi.post('/terminals', data);
};

const getTerminalDetails = (id) => {
  return callApi.get(`/terminals/${id}`);
};

const modifyTerminal = (id, data) => {
  return callApi.patch(`/terminals/${id}`, data);
};

const batchDeleteTerminal = (ids) => {
  return callApi.post('/terminals/delete-batch', {
    id: ids,
  });
};

const deleteTerminal = (id) => {
  return callApi.delete('/terminals', { params: id });
};

const exportTerminals = () => {
  return callApi.post('/terminals/export');
};

// terminal group
const getTerminalGroupList = (data) => {
  return callApi.get('/terminal-groups', { params: data });
};

const createTerminalGroup = (data) => {
  return callApi.post('/terminal-groups', data);
};

const deleteTerminalGroup = (id) => {
  return callApi.delete('/terminal-groups', { params: id });
};

const modifyTerminalGroup = (data) => {
  return callApi.put('/terminal-groups', data);
};

const getTerminalGroupInfo = (id) => {
  return callApi.get('/terminal-groups/info', { params: { id } });
};

const getTerminalOfGroup = (data) => {
  return callApi.get('/terminal-groups/terminals', { params: data });
};

export {
  getTerminalList,
  getTerminalImportTemplate,
  importTerminal,
  createTerminal,
  getTerminalDetails,
  modifyTerminal,
  batchDeleteTerminal,
  deleteTerminal,
  exportTerminals,
  getTerminalGroupList,
  createTerminalGroup,
  deleteTerminalGroup,
  modifyTerminalGroup,
  getTerminalGroupInfo,
  getTerminalOfGroup,
};
