import { css } from 'styled-components';

const colorOnBackground = css`
  ${({ primary }) => primary && 'color: var(--font-on-primary);'}

  ${({ success }) => success && 'color: var(--font-on-success);'}

  ${({ warning }) => warning && 'color: var(--font-on-danger);'}

  ${({ danger }) => danger && 'color: var(--font-on-warning);'}
`;

export default colorOnBackground;
