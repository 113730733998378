import { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { showErrorMessage } from '../module/message';
import AppContext from '../provider/context/app.context';
import AuthenticateContext from '../provider/context/authenticate.context';
import AxiosInterceptor from '../services/api';
import themes from '../styles/theme';
import GlobalStyle from '../components/GlobalStyle';
import Router from './Router';

const App = () => {
  const { i18n } = useTranslation();
  const { setLoading, mode } = useContext(AppContext);
  const { authToken, removeAuthenticate } = useContext(AuthenticateContext);

  const handleBeforeSend = useCallback(
    (config) => {
      if (config.global !== false) {
        setLoading(true);
      }

      // call external api no need send authenticate information
      if (authToken && !config.external) {
        config.headers['Auth-Token'] = authToken;
      }
      config.headers['Lang'] = i18n.language;

      return config;
    },
    [setLoading, authToken, i18n.language],
  );

  const handleDone = useCallback(
    (response) => {
      // call external api no need check status
      if (response.config.external) {
        return response;
      }

      const { data } = response;
      if (data !== undefined) {
        switch (data.code) {
          // redirect to login page when access token invalid
          case 401:
            removeAuthenticate();
            return null;
          default:
            break;
        }
      }

      switch (response.status) {
        case 200:
        case 201:
        case 202:
        case 204:
          if (
            data !== undefined &&
            !(data instanceof Blob) &&
            data.code !== 0 &&
            response.config.url !== '/reports/export/results'
          ) {
            showErrorMessage({ message: data.message });
            return null;
          }

          return response;
        default:
          showErrorMessage({ message: data.message });
      }
    },
    [removeAuthenticate],
  );

  const handleAllDone = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  return (
    <ThemeProvider theme={themes.dark[mode]}>
      <GlobalStyle />

      <AxiosInterceptor
        onBeforeSend={handleBeforeSend}
        onDone={handleDone}
        onAllDone={handleAllDone}
      />

      <Router />
    </ThemeProvider>
  );
};
export default App;
