const sidebarStyle = {
  close: {
    width: '72px',
    height: '100%',
  },
  open: {
    width: '316px',
    height: '100%',
  },
};

export default sidebarStyle;
