import styled from 'styled-components';
import { MapContainer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const CustomLeafletMapContainer = styled(MapContainer)`
  width: 100%;
  height: ${({ height }) => height}px;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  z-index: 1;

  &:focus {
    border-color: var(--border-color);
    box-shadow: var(--box-shadow);
  }

  .leaflet-control-container {
    .leaflet-control-zoom {
      box-shadow: var(--box-shadow);
      border-radius: var(--border-radius);

      a {
        background: transparent;
        border-bottom: 1px solid var(--border-color);
        color: var(--font-on-background);

        &:last-child {
          border-bottom: none;
        }

        &.leaflet-disabled {
          opacity: 0.5;
        }
      }
    }

    .leaflet-draw {
      box-shadow: var(--box-shadow);
      border-radius: var(--border-radius);

      a {
        background-color: var(--color-background1);
      }

      .leaflet-draw-draw-polyline {
        background-position: -3px -3px;
      }

      .leaflet-draw-draw-polygon {
        background-position: -32px -3px;
      }

      .leaflet-draw-draw-rectangle {
        background-position: -63px -3px;
      }

      .leaflet-draw-draw-circle {
        background-position: -93px -3px;
      }

      .leaflet-draw-draw-marker {
        background-position: -123px -3px;
      }

      .leaflet-draw-draw-circlemarker {
        background-position: -273px -3px;
      }

      .leaflet-draw-edit-edit {
        background-position: -213px -3px;
      }

      .leaflet-draw-edit-remove {
        background-position: -243px -3px;
      }
    }

    .leaflet-left .leaflet-control {
      margin-left: var(--spacing-s);
    }

    .leaflet-right .leaflet-control {
      margin-right: var(--spacing-s);
    }

    .leaflet-top .leaflet-control {
      margin-top: var(--spacing-s);
    }

    .leaflet-control {
      font-weight: bold;
      background: var(--color-background1);

      &.leaflet-control-attribution {
        background: var(--color-white);
        color: var(--color-dark);
        margin: 0;
      }
    }
  }

  .leaflet-edit-move {
    border-radius: 50px;
    border-color: var(--border-color);
  }

  .leaflet-edit-resize {
    border-radius: 50px;
    border-color: var(--border-color);
  }

  &.leaflet-touch {
    .leaflet-bar {
      border: none;

      a {
        width: 24px;
        height: 24px;
        line-height: 24px;
      }
    }

    .leaflet-left .leaflet-control {
      margin-left: var(--spacing-s);
    }

    .leaflet-right .leaflet-control {
      margin-right: var(--spacing-s);
    }

    .leaflet-top .leaflet-control {
      margin-top: var(--spacing-s);
    }
  }

  .marker-cluster {
    background: var(--color-primary) a1;

    div {
      background: var(--color-primary);
      color: var(--font-on-primary);
    }
  }

  .leaflet-popup-content-wrapper {
    background: transparent;
    box-shadow: unset;
  }

  .leaflet-popup-tip {
    display: none;
  }

  .sr-only {
    display: none;
  }
`;

export default CustomLeafletMapContainer;
