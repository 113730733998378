const testEmail = (text) => {
  const emailReg =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return emailReg.test(text);
};

const testContactData = (text) => {
  const contactDataReg =
    /^(?:\d+|[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*)$/;
  return contactDataReg.test(text);
};

export { testEmail, testContactData };
