import styled from 'styled-components';

const Paragraph = styled.p`
  color: var(--font-on-background);
  font-size: var(--font-body1);
  margin-bottom: var(--spacing-s);
  word-break: break-word;
  overflow-wrap: break-word;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ flex }) => flex && 'display: flex;'}

  ${({ justifyContentBetween }) =>
    justifyContentBetween && 'justify-content: space-between;'}

  ${({ inline }) =>
    inline &&
    `
      display: inline;
      margin-right: var(--spacing-s);
    `}

  ${({ fontH3 }) => fontH3 && 'font-size: var(--font-h3);'}

  ${({ fontWeightBold }) => fontWeightBold && 'font-weight: bold;'}

  ${({ marginBottomXS }) =>
    marginBottomXS && 'margin-bottom: var(--spacing-xs);'}
`;

export default Paragraph;
