import { useCallback } from 'react';
import isInteger from '../../../utils/check/isInteger';

const useChangeNumber = (min, max, setState, name) => {
  return useCallback(
    (number) => {
      let value = String(number);

      // update value when value is a valid value
      if (value.length > 0) {
        // if value is not a integer, then remove last char
        if (!isInteger(value)) {
          value = value.substring(0, value.length - 1);
        }

        // use min value instead when exceed minimum
        if (value.length >= String(min).length && value < min) {
          value = min;
        }

        // use max value instead when exceed maximum
        if (value > max) {
          value = max;
        }
      }

      setState((previous) => {
        if (name && typeof previous === 'object') {
          const newState = { ...previous };
          newState[name] = value;

          return newState;
        }

        return value;
      });
    },
    [min, max, setState, name],
  );
};

export default useChangeNumber;
