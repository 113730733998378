import { useState, useRef } from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import styled from 'styled-components';
import uniqueId from 'lodash.uniqueid';
import { BsCheckCircle, BsExclamationCircle } from 'react-icons/bs';
import { FaRegTimesCircle } from 'react-icons/fa';
import ButtonGroup from '../button/ButtonGroup';
import Button from '../button/Button';
import ModalBackdrop from '../backdrop/ModalBackdrop';
import { appendElementToBody } from '../../utils/element';

const StateModal = ({ onClose, ...props }) => {
  const rootElement = appendElementToBody('StateModalContainer');
  const Container = document.createElement('div');

  rootElement.appendChild(Container);

  const handleClose = () => {
    // unmount component and clear event handler and state before remove container
    unmountComponentAtNode(Container);
    Container.remove();

    if (onClose) onClose();
  };

  ReactDOM.render(<ModalContent {...props} onClose={handleClose} />, Container);
};

const ModalContent = ({
  type,
  title,
  text,
  confirmButtonText,
  cancelButtonText,
  showCancelButton,
  onClose,
  onConfirm,
  onCancel,
  allowDismiss = true,
}) => {
  const refWrapper = useRef(null);
  const [id] = useState(uniqueId('SD-'));

  let Icon = BsCheckCircle;
  if (type === 'error') Icon = FaRegTimesCircle;
  if (type === 'warning') Icon = BsExclamationCircle;

  const handleDismiss = (event) => {
    if (event.target === refWrapper.current && allowDismiss === true) {
      onClose();
    }
  };

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    onClose();
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    onClose();
  };

  return (
    <Wrapper
      ref={refWrapper}
      onClick={handleDismiss}
      role='dialog'
      aria-labelledby={`${id}-Title`}
    >
      <Container>
        <Body type={type}>
          <Icon role='img' />
          <Title>{title}</Title>
          <Message>{text}</Message>
        </Body>

        <ButtonGroup alignRight>
          {showCancelButton === true && (
            <Button danger onClick={handleCancel}>
              {cancelButtonText || 'Cancel'}
            </Button>
          )}
          <Button success onClick={handleConfirm}>
            {confirmButtonText || 'Confirm'}
          </Button>
        </ButtonGroup>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(ModalBackdrop)`
  z-index: 90;
`;

const Container = styled.div`
  width: 500px;
  max-width: 90%;
  background: var(--color-background2);
  box-shadow: var(--box-shadow);
  padding: var(--spacing);
  border-radius: var(--border-radius);
  color: var(--font-on-background);
  border: var(--border-width) solid var(--border-color);
  text-align: center;
`;

const Body = styled.div`
  margin-bottom: var(--spacing);

  > svg {
    font-size: 120px;
    margin-bottom: var(--spacing);

    ${({ type }) => {
      switch (type) {
        case 'error':
          return 'color: var(--color-danger);';
        case 'warning':
          return 'color: var(--color-warning);';
        default:
          return 'color: var(--color-success);';
      }
    }}
  }
`;

const Title = styled.h2`
  font-size: var(--font-body1);
  font-weight: var(--font-bold);
  margin-bottom: var(--spacing-s);
`;

const Message = styled.p`
  font-size: var(--font-body1);
`;

export default StateModal;
