import styled from 'styled-components';

const Heading2 = styled.h2`
  font-size: var(--font-h2);
  font-weight: var(--font-bold);
  word-break: break-word;
  white-space: pre-wrap;

  ${({ center }) => center && 'text-align: center;'}
  ${({ whiteColor }) => whiteColor && 'color: #ffffff;'}
  ${({ marginBottom }) => marginBottom && `margin-bottom: var(--spacing)`}
`;

export default Heading2;
