import { useState, useEffect } from 'react';

const useCalculateDataRange = (page, limit, total) => {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  useEffect(() => {
    let start = 0;
    let end = page * limit;

    if (total !== 0) {
      start = (page - 1) * limit + 1;
    }

    if (end > total) {
      end = total;
    }

    setStart(start);
    setEnd(end);
  }, [page, limit, total]);

  return { start, end };
};

export default useCalculateDataRange;
