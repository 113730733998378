import styled from 'styled-components';

const Heading1 = styled.h1`
  font-size: var(--font-h1);
  word-break: break-word;
  white-space: pre-wrap;

  ${({ center }) => center && 'text-align: center;'}
`;

export default Heading1;
