import { forwardRef } from 'react';
import styled from 'styled-components';
import Tagify from '@yaireo/tagify/dist/react.tagify';
import '@yaireo/tagify/dist/tagify.css';

const Tags = forwardRef(
  ({ onChange, settings, tagifyProps, ...props }, ref) => {
    return (
      <Wrapper>
        <Tagify
          tagifyRef={ref}
          settings={settings}
          {...tagifyProps}
          onChange={onChange}
          {...props}
        />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  .tagify[readonly]:not(.tagify--mix):not(.tagify--select) {
    opacity: 0.5;
  }

  .tagify {
    --tags-disabled-bg: var(--font-on-mute);
    --tags-border-color: var(--border-color);
    --tags-hover-border-color: var(--border-color);
    --tags-focus-border-color: var(--color-primary);
    --tag-invalid-color: var(--color-danger);
    --tag-remove-btn-bg--hover: var(--color-danger);
    --input-color: var(--font-on-background);
    --readonly-striped: 0;

    border-radius: var(--border-radius-l);
    width: 100%;

    .tagify__tag {
      > div::before {
        border-radius: var(--border-radius-l);
      }
    }
  }
`;

export default Tags;
