import { createContext, useState, useCallback, useEffect } from 'react';
import appConfig from '../../config/app.config';
import { getCurrentUserInformation } from '../../module/api';
import { showErrorMessage } from '../../module/message';

const AuthenticateContext = createContext();

const TOKEN_NAME = 'admin-token';
const AuthenticateContextProvider = ({ children }) => {
  const [isGetting, setIsGetting] = useState(true);
  const [authToken, setAuthToken] = useState(null);
  const [tokenExpiry, setTokenExpiry] = useState(null);
  const [userInformation, setUserInformation] = useState(null);

  const getToken = () => {
    let token = {};
    try {
      token = JSON.parse(localStorage.getItem(TOKEN_NAME));
      if (token === null || typeof token !== 'object') {
        token = {};
      }
    } catch (error) {
      console.log(error);
    }

    return token;
  };

  const setToken = (token) => {
    localStorage.setItem(TOKEN_NAME, token);
  };

  const removeToken = () => {
    localStorage.removeItem(TOKEN_NAME);
  };

  const updateToken = (token) => {
    if (token) {
      const expiry = new Date(
        Date.now() + 1000 * 60 * appConfig.sessionTimeout,
      );

      setAuthToken(token);
      setTokenExpiry(expiry);
      setToken(
        JSON.stringify({
          token: token,
          expiry: expiry,
        }),
      );
    }
  };

  const getAuthenticateInformation = () => {
    getCurrentUserInformation()
      .then((response) => {
        if (response) {
          const { data } = response.data;
          setUserInformation(data);
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  };

  const initialAuthenticate = useCallback(() => {
    const { token, expiry } = getToken();
    if (token && expiry) {
      setAuthToken(token);
      setTokenExpiry(new Date(expiry));
    }
    setIsGetting(false);
  }, []);

  const removeAuthenticate = useCallback(() => {
    removeToken();
    setAuthToken(null);
    setUserInformation(null);
  }, []);

  useEffect(() => {
    initialAuthenticate();
  }, [initialAuthenticate]);

  useEffect(() => {
    if (authToken && !userInformation) {
      getAuthenticateInformation();
    }
  }, [authToken, userInformation]);

  useEffect(() => {
    if (tokenExpiry) {
      const leftTime = tokenExpiry - Date.now();
      const timeoutID = setTimeout(() => {
        removeAuthenticate();
      }, leftTime);

      return () => {
        clearTimeout(timeoutID);
      };
    }
  }, [tokenExpiry, removeAuthenticate]);

  return (
    <AuthenticateContext.Provider
      value={{
        isGetting,
        authToken,
        updateToken,
        removeAuthenticate,
        userInformation,
      }}
    >
      {children}
    </AuthenticateContext.Provider>
  );
};

export default AuthenticateContext;
export { AuthenticateContextProvider };
