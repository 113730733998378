import { useContext, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { MdMoreHoriz, MdDelete } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { USER_STATUS } from '../../provider/constant';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { showSuccessMessage } from '../../module/message';
import { userList, deleteUser } from '../../module/user';
import {
  Section,
  SectionHeader,
  SectionToolbar,
  SectionToolbarItem,
  SectionBody,
} from '../../components/section/index';
import { Heading1 } from '../../components/heading/index';
import { Button, IconButton } from '../../components/button/index';
import Dropdown, {
  DropdownItem,
  DropdownLinkOption,
  DropdownButtonOption,
} from '../../components/dropdown/index';
import { Table } from '../../components/table/index';
import { useLimitChange, usePageChange } from '../../components/table/hooks';

const UserList = () => {
  const { t } = useTranslation();
  const { userInformation } = useContext(AuthenticateContext);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(1);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
  });
  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);

  const handleGetUserList = useCallback(() => {
    const params = {
      page: listParams.page,
      limit: listParams.limit,
    };
    handleApiResponse(userList(params), (response) => {
      const { list, total } = response.data.data;
      setData(list);
      setTotal(total);
    });
  }, [listParams]);

  const handleRemove = useCallback(
    (id) => {
      handleApiResponse(deleteUser(id), () => {
        showSuccessMessage({
          message: t('success:RemoveUser'),
        });
        handleGetUserList();
      });
    },
    [t, handleGetUserList],
  );

  const generateActionDropdown = useCallback(
    ({ id }) => {
      return (
        <Dropdown key={id}>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem>
              <DropdownLinkOption to={`/user/${id}`}>
                <FiFileText />
                {t('button:Details')}
              </DropdownLinkOption>
            </DropdownItem>
            {userInformation.permissions.includes('administrator.delete') && (
              <DropdownItem onClick={() => handleRemove(id)}>
                <DropdownButtonOption>
                  <MdDelete />
                  {t('button:Remove')}
                </DropdownButtonOption>
              </DropdownItem>
            )}
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [t, userInformation, handleRemove],
  );

  useEffect(() => {
    handleGetUserList();
  }, [handleGetUserList]);

  return (
    <>
      <Section noPadding>
        <SectionHeader sticky backgroundFill>
          <SectionToolbar>
            <SectionToolbarItem>
              <Heading1>{t('UserList')}</Heading1>
            </SectionToolbarItem>
            {userInformation.permissions.includes('administrator.create') && (
              <SectionToolbarItem>
                <Link to={'/user/invite'}>
                  <Button>
                    <FaPlus />
                    {t('button:InviteUser')}
                  </Button>
                </Link>
              </SectionToolbarItem>
            )}
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            columns={[
              {
                title: t('UserName'),
                fieldName: 'username',
                sortable: false,
              },

              {
                title: t('Email'),
                fieldName: 'email',
                sortable: false,
              },
              {
                title: t('Telephone'),
                fieldName: 'telephone',
                sortable: false,
              },
              {
                title: t('Status'),
                fieldName: 'status',
                sortable: false,
                render(data) {
                  return t(USER_STATUS[data].text);
                },
              },

              {
                title: t('Action'),
                custom: true,
                width: '10%',
                align: 'center',
                render(data) {
                  return generateActionDropdown(data);
                },
              },
            ]}
            data={data}
            currentPage={listParams.page}
            limit={listParams.limit}
            onLimitChange={onLimitChange}
            total={total}
            sortColumn={listParams.sort_column}
            sortType={listParams.sort_type}
            onPageChange={onPageChange}
          />
        </SectionBody>
      </Section>
    </>
  );
};

export default UserList;
