import styled from 'styled-components';
import { Link } from 'react-router-dom';

const DropdownLinkOption = styled(Link)`
  display: flex;
  padding: 12px var(--spacing-s);
  color: var(--font-on-background);
  background: var(--color-background2);

  &:hover {
    background: var(--color-hover);
  }

  > svg {
    margin-right: var(--spacing-s);
  }
`;

export default DropdownLinkOption;
