import styled from 'styled-components';

const OptionGroup = styled.div`
  [role='presentation'] {
    font-weight: bold;

    &:hover {
      background: var(--color-background1);
    }
  }

  [role='option'] {
    padding-left: calc(var(--spacing-s) * 2);
  }
`;
OptionGroup.displayName = 'OptionGroup';

export default OptionGroup;
