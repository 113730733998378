import { useContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { FiFileText } from 'react-icons/fi';
import { MdDelete, MdMoreHoriz } from 'react-icons/md';
import { Link } from 'react-router-dom';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { Button, IconButton } from '../../components/button';
import Dropdown, {
  DropdownItem,
  DropdownButtonOption,
  DropdownLinkOption,
} from '../../components/dropdown';
import { Heading1 } from '../../components/heading';
import { StateModal } from '../../components/modal';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionToolbar,
  SectionToolbarItem,
} from '../../components/section';
import { Table } from '../../components/table';
import { useLimitChange, usePageChange } from '../../components/table/hooks';
import { showSuccessMessage } from '../../module/message';
import {
  deleteTerminalGroup,
  getTerminalGroupList,
} from '../../module/terminal';
import handleApiResponse from '../../utils/api/handleApiResponse';

const TerminalGroupList = () => {
  const { t } = useTranslation();
  const { userInformation } = useContext(AuthenticateContext);
  const [listData, setListData] = useState([]);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
  });
  const [total, setTotal] = useState(1);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);

  const handleDeleteTerminalGroup = (id) => {
    handleApiResponse(deleteTerminalGroup({ id }), () => {
      showSuccessMessage({ message: t('success:RemoveTerminalGroup') });
      handleGetTerminalGroupList();
    });
  };

  const handleGetTerminalGroupList = useCallback(() => {
    const requestData = {
      page: listParams.page,
      limit: listParams.limit,
    };

    handleApiResponse(getTerminalGroupList(requestData), (response) => {
      const { total, list } = response.data.data;
      setTotal(total);
      setListData(list);
    });
  }, [listParams]);

  const handleRemove = (id) => {
    return StateModal({
      type: 'warning',
      title: t('AreYouSure'),
      text: t('DoYouWantToRemoveThisTerminalGroup'),
      showCancelButton: true,
      confirmButtonText: t('button:Confirm'),
      cancelButtonText: t('button:Cancel'),
      allowDismiss: true,
      onConfirm() {
        handleDeleteTerminalGroup(id);
        handleGetTerminalGroupList();
      },
    });
  };

  const generateActionDropdown = ({ id }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <IconButton>
            <MdMoreHoriz />
          </IconButton>
        </Dropdown.Toggle>
        <Dropdown.Content>
          <DropdownItem>
            <DropdownLinkOption to={`/terminal-group/${id}`}>
              <FiFileText />
              {t('button:Details')}
            </DropdownLinkOption>
          </DropdownItem>
          {userInformation.permissions.includes('terminal_group.delete') && (
            <DropdownItem onClick={() => handleRemove(id)}>
              <DropdownButtonOption>
                <MdDelete />
                {t('button:Remove')}
              </DropdownButtonOption>
            </DropdownItem>
          )}
        </Dropdown.Content>
      </Dropdown>
    );
  };

  useEffect(() => {
    handleGetTerminalGroupList();
  }, [handleGetTerminalGroupList]);

  return (
    <>
      <Section noPadding>
        <SectionHeader sticky backgroundFill>
          <SectionToolbar>
            <SectionToolbarItem>
              <Heading1>{t('TerminalGroupList')}</Heading1>
            </SectionToolbarItem>
            {userInformation.permissions.includes('terminal_group.create') && (
              <SectionToolbarItem>
                <Link to='/terminal-group/create'>
                  <Button>
                    <FaPlus />
                    {t('button:Create')}
                  </Button>
                </Link>
              </SectionToolbarItem>
            )}
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            columns={[
              {
                title: t('Name'),
                fieldName: 'name',
              },
              {
                title: t('TerminalCount'),
                fieldName: 'terminalCount',
              },
              {
                title: t('Description'),
                fieldName: 'remarks',
              },
              {
                title: t('Action'),
                custom: true,
                width: '10%',
                align: 'center',
                render(data) {
                  return generateActionDropdown(data);
                },
              },
            ]}
            data={listData}
            currentPage={listParams.page}
            limit={listParams.limit}
            total={total}
            onLimitChange={onLimitChange}
            onPageChange={onPageChange}
          />
        </SectionBody>
      </Section>
    </>
  );
};

export default TerminalGroupList;
