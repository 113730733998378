import TableFooter from './TableFooter';
import styled from 'styled-components';

const GridTableFooter = styled(TableFooter)`
  position: sticky;
  bottom: 0;
  height: 48px;
  padding: 0 var(--spacing-xs);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background: ${({ backgroundReverse }) => {
    return backgroundReverse
      ? 'var(--color-background1)'
      : 'var(--color-background2)';
  }};
`;

export default GridTableFooter;
