import styled from 'styled-components';

const Section = styled.div`
  background: var(--color-background1);
  border-radius: var(--border-radius);
  color: var(--color-white);
  padding: var(--spacing);
  margin-bottom: var(--spacing);

  ${({ backgroundReverse }) =>
    backgroundReverse && 'background: var(--color-background2);'}

  ${({ paddingS }) => paddingS && 'padding: var(--spacing-s);'}

  ${({ paddingXS }) => paddingXS && 'padding: var(--spacing-xs);'}

  ${({ noPadding }) => noPadding && 'padding: 0;'}

  ${({ noPaddingX }) => noPaddingX && 'padding-left: 0; padding-right: 0;'}

  ${({ noPaddingTop }) => noPaddingTop && 'padding-top: 0;'}

  ${({ noPaddingBottom }) => noPaddingBottom && 'padding-bottom: 0;'}

  ${({ marginBottomS }) => marginBottomS && 'margin-bottom: var(--spacing-s);'}

  ${({ noMarginBottom }) => noMarginBottom && 'margin-bottom: 0;'}

  ${({ fullHeight }) => fullHeight && 'height: calc(100% - var(--spacing));'}

  ${({ backgroundMarker }) =>
    backgroundMarker &&
    `
      background-color: #222143;
      
      background-repeat: no-repeat;
      background-position: left top, right bottom;
      background-size: 337px 312px, 166px 225px;
    `}

  ${({ backgroundMap }) =>
    backgroundMap &&
    `
      background-color: #222143;

      background-repeat: no-repeat;
      background-size: cover;
    `}

  ${({ border }) =>
    border && 'border: var(--border-width) solid var(--border-color);'}
  
  ${({ checkboxGroup }) =>
    checkboxGroup &&
    `
      background: transparent;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    `}
`;

export default Section;
