import { useState, useMemo } from 'react';
import L from 'leaflet';
import { TileLayer, Marker } from 'react-leaflet';
import CustomLeafletMapContainer from '../map/CustomLeafletMapContainer';
import MapInteractor from './MapInteractor';
import MapSearchBoxControl from './subComponent/MapSearchBoxControl';
import MapIcon from '../../assets/icons/map_icon4.png';

const ReverseGeolocation = ({
  children,
  defaultLatlng,
  changeLatlng,
  onChoose,
  onSearch,
  onError,
}) => {
  const [markerLatLng, setMarkerLatLng] = useState(defaultLatlng);

  const Icon = useMemo(() => {
    return L.icon({
      iconUrl: MapIcon,
      iconSize: [40, 50],
    });
  }, []);

  const handleChangeLatlng = (latlng) => {
    setMarkerLatLng(latlng);
    changeLatlng(latlng);
  };

  const confirmChooseLocation = async (callback) => {
    if (markerLatLng.lat && markerLatLng.lng) {
      try {
        const address = await onChoose(markerLatLng);
        if (address) {
          callback({ address });
        } else {
          callback({ error: 'ReverseGeo' });
        }
      } catch (error) {
        callback(error);
      }
    } else {
      callback({ error: 'PleaseChooseLocation' });
    }
  };

  const Map = (
    <CustomLeafletMapContainer
      height={500}
      center={[1, 1]}
      zoom={10}
      scrollWheelZoom={true}
      maxBounds={[
        [-90, -180],
        [90, 180],
      ]}
    >
      <TileLayer
        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        minZoom={3}
        maxZoom={20}
        noWrap={true}
        bounds={[
          [-90, -180],
          [90, 180],
        ]}
      />

      {markerLatLng.lat && markerLatLng.lng && (
        <Marker position={[markerLatLng.lat, markerLatLng.lng]} icon={Icon} />
      )}

      <MapInteractor changeLatlng={handleChangeLatlng} />

      <MapSearchBoxControl
        changeLatlng={handleChangeLatlng}
        onSearch={onSearch}
        onError={onError}
      />
    </CustomLeafletMapContainer>
  );

  return <>{children(Map, confirmChooseLocation)}</>;
};

export default ReverseGeolocation;
