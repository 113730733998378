import styled, { css } from 'styled-components';
import media from '../styles/mixin/media';

const Container = styled.div`
  transition: 0.3s;
  width: calc(100% - 72px);
  margin-left: 72px;
  padding: calc(64px + 1.5rem) 1.5rem 1.5rem;

  ${({ $display }) =>
    $display === true &&
    css`
      width: calc(100% - 316px);
      margin-left: 316px;
    `}

  ${media.tablet`
    width: 100%;
    margin-left: 0;
  `}
`;

export default Container;
