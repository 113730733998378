import { useState, useEffect, useCallback } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import moment from 'moment';
import styled from 'styled-components';
import uniqueId from 'lodash.uniqueid';
import { useIncrease, useDecrease, useChangeNumber } from './hooks';
import pad from './utils/pad';
import { KEY_CODE } from '../../utils/aria';

const LENGTH = 2;
const PAD_STRING = '0';

const TimePicker = ({
  labelName = 'Time',
  value,
  onChange,
  showSecond = true,
}) => {
  const [hourId] = useState(uniqueId('hour-'));
  const [time, setTime] = useState(() => {
    let time = moment(value, 'HH:mm:ss');

    // use current time when time value is invalid
    if (!value || !time.isValid()) {
      time = moment();
    }
    return {
      hour: pad(time.hour(), LENGTH, PAD_STRING),
      minute: pad(time.minute(), LENGTH, PAD_STRING),
      second: pad(time.second(), LENGTH, PAD_STRING),
    };
  });

  useEffect(() => {
    if (
      typeof onChange === 'function' &&
      time.hour &&
      time.minute &&
      time.second
    ) {
      onChange(`${time.hour}:${time.minute}:${time.second}`);
    }
  }, [onChange, time.hour, time.minute, time.second]);

  return (
    <Container>
      <Label htmlFor={hourId}>{labelName}</Label>
      <Hour id={hourId} value={time.hour} setTime={setTime} />
      <Minute value={time.minute} setTime={setTime} />
      {showSecond === true && <Second value={time.second} setTime={setTime} />}
    </Container>
  );
};

const Hour = ({ id, value, setTime }) => {
  const NAME = 'hour';
  const MIN = 0;
  const MAX = 23;
  const increase = useIncrease(MIN, MAX, setTime, {
    name: NAME,
    length: LENGTH,
    padString: PAD_STRING,
  });
  const decrease = useDecrease(MIN, MAX, setTime, {
    name: NAME,
    length: LENGTH,
    padString: PAD_STRING,
  });
  const changeNumber = useChangeNumber(MIN, MAX, setTime, NAME);
  const handleKeyUp = useKeyUp(increase, decrease, MIN, MAX, setTime, NAME);

  return (
    <Section>
      <Input
        type='text'
        id={id}
        maxLength='2'
        value={value}
        onChange={(event) => {
          changeNumber(event.target.value);
        }}
        onKeyUp={handleKeyUp}
        onBlur={(event) => {
          setTime((previous) => {
            const newState = { ...previous };
            newState[NAME] = pad(event.target.value, LENGTH, PAD_STRING);

            return newState;
          });
        }}
      />
      <Stepper>
        <StepperButton onClick={increase}>
          <FaAngleUp />
        </StepperButton>
        <StepperButton onClick={decrease}>
          <FaAngleDown />
        </StepperButton>
      </Stepper>
    </Section>
  );
};

const Minute = ({ value, setTime }) => {
  const NAME = 'minute';
  const MIN = 0;
  const MAX = 59;
  const [minuteId] = useState(uniqueId(`${NAME}-`));
  const increase = useIncrease(MIN, MAX, setTime, {
    name: NAME,
    length: LENGTH,
    padString: PAD_STRING,
  });
  const decrease = useDecrease(MIN, MAX, setTime, {
    name: NAME,
    length: LENGTH,
    padString: PAD_STRING,
  });
  const changeNumber = useChangeNumber(MIN, MAX, setTime, NAME);
  const handleKeyUp = useKeyUp(increase, decrease, MIN, MAX, setTime, NAME);

  return (
    <>
      <Delimiter>:</Delimiter>
      <Section>
        <Input
          type='text'
          id={minuteId}
          maxLength='2'
          value={value}
          onChange={(event) => {
            changeNumber(event.target.value);
          }}
          onKeyUp={handleKeyUp}
          onBlur={(event) => {
            setTime((previous) => {
              const newState = { ...previous };
              newState[NAME] = pad(event.target.value, LENGTH, PAD_STRING);

              return newState;
            });
          }}
        />
        <Stepper>
          <StepperButton onClick={increase}>
            <FaAngleUp />
          </StepperButton>
          <StepperButton onClick={decrease}>
            <FaAngleDown />
          </StepperButton>
        </Stepper>
      </Section>
    </>
  );
};

const Second = ({ value, setTime }) => {
  const NAME = 'second';
  const MIN = 0;
  const MAX = 59;
  const [secondId] = useState(uniqueId(`${NAME}-`));
  const increase = useIncrease(MIN, MAX, setTime, {
    name: NAME,
    length: LENGTH,
    padString: PAD_STRING,
  });
  const decrease = useDecrease(MIN, MAX, setTime, {
    name: NAME,
    length: LENGTH,
    padString: PAD_STRING,
  });
  const changeNumber = useChangeNumber(MIN, MAX, setTime, NAME);
  const handleKeyUp = useKeyUp(increase, decrease, MIN, MAX, setTime, NAME);

  return (
    <>
      <Delimiter>:</Delimiter>
      <Section>
        <Input
          type='text'
          id={secondId}
          maxLength='2'
          value={value}
          onChange={(event) => {
            changeNumber(event.target.value);
          }}
          onKeyUp={handleKeyUp}
          onBlur={(event) => {
            setTime((previous) => {
              const newState = { ...previous };
              newState[NAME] = pad(event.target.value, LENGTH, PAD_STRING);

              return newState;
            });
          }}
        />
        <Stepper>
          <StepperButton onClick={increase}>
            <FaAngleUp />
          </StepperButton>
          <StepperButton onClick={decrease}>
            <FaAngleDown />
          </StepperButton>
        </Stepper>
      </Section>
    </>
  );
};

const useKeyUp = (increase, decrease, min, max, setState, name) => {
  return useCallback(
    (event) => {
      const key = event.keyCode;

      switch (key) {
        case KEY_CODE.UP:
          increase();
          break;

        case KEY_CODE.DOWN:
          decrease();
          break;

        case KEY_CODE.HOME:
          setState((previous) => {
            const newState = { ...previous };
            newState[name] = pad(min, LENGTH, PAD_STRING);

            return newState;
          });
          break;

        case KEY_CODE.END:
          setState((previous) => {
            const newState = { ...previous };
            newState[name] = pad(max, LENGTH, PAD_STRING);

            return newState;
          });
          break;

        default:
          break;
      }
    },
    [increase, decrease, min, max, setState, name],
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.label`
  display: inline-block;
  color: var(--font-on-background);
  font-size: var(--font-body1);
  margin-right: 12px;
`;

const Section = styled.div`
  display: inline-flex;
  height: 36px;
  width: 56px;
  padding: calc(var(--spacing-xs) / 2) var(--spacing-xs);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius-s);
  background: transparent;
  color: var(--font-on-background);
`;

const Input = styled.input`
  width: calc(100% - 4px - 12px);
  border: none;
  background: transparent;
  color: inherit;

  &:focus {
    outline: none;
  }
`;

const StepperButton = styled.div.attrs(() => ({
  role: 'button',
}))`
  width: 12px;
  height: 12px;

  > svg {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }

  &:first-child {
    margin-bottom: 2px;
  }

  &:hover {
    color: var(--color-primary);
  }
`;

const Stepper = styled.div`
  margin-left: calc(var(--spacing-xs) / 2);
`;

const Delimiter = styled.span`
  display: inline-block;
  color: var(--font-on-background);
  font-size: var(--font-body1);
  width: 12px;
  text-align: center;
`;

export default TimePicker;
