import styled from 'styled-components';
import media from '../styles/mixin/media';

const Column = styled.div`
  padding: 0 calc(var(--spacing) / 2);
  grid-column-end: span ${({ desktop }) => desktop};

  ${({ laptop }) => {
    return (
      laptop &&
      media.laptop`
        grid-column-end: span ${laptop};
      `
    );
  }}

  ${({ tablet }) => {
    return (
      tablet &&
      media.tablet`
        grid-column-end: span ${tablet};
      `
    );
  }}

  ${({ mobile }) => {
    return (
      mobile &&
      media.mobile`
        grid-column-end: span ${mobile};
      `
    );
  }}
`;

export default Column;
