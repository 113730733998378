import { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { FiFileText } from 'react-icons/fi';
import { MdMoreHoriz } from 'react-icons/md';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { showErrorMessage, showSuccessMessage } from '../../module/message';
import { getBottlerSimpleList } from '../../module/bottler';
import {
  getSalesCenterDetails,
  modifySalesCenter,
} from '../../module/salesCenter';
import { getTerminalList } from '../../module/terminal';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { Section, SectionBody, SectionHeader } from '../../components/section';
import { Heading1, Heading2 } from '../../components/heading';
import { Grid, Column } from '../../components/grid';
import { FormItem, Input, Label, Textarea } from '../../components/form';
import { Select } from '../../components/Select';
import { Table } from '../../components/table';
import { useLimitChange, usePageChange } from '../../components/table/hooks';
import Dropdown, {
  DropdownItem,
  DropdownLinkOption,
} from '../../components/dropdown';
import { Button, IconButton, ButtonGroup } from '../../components/button';

const SalesCenterDetail = () => {
  const { t: trans } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { userInformation } = useContext(AuthenticateContext);
  const [bottlerList, setBottlerList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [bottlerSimpleList, setBottlerSimpleList] = useState([]);
  const [isOnModify, setIsOnModify] = useState(false);
  const [data, setData] = useState({
    name: '',
    bottlerId: '',
    description: '',
  });
  const [savedData, setSavedData] = useState(data);

  const handleGetBottlerList = useCallback((currentPage) => {
    const parameters = {
      page: currentPage,
      limit: 100,
    };
    handleApiResponse(getBottlerSimpleList(parameters), (response) => {
      const { list, total } = response.data.data;

      setBottlerList((previous) => {
        const newBottlerList = [...previous.data, ...list];

        return {
          data: newBottlerList,
          currentPage: currentPage + 1,
          lastPage: Math.ceil(total / 100),
        };
      });
    });
  }, []);

  const handleGetSalesCenterDetails = useCallback(() => {
    getSalesCenterDetails(id)
      .then((response) => {
        if (response) {
          const { data } = response.data;
          setData({
            name: data.name,
            bottlerId: data.bottlerId,
            description: data.description,
          });
          setSavedData({
            name: data.name,
            bottlerId: data.bottlerId,
            description: data.description,
          });
        }
      })
      .catch((error) => {
        showErrorMessage({ title: error.name, message: error.message });
      });
  }, [id]);

  const handleChangeValue = (key, value) => {
    setData((previous) => {
      return { ...previous, [key]: value };
    });
  };

  const handleCancel = () => {
    setData(savedData);
    setIsOnModify(false);
  };

  const handleModify = () => {
    if (!data.name.trim()) {
      showErrorMessage({ message: trans('error:PleaseFillUpName') });
      return;
    }

    if (!data.bottlerId) {
      showErrorMessage({ message: trans('error:PleaseSelectABottler') });
      return;
    }

    const requestData = {
      name: data.name.trim(),
      bottlerId: data.bottlerId,
      description: data.description.trim(),
    };

    handleApiResponse(modifySalesCenter(id, requestData), () => {
      setIsOnModify(false);
      handleGetSalesCenterDetails();
      showSuccessMessage({ message: trans('success:ModifySalesCenter') });
    });
  };

  useEffect(() => {
    handleGetSalesCenterDetails();
  }, [handleGetSalesCenterDetails]);

  useEffect(() => {
    if (bottlerList.currentPage <= bottlerList.lastPage) {
      handleGetBottlerList(bottlerList.currentPage);
    } else {
      setBottlerSimpleList(
        bottlerList.data.map((bottler) => {
          return { id: bottler.id, text: bottler.name };
        }),
      );
    }
  }, [
    handleGetBottlerList,
    bottlerList.data,
    bottlerList.currentPage,
    bottlerList.lastPage,
  ]);

  return (
    <>
      <Section noPadding>
        <SectionHeader>
          <Heading1>{trans('SalesCenter')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionBody noPadding>
              <Grid columns={12}>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='name' required={isOnModify}>
                      {trans('Name')}
                    </Label>
                    <Input
                      id='name'
                      type='text'
                      value={data.name}
                      onChange={(event) =>
                        handleChangeValue('name', event.target.value)
                      }
                      maxLength='100'
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='bottler' required={isOnModify}>
                      {trans('Bottler')}
                    </Label>
                    <Select
                      fullWidth
                      options={bottlerSimpleList}
                      selected={data.bottlerId}
                      onSelect={(id) => {
                        handleChangeValue('bottlerId', id);
                      }}
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='description'>{trans('Description')}</Label>
                    <Textarea
                      id='description'
                      rows='4'
                      value={data.description}
                      onChange={(event) =>
                        handleChangeValue('description', event.target.value)
                      }
                      maxLength='200'
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
              </Grid>
            </SectionBody>
          </Section>

          <AssociatedTerminalList id={id} />
        </SectionBody>
        <ButtonGroup alignRight>
          {isOnModify ? (
            <>
              <Button danger onClick={handleCancel}>
                {trans('button:Cancel')}
              </Button>
              <Button success onClick={handleModify}>
                {trans('button:Save')}
              </Button>
            </>
          ) : (
            <>
              <Button danger onClick={() => navigate(-1)}>
                {trans('button:Back')}
              </Button>
              {userInformation.permissions.includes('sales_center.delete') && (
                <Button
                  warning
                  onClick={() => {
                    setIsOnModify(true);
                  }}
                >
                  {trans('button:Modify')}
                </Button>
              )}
            </>
          )}
        </ButtonGroup>
      </Section>
    </>
  );
};

const AssociatedTerminalList = ({ id }) => {
  const { t: trans } = useTranslation();
  const [listData, setListData] = useState([]);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
  });
  const [total, setTotal] = useState(1);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);

  const handleGetTerminalList = useCallback(() => {
    const requestData = {
      page: listParams.page,
      limit: listParams.limit,
      saleCenterId: id,
    };

    handleApiResponse(getTerminalList(requestData), (response) => {
      const { total, list } = response.data.data;
      setTotal(total);
      setListData(list);
    });
  }, [listParams, id]);

  const generateActionDropdown = ({ id }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <IconButton>
            <MdMoreHoriz />
          </IconButton>
        </Dropdown.Toggle>

        <Dropdown.Content>
          <DropdownItem>
            <DropdownLinkOption to={`/terminal/${id}`}>
              <FiFileText />
              {trans('button:Details')}
            </DropdownLinkOption>
          </DropdownItem>
        </Dropdown.Content>
      </Dropdown>
    );
  };

  useEffect(() => {
    handleGetTerminalList();
  }, [handleGetTerminalList]);

  return (
    <Section backgroundReverse>
      <SectionHeader>
        <Heading2 whiteColor>{trans('TerminalList')}</Heading2>
      </SectionHeader>
      <SectionBody noPadding>
        <Table
          backgroundReverse
          columns={[
            {
              title: trans('HardwareSerialNumber'),
              fieldName: 'sn',
            },
            {
              title: trans('OutletNumber'),
              fieldName: 'outletNumber',
            },
            {
              title: trans('OutletName'),
              fieldName: 'outletName',
            },
            {
              title: trans('NameExtension'),
              fieldName: 'nameExtension',
            },
            {
              title: trans('AssetNumber'),
              fieldName: 'assetNumber',
            },
            {
              title: trans('Action'),
              custom: true,
              width: '10%',
              align: 'center',
              render(data) {
                return generateActionDropdown(data);
              },
            },
          ]}
          data={listData}
          currentPage={listParams.page}
          limit={listParams.limit}
          total={total}
          onLimitChange={onLimitChange}
          onPageChange={onPageChange}
        />
      </SectionBody>
    </Section>
  );
};

export default SalesCenterDetail;
