import { callApi } from '../services/api';
import MD5 from 'crypto-js/md5';

const login = ({ email, password }) => {
  const params = {
    email,
    password: MD5(password).toString(),
  };

  return callApi.post('/login', params);
};

const getCurrentUserInformation = () => {
  return callApi.get('/current-user', {
    global: false,
  });
};

export { login, getCurrentUserInformation };
