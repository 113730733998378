import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import appConfig from '../../config/app.config';
import DocumentTitleDescription from '../../components/helmet/DocumentTitleDescription';

const NotFoundPage = () => {
  const message = '404 Not Found 😨';
  const [messageArr, setMessageArr] = useState([]);

  useEffect(() => {
    let timeoutIDs = [];
    const timeoutID = setTimeout(() => {
      timeoutIDs = [...message].map((item, index) => {
        return setTimeout(() => {
          setMessageArr((messageArr) => [...messageArr, item]);
        }, index * 200);
      });
    }, 1000);

    return () => {
      clearTimeout(timeoutID);
      timeoutIDs.forEach((id) => {
        clearTimeout(id);
      });
    };
  }, []);

  return (
    <>
      <DocumentTitleDescription
        title={`${appConfig.name} - ${'404 Not Found'}`}
      />
      <Wrapper>
        <p>
          {messageArr}
          <StyledSpan>|</StyledSpan>
          <Link to='/'>
            <Button>
              Go To Home <span>&thinsp;&thinsp;&gt;&gt;&gt;</span>{' '}
            </Button>
          </Link>
        </p>
      </Wrapper>
    </>
  );
};

const float = keyframes`
  from {
    transform: rotate(0deg);
    top: -600px;
  }

  to {
    transform: rotate(360deg);
    top: -750px;
    left: -180px;
  }
`;

const float02 = keyframes`
  from {
    transform: rotate(0deg);
    top: 150px;
  }

  to {
    transform: rotate(360deg);
    top: 250px;
    right: -120px;
  }
`;

const cursor = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  background: var(--color-background1);
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 200;

  > p {
    color: var(--color-primary);
    transform: translateY(-50%);
    position: relative;
    top: 50%;
    font-size: 50px;
    text-align: center;
    margin: 0;
    font-weight: bold;

    &::before {
      content: '';
      border-radius: 47% 53% 73% 27% / 52% 54% 46% 48%;
      width: 748px;
      height: 747px;
      background-image: linear-gradient(-90deg, #222143 0%, #8f8caf 100%);
      top: -450px;
      left: -200px;
      position: absolute;
      animation: ${float} 20s linear infinite;
      animation-direction: alternate;
      opacity: 0.5;
      z-index: -1;
    }

    &::after {
      content: '';
      border-radius: 48% 52% 46% 54% / 42% 60% 40% 58%;
      width: 768px;
      height: 767px;
      background-image: linear-gradient(-90deg, #222143 0%, #8f8caf 100%);
      top: 150px;
      right: -70px;
      position: absolute;
      animation: ${float02} 20s linear infinite;
      animation-direction: alternate;
      opacity: 0.5;
      z-index: -1;
    }
  }
`;

const StyledSpan = styled.span`
  animation: ${cursor} 0.8s step-end infinite;
`;

const Button = styled.button`
  border: 2px solid var(--color-primary);
  background: transparent;
  color: var(--color-primary);
  border-radius: 30px;
  display: block;
  margin: 25px auto;
  font-size: 16px;
  padding: 5px 15px;
  width: 125px;
  white-space: nowrap;
  display: flex;
  transition: 0.3s;
  font-weight: bold;
  cursor: inherit;

  > span {
    opacity: 0;
    transition: 0.3s;
  }

  &:hover span {
    opacity: 1;
  }

  &:hover {
    width: 155px;
  }
`;

export default NotFoundPage;
