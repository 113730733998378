import { useEffect, useCallback } from 'react';
import axios from 'axios';
import Mock from './mock';

// Create axios instance for set default config
let numberOfAjaxCallPending = 0;
const callApi = axios.create({
  baseURL: '/api',
});

if (process.env.REACT_APP_MOCK === 'true') {
  Mock(callApi);
}

const AxiosInterceptor = ({ onBeforeSend, onDone, onAllDone }) => {
  const countAjaxCall = useCallback(() => {
    if (numberOfAjaxCallPending > 0) {
      numberOfAjaxCallPending -= 1;
    }

    if (numberOfAjaxCallPending === 0 && typeof onAllDone === 'function') {
      onAllDone();
    }
  }, [onAllDone]);

  useEffect(() => {
    const requestInterceptor = callApi.interceptors.request.use(
      (config) => {
        if (config.global !== false) {
          numberOfAjaxCallPending += 1;
        }

        if (typeof onBeforeSend === 'function') {
          return onBeforeSend(config);
        }

        return config;
      },
      (error) => {
        countAjaxCall();
        return Promise.reject(error);
      },
    );

    const responseInterceptor = callApi.interceptors.response.use(
      (response) => {
        if (!response) return response;

        countAjaxCall();

        if (typeof onDone === 'function') {
          return onDone(response);
        }

        return response;
      },
      (error) => {
        countAjaxCall();
        return Promise.reject(error);
      },
    );

    return () => {
      callApi.interceptors.request.eject(requestInterceptor);
      callApi.interceptors.response.eject(responseInterceptor);
    };
  }, [onBeforeSend, onDone, countAjaxCall]);

  return null;
};

export default AxiosInterceptor;
export { callApi };
