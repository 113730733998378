import { showErrorMessage } from '../../module/message';

const handleApiResponse = (promise, onSuccess, onFailure, callback) => {
  promise
    .then((response) => {
      if (response && typeof onSuccess === 'function') {
        onSuccess(response);
        if (callback) callback();
      }

      if (!response && typeof onFailure === 'function') {
        onFailure();
      }
    })
    .catch((error) => {
      if (typeof onFailure === 'function') {
        onFailure(error);
      }

      showErrorMessage({
        title: error.name,
        message: error.message,
      });
    });
};

export default handleApiResponse;
