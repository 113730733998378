import { useContext, cloneElement } from 'react';
import DropdownContext from './subComponent/DropdownContext';

const DropdownItem = ({ children, onClick }) => {
  const { handleSelect } = useContext(DropdownContext);

  const handleClick = (event) => {
    if (onClick) onClick(event);
    handleSelect();
  };

  return cloneElement(children, {
    onClick: handleClick,
  });
};

export default DropdownItem;
