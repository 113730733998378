import styled from 'styled-components';

const SectionToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > *:not(:first-child) {
    margin-left: var(--spacing-s);
  }
`;

export default SectionToolbar;
