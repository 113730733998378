import styled from 'styled-components';

const ButtonLink = styled.button`
  font-size: '1rem';
  line-height: 1.5;
  font-weight: 500;
  color: var(--color-primary);
  cursor: pointer;
  position: relative;
  display: inline-block;
  background: transparent;
  border: none;

  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    top: 1rem;
    left: 0;
    width: 0%;
    transition: width 0.3s ease;
  }

  &:hover {
    &:after {
      width: 100%;
      border-bottom: 1px solid var(--border-color);
      opacity: 0.75;
    }
  }
`;

export default ButtonLink;
