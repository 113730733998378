import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { replaceNonLatinSymbol } from '../../utils/regex/replace';
import { testEmail } from '../../utils/regex/check';
import { login } from '../../module/api';
import { showErrorMessage } from '../../module/message';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { LANGUAGES } from '../../provider/constant';
import Wrapper from '../../components/login/Wrapper';
import Container from '../../components/login/Container';
import Logo from '../../components/login/Logo';
import Form from '../../components/login/Form';
import Select from '../../components/login/Select';
import InputGroup from '../../components/login/InputGroup';
import Label from '../../components/login/Label';
import Input from '../../components/login/Input';
import FormButton from '../../components/login/FormButton';

const Login = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { updateToken } = useContext(AuthenticateContext);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (email, password) => {
    login({ email, password })
      .then((response) => {
        if (response) {
          const { token } = response.data.data;
          updateToken(token);

          navigate('/');
        }
      })
      .catch((error) => {
        showErrorMessage({ message: error.message });
      });
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();

    const trimedEmail = email.trim().toLowerCase();
    const trimedPassword = replaceNonLatinSymbol(password.trim());
    if (trimedEmail.length === 0) {
      showErrorMessage({ message: t('error:PleaseFillUpEmailAddress') });
      return;
    }

    if (!testEmail(trimedEmail)) {
      showErrorMessage({ message: t('error:PleaseFillUpValidEmailAddress') });
      return;
    }

    if (trimedPassword.length === 0) {
      showErrorMessage({ message: t('error:PleaseFillUpPassword') });
      return;
    }

    handleLogin(trimedEmail, trimedPassword);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [i18n, selectedLanguage]);

  return (
    <Wrapper>
      <Container>
        <Logo />

        <Form onSubmit={handleLoginSubmit}>
          <InputGroup>
            <Label>{t('Language')}</Label>
            <Select
              options={LANGUAGES}
              selected={selectedLanguage}
              onSelect={setSelectedLanguage}
            />
          </InputGroup>
          <InputGroup>
            <Label htmlFor='email'>{t('Email')}</Label>
            <Input
              id='email'
              name='email'
              type='text'
              value={email}
              placeholder={t('placeholder:email')}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete='username'
              autoFocus
            />
          </InputGroup>
          <InputGroup>
            <Label htmlFor='password'>{t('Password')}</Label>
            <Input
              id='password'
              name='password'
              type='password'
              value={password}
              placeholder={t('placeholder:password')}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete='current-password'
            />
          </InputGroup>

          <FormButton
            buttons={[
              {
                type: 'submit',
                text: t('button:Login'),
                tall: true,
                wide: true,
              },
            ]}
          />
        </Form>
      </Container>
    </Wrapper>
  );
};

export default Login;
