import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../provider/constant';
import MenuIcon from './MenuIcon';
import Logo from './Logo';
import UserInfo from './UserInfo';
import media from '../styles/mixin/media';
import { Select } from '../Select';

const Header = ({ toggleSidebar }) => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [i18n, selectedLanguage]);

  return (
    <Wrapper>
      <Brand>
        <MenuIcon toggleSidebar={toggleSidebar} />
        <Logo />
      </Brand>
      <Topbar>
        <Select
          large
          fillReverse
          allowSearch={false}
          options={LANGUAGES}
          selected={selectedLanguage}
          onSelect={setSelectedLanguage}
        />

        <UserInfo />
      </Topbar>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 40;
  width: 100%;
  height: 64px;
  padding: 0 calc(1rem + 0.25rem) 0 1rem;
  background: var(--color-background2);

  ${media.tablet`
    padding: 0 1rem 0 calc(1rem - 0.25rem);
  `}
`;

const Brand = styled.div`
  display: flex;

  > * {
    flex: none;
  }
`;

const Topbar = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: calc(1.5rem - 0.5rem);
  }
`;

export default Header;
