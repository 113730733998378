import { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaPlus, FaFilter } from 'react-icons/fa';
import { MdMoreHoriz, MdOutlineAttachEmail } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi';
import { BiTable } from 'react-icons/bi';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { getReportList } from '../../module/report';
import handleApiResponse from '../../utils/api/handleApiResponse';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionToolbar,
  SectionToolbarItem,
} from '../../components/section';
import { Heading1 } from '../../components/heading';
import Dropdown, {
  DropdownItem,
  DropdownLinkOption,
} from '../../components/dropdown';
import { Button, IconButton, ButtonGroup } from '../../components/button';
import { Table } from '../../components/table';
import {
  useLimitChange,
  usePageChange,
  useSortChange,
} from '../../components/table/hooks';
import { TitleModal } from '../../components/modal';
import { Grid, Column } from '../../components/grid';
import { FormItem, Input, Label } from '../../components/form';

const ReportList = () => {
  const { t: trans } = useTranslation();
  const { userInformation } = useContext(AuthenticateContext);
  const [listData, setListData] = useState([]);
  const [total, setTotal] = useState(1);
  const [listParameters, setListParameters] = useState({
    page: 1,
    limit: 10,
    sortColumn: 'createdAt',
    sortType: 'asc',
    filters: {
      search: '',
    },
  });

  const onLimitChange = useLimitChange(setListParameters);
  const onPageChange = usePageChange(setListParameters);
  const onSortChange = useSortChange(
    setListParameters,
    listParameters.sortColumn,
    listParameters.sortType,
  );

  const showFilterPanel = () => {
    TitleModal({
      size: 'normal',
      children: (
        <ReportListFilter
          listParameters={listParameters}
          setListParameters={setListParameters}
        />
      ),
    });
  };

  const generateActionDropdown = useCallback(
    ({ id }) => {
      return (
        <Dropdown>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem>
              <DropdownLinkOption to={`/report/${id}/setting`}>
                <FiSettings />
                {trans('Setting')}
              </DropdownLinkOption>
            </DropdownItem>
            <DropdownItem>
              <DropdownLinkOption to={`/report/${id}/data`}>
                <BiTable />
                {trans('Data')}
              </DropdownLinkOption>
            </DropdownItem>
            <DropdownItem>
              <DropdownLinkOption to={`/report/${id}/automated-report`}>
                <MdOutlineAttachEmail />
                {trans('Automated')}
              </DropdownLinkOption>
            </DropdownItem>
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [trans],
  );

  useEffect(() => {
    const requestData = {
      page: listParameters.page,
      limit: listParameters.limit,
      sortColumn: listParameters.sortColumn,
      sortType: listParameters.sortType,
    };
    if (listParameters.filters.search.trim()) {
      requestData.search = listParameters.filters.search.trim();
    }

    handleApiResponse(getReportList(requestData), (response) => {
      const { total, list } = response.data.data;
      setTotal(total);
      setListData(list);
    });
  }, [listParameters]);

  return (
    <>
      <Section noPadding>
        <SectionHeader sticky backgroundFill>
          <SectionToolbar>
            <SectionToolbarItem>
              <Heading1>{trans('ReportList')}</Heading1>
            </SectionToolbarItem>
            <SectionToolbarItem>
              {userInformation.permissions.includes('report.create') && (
                <Link to='/report/create'>
                  <Button>
                    <FaPlus />
                    {trans('button:Create')}
                  </Button>
                </Link>
              )}
              <Button secondary onClick={showFilterPanel}>
                <FaFilter />
                {trans('Filter')}
              </Button>
            </SectionToolbarItem>
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            columns={[
              {
                title: trans('Name'),
                fieldName: 'name',
              },
              {
                title: trans('CreatedTime'),
                fieldName: 'createdAt',
                sortable: true,
              },
              {
                title: trans('Description'),
                fieldName: 'memo',
              },
              {
                title: trans('Action'),
                custom: true,
                width: '10%',
                align: 'center',
                render(data) {
                  return generateActionDropdown(data);
                },
              },
            ]}
            data={listData}
            currentPage={listParameters.page}
            limit={listParameters.limit}
            total={total}
            sortColumn={listParameters.sortColumn}
            sortType={listParameters.sortType}
            onSortChange={onSortChange}
            onLimitChange={onLimitChange}
            onPageChange={onPageChange}
            translation={{
              info: trans('table.info'),
              empty: trans('table.empty'),
            }}
          />
        </SectionBody>
      </Section>
    </>
  );
};

const ReportListFilter = ({ listParameters, setListParameters, close }) => {
  const { t: trans } = useTranslation();
  const [searchData, setSearchData] = useState({
    ...listParameters.filters,
  });

  const handleChangeSearchKeyword = (key, value) => {
    searchData[key] = value;
    setSearchData({ ...searchData });
  };

  const handleSearch = () => {
    setListParameters((previousParams) => {
      return {
        ...previousParams,
        page: 1,
        filters: searchData,
      };
    });
    close();
  };

  const handleReset = () => {
    setSearchData({ search: '' });
  };

  return (
    <>
      <TitleModal.Header handleClose={close}>
        {trans('Filter')}
      </TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <Grid columns={2}>
          <Column desktop={2}>
            <FormItem>
              <Label htmlFor='keyword'>{trans('Keyword')}</Label>
              <Input
                type='text'
                id='keyword'
                value={searchData.search}
                onChange={(event) => {
                  handleChangeSearchKeyword('search', event.target.value);
                }}
              />
            </FormItem>
          </Column>
        </Grid>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={handleReset}>
            {trans('button:Reset')}
          </Button>
          <Button danger onClick={close}>
            {trans('button:Cancel')}
          </Button>
          <Button success onClick={handleSearch}>
            {trans('button:Search')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

export default ReportList;
