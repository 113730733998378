const generateRange = (start, end) => {
  const arr = [];
  for (let index = start; index <= end; index += 1) {
    arr.push(index);
  }

  return arr;
};

export default generateRange;
