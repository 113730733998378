import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Backdrop from './Backdrop';
import Container from './Container';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import appConfig from '../../config/app.config';

const Layout = () => {
  const [display, setDisplay] = useState(true);
  const toggleSidebar = () => {
    setDisplay(!display);
  };

  return (
    <Wrapper>
      <Header toggleSidebar={toggleSidebar} />
      <Main>
        <Backdrop $display={display} onClick={toggleSidebar}></Backdrop>
        <Sidebar $display={display} toggleSidebar={toggleSidebar} />
        <Container $display={display}>
          <Outlet />
        </Container>
      </Main>
      <Footer $display={display}>{appConfig.version}&nbsp;|&nbsp;</Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--color-background1);
`;

const Main = styled.div`
  display: flex;
  flex: 1;
  background: var(--color-background1);
  position: relative;
`;

export default Layout;
