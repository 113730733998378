import { useTranslation } from 'react-i18next';
import {
  getReverseGeolocation,
  getGeolocationByAddress,
} from '../../module/geolocation';
import { showErrorMessage } from '../../module/message';
import { Button, ButtonGroup } from '../../components/button';
import { TitleModal } from '../../components/modal';
import { ReverseGeolocation } from '../../components/geolocation';

const ReverseGeolocationModal = ({
  choseLatlng,
  setChoseAddress,
  setChoseLatlng,
  setGeolocationStep,
  close,
}) => {
  const { t: trans } = useTranslation();

  const onError = (errorCode) => {
    showErrorMessage({ message: trans(`error:${errorCode}`) });
  };

  const handleConfirmChooseLocation = (result) => {
    if (result.error) {
      onError(result.error);
      return;
    }

    if (result.message) {
      showErrorMessage({ message: result.message });
      return;
    }

    if (result.address) {
      setChoseAddress(result.address);
      setGeolocationStep('address');
      close();
    }
  };

  const onChoose = async (latLng) => {
    return new Promise((resolve, reject) => {
      getReverseGeolocation(latLng)
        .then((response) => {
          if (!response) {
            resolve('');
            return;
          }

          const { data } = response;
          if (data && data.address) {
            resolve(data.display_name);
          } else {
            resolve('');
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const onSearch = async (address) => {
    return new Promise((resolve, reject) => {
      getGeolocationByAddress(address)
        .then((response) => {
          if (response) {
            const { data } = response;
            if (data[0]) {
              const latLng = { lat: data[0].lat, lng: data[0].lon };
              resolve(latLng);
            } else {
              resolve('');
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <ReverseGeolocation
      defaultLatlng={choseLatlng}
      changeLatlng={setChoseLatlng}
      onChoose={onChoose}
      onSearch={onSearch}
      onError={onError}
    >
      {(map, confirmChooseLocation) => {
        return (
          <>
            <TitleModal.Header handleClose={close}>
              {trans('ChooseLocation')}
            </TitleModal.Header>
            <TitleModal.Body>{map}</TitleModal.Body>
            <TitleModal.Footer>
              <ButtonGroup>
                <Button danger onClick={close}>
                  {trans('button:Cancel')}
                </Button>
                <Button
                  success
                  onClick={() =>
                    confirmChooseLocation(handleConfirmChooseLocation)
                  }
                >
                  {trans('button:Confirm')}
                </Button>
              </ButtonGroup>
            </TitleModal.Footer>
          </>
        );
      }}
    </ReverseGeolocation>
  );
};

export default ReverseGeolocationModal;
