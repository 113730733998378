import styled from 'styled-components';

const FormGroupItem = styled.fieldset`
  margin-bottom: var(--spacing);
  padding: 0;
  border: none;

  legend {
    color: var(--font-on-background);
    font-size: var(--font-body1);
    margin-bottom: var(--spacing-xs);

    ${({ required }) =>
      required &&
      `&:after {
        content: ' *';
        color: var(--color-danger);
      }
    `}
  }

  label {
    margin: 0;
  }
`;

export default FormGroupItem;
