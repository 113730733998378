import { callApi } from '../services/api';

const getDailyCouponUsage = () => {
  return callApi.get('/daily-statistics/coupon-usage');
};

const getDailyPromoCodeUsage = () => {
  return callApi.get('/daily-statistics/redeem-code-usage');
};

const getDailyRegisteredUser = () => {
  return callApi.get('/daily-statistics/user-register');
};

const getDailyCouponRedeemed = () => {
  return callApi.get('/daily-statistics/user-coupon');
};

const getDailyDiscountAmount = () => {
  return callApi.get('/daily-statistics/discount-amount');
};

const getDailyPaymentAmount = () => {
  return callApi.get('/daily-statistics/payment-amount');
};

const getVendingStatistics = (data) => {
  return callApi.get('/statistics/vending', { params: data });
};

export {
  getDailyCouponUsage,
  getDailyPromoCodeUsage,
  getDailyRegisteredUser,
  getDailyCouponRedeemed,
  getDailyDiscountAmount,
  getDailyPaymentAmount,
  getVendingStatistics,
};
