import styled, { css } from 'styled-components';
import scrollbar from '../styles/mixin/scrollbar';
import media from '../styles/mixin/media';

const SectionBody = styled.div`
  // fix: overflow element(relate to z-index problem)
  // position: relative;
  background: transparent;
  padding: var(--spacing) 0 0 0;

  ${({ noPadding }) => noPadding && 'padding: 0;'}

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight}px;
      overflow-y: auto;
      overflow-x: hidden;
      ${scrollbar}
    `}

  ${({ maxHeightLaptop }) =>
    maxHeightLaptop &&
    css`
      ${media.laptop`
        max-height: ${maxHeightLaptop}px;
        overflow-y: auto;
<<<<<<< HEAD
        overflow-x: hidden;
=======
        overflow-x: hidden; 
>>>>>>> 101c36e ([CASHUB-7038] add component)
        ${scrollbar}
      `}
    `}
`;

export default SectionBody;
