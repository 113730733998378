import { callApi } from '../services/api';

const getReportList = (data) => {
  return callApi.get('/report-templates', { params: data });
};

const createReport = (data) => {
  return callApi.post('/report-templates', data);
};

const getReportInfo = (id) => {
  return callApi.get(`/report-templates/${id}`);
};

const modifyReport = (id, data) => {
  return callApi.patch(`/report-templates/${id}`, data);
};

const getReportDataList = (data) => {
  return callApi.get('/reports', { params: data });
};

const exportReport = (data) => {
  return callApi.post('/reports/export', data);
};

const getExportReportResult = (key) => {
  return callApi.get('/reports/export/results', {
    params: { key },
    global: false,
  });
};

const getAggregateList = () => {
  return callApi.get('/aggregates');
};

const getAutomatedReportList = (reportId, data) => {
  return callApi.get(`/report-templates/${reportId}/mail-events`, {
    params: data,
  });
};

const createAutomatedReport = (reportId, data) => {
  return callApi.post(`/report-templates/${reportId}/mail-events`, data);
};

const getAutomatedReportDetails = (id) => {
  return callApi.get(`/report-mail-events/${id}`);
};

const modifyAutomatedReport = (id, data) => {
  return callApi.patch(`/report-mail-events/${id}`, data);
};

const deleteAutomatedReport = (id) => {
  return callApi.delete(`/report-mail-events/${id}`);
};

const getAutomatedReportMailLogs = (id) => {
  return callApi.get('/mail-logs', { params: { eventId: id } });
};

export {
  getReportList,
  createReport,
  getReportInfo,
  modifyReport,
  getReportDataList,
  exportReport,
  getExportReportResult,
  getAggregateList,
  getAutomatedReportList,
  createAutomatedReport,
  getAutomatedReportDetails,
  modifyAutomatedReport,
  deleteAutomatedReport,
  getAutomatedReportMailLogs,
};
