import styled from 'styled-components';

const Logo = () => {
  return (
    <Container>
      <h1>Promotion Program</h1>
    </Container>
  );
};

const Container = styled.div`
  max-width: 220px;
  height: 40px;

  > h1 {
    font-size: 20px;
    line-height: 40px;
    color: var(--font-on-background);
  }
`;

export default Logo;
