import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import useCustomPopper from '../../utils/hooks/useCustomPopper';
import DropdownContext from './subComponent/DropdownContext';

const Dropdown = ({ children, fixed }) => {
  const [initialized, setInitialized] = useState(null);
  const [display, setDisplay] = useState(false);
  const [toggleElement, setToggleElement] = useState(null);
  const [contentElement, setContentElement] = useState(null);

  const { styles, attributes, state, update } = useCustomPopper(
    toggleElement,
    contentElement,
    {
      strategy: fixed ? 'fixed' : 'absolute',
    },
  );

  const handleSelect = () => {
    setDisplay(false);
  };

  const context = {
    display,
    setDisplay,
    handleSelect,
    setInitialized,
    setToggleElement: initialized ? setToggleElement : null,
    setContentElement: initialized ? setContentElement : null,
    styles,
    attributes,
    state,
    update,
  };

  return (
    <DropdownContext.Provider value={context}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setDisplay(false);
        }}
      >
        {children}
      </OutsideClickHandler>
    </DropdownContext.Provider>
  );
};

export default Dropdown;
