import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MD5 from 'crypto-js/md5';
import { getProfile, updateProfile } from '../../module/profile';
import { showErrorMessage, showSuccessMessage } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { replaceNonLatinSymbol } from '../../utils/regex/replace';
import { getBase64DataUrlFromFile } from '../../utils/file';
import { Section, SectionBody } from '../../components/section';
import { Grid, Column } from '../../components/grid';
import { FormItem, Input, Label } from '../../components/form';
import { UploadImage } from '../../components/image';
import { CropperModalHandler } from '../../components/cropper';
import { Button, ButtonGroup } from '../../components/button';
import defaultUserPic from '../../assets/images/default-user.jpg';

const ProfileUpdate = () => {
  const { t: trans } = useTranslation();
  const navigate = useNavigate();
  const logoRef = useRef(null);
  const [information, setInformation] = useState({
    username: '',
    email: '',
    telephone: '',
    roles: [],
    avatar: '',
    password: '',
    confirmPassword: '',
  });

  const handleChangeValue = (key, value) => {
    const newData = { ...information };
    newData[key] = value;

    setInformation(newData);
  };

  const getImageBase64Url = () => {
    return new Promise((resolve) => {
      getBase64DataUrlFromFile(logoRef.current.files[0], resolve);
    });
  };

  const handleSubmit = async (password) => {
    const requestData = {
      username: information.username.trim(),
      telephone: information.telephone,
    };
    if (password) {
      requestData.password = MD5(password).toString();
    }
    if (logoRef.current.files[0]) {
      const imageBase64Url = await getImageBase64Url();
      requestData.avatar = imageBase64Url;
    }
    handleApiResponse(updateProfile(requestData), () => {
      showSuccessMessage({ message: trans('success:ModifyProfile') });
      navigate(-1);
    });
  };

  const handleModify = () => {
    if (!information.username.trim()) {
      showErrorMessage({ message: trans('error:PleaseFillUpUsername') });
      return;
    }

    let changePsw = false;
    const trimedPassword = replaceNonLatinSymbol(information.password.trim());
    const trimedPasswordConfirmation = replaceNonLatinSymbol(
      information.confirmPassword.trim(),
    );

    if (trimedPassword || trimedPasswordConfirmation) {
      if (trimedPassword !== trimedPasswordConfirmation) {
        showErrorMessage({
          message: trans('error:PasswordConfirmationNotMatch'),
        });
        return;
      }
      changePsw = true;
    } else {
      changePsw = false;
    }

    if (changePsw) {
      handleSubmit(trimedPassword);
    } else {
      handleSubmit();
    }
  };

  useEffect(() => {
    handleApiResponse(getProfile(), (response) => {
      const { data } = response.data;
      setInformation((previous) => {
        return {
          ...previous,
          username: data.username,
          email: data.email,
          telephone: data.telephone,
          roles: data.roles,
          avatar: data.avatar || defaultUserPic,
        };
      });
    });
  }, []);

  return (
    <>
      <Section backgroundReverse noPaddingBottom>
        <SectionBody noPadding>
          <Grid columns={2}>
            <Column desktop={2}>
              <FormItem>
                <Label htmlFor='avatar'>{trans('Avatar')}</Label>
                <UploadImage
                  id='avatar'
                  ref={logoRef}
                  backgroundReverse
                  roundedCircle
                  src={information.avatar}
                  cropperHandler={CropperModalHandler}
                />
              </FormItem>
            </Column>
            <Column desktop={2}>
              <FormItem>
                <Label htmlFor='email'>{trans('Email')}</Label>
                <Input
                  type='text'
                  id='email'
                  value={information.email}
                  maxLength='128'
                  disabled={true}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='username' required>
                  {trans('UserName')}
                </Label>
                <Input
                  type='text'
                  id='username'
                  value={information.username}
                  onChange={(event) =>
                    handleChangeValue('username', event.target.value)
                  }
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='telephone'>{trans('Telephone')}</Label>
                <Input
                  type='text'
                  id='telephone'
                  value={information.telephone}
                  onChange={(event) =>
                    handleChangeValue('telephone', event.target.value)
                  }
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='password'>{trans('Password')}</Label>
                <Input
                  type='password'
                  id='password'
                  autoComplete='new-password'
                  value={information.password}
                  onChange={(event) =>
                    handleChangeValue('password', event.target.value)
                  }
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='confirmPassword'>
                  {trans('ConfirmPassword')}
                </Label>
                <Input
                  type='password'
                  id='confirmPassword'
                  autoComplete='new-password'
                  value={information.confirmPassword}
                  onChange={(event) =>
                    handleChangeValue('confirmPassword', event.target.value)
                  }
                />
              </FormItem>
            </Column>
          </Grid>
        </SectionBody>
      </Section>

      <ButtonGroup alignRight>
        <Button danger onClick={() => navigate(-1)}>
          {trans('button:Cancel')}
        </Button>
        <Button success onClick={handleModify}>
          {trans('button:Save')}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default ProfileUpdate;
