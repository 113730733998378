import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AppContext from '../provider/context/app.context';
import AuthenticateContext from '../provider/context/authenticate.context';
import Loader from '../components/animate/Loader';
import CustomToastContainer from '../components/toast/CustomToastContainer';
import Login from './login/Login';
import ForbiddenPage from './error/forbidden';
import NotFoundPage from './error/notFound';
import Dashboard from './dashboard/Dashboard';
import CouponList from './coupon/List';
import CreateCoupon from './coupon/Create';
import UserList from './user/List';
import InviteUser from './user/Invite';
import UserDetail from './user/Detail';
import Profile from './profile/Profile';
import Layout from '../components/layout/Layout';
import CouponDetail from './coupon/Detail';
import CouponRedeemCodeDetail from './coupon/redeem/Detail';
import TerminalList from './terminal/List';
import TerminalDetail from './terminal/Detail';
import ImportTerminal from './terminal/Import';
import CreateTerminal from './terminal/Create';
import TerminalGroupList from './terminalGroup/List';
import TerminalGroupDetail from './terminalGroup/Detail';
import CreateTerminalGroup from './terminalGroup/Create';
import BottlerGroupList from './bottlerGroup/List';
import BottlerGroupDetail from './bottlerGroup/Detail';
import CreateBottlerGroup from './bottlerGroup/Create';
import SalesCenterList from './salesCenter/List';
import SalesCenterDetail from './salesCenter/Detail';
import CreateSalesCenter from './salesCenter/Create';
import ReportList from './report/List';
import ReportSetting from './report/Setting';
import ReportData from './report/Data';
import AutomatedReportList from './report/AutomatedReport/List';
import CreateAutomatedReport from './report/AutomatedReport/Create';
import AutomatedReportDetail from './report/AutomatedReport/Detail';
import AutomatedReportMailLogs from './report/AutomatedReport/MailLogs';
import CreateReport from './report/Create';
import RoleList from './role/List';
import RoleDetail from './role/Detail';
import CreateRole from './role/Create';
import Promotions from './analyses/Promotions';
import ProfileUpdate from './profile/Update';

const Router = () => {
  const { loading } = useContext(AppContext);
  const { isGetting, userInformation } = useContext(AuthenticateContext);

  return (
    <>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/403' element={<ForbiddenPage />} />
        <Route path='/404' element={<NotFoundPage />} />

        {!isGetting && (
          <Route path='/' element={<Layout />}>
            <Route
              path='/'
              element={
                <ProtectedRoute>
                  <DefaultRoute />
                </ProtectedRoute>
              }
            />

            <Route
              path='dashboard'
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />

            {userInformation &&
              userInformation.menus &&
              Array.isArray(userInformation.menus) && (
                <>
                  {userInformation.menus.includes('coupon') && (
                    <>
                      <Route
                        path='coupon'
                        element={
                          <ProtectedRoute>
                            <CouponList />
                          </ProtectedRoute>
                        }
                      />
                      {userInformation.permissions.includes(
                        'coupon.create',
                      ) && (
                        <Route
                          path='coupon/create'
                          element={
                            <ProtectedRoute>
                              <CreateCoupon />
                            </ProtectedRoute>
                          }
                        />
                      )}
                      <Route
                        path='/coupon/:id'
                        element={
                          <ProtectedRoute>
                            <CouponDetail />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='/coupon/redeem-code/:id'
                        element={
                          <ProtectedRoute>
                            <CouponRedeemCodeDetail />
                          </ProtectedRoute>
                        }
                      />
                    </>
                  )}

                  {userInformation.menus.includes('terminal group') && (
                    <>
                      <Route
                        path='/terminal-group'
                        element={
                          <ProtectedRoute>
                            <TerminalGroupList />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='/terminal-group/:id'
                        element={
                          <ProtectedRoute>
                            <TerminalGroupDetail />
                          </ProtectedRoute>
                        }
                      />
                      {userInformation.permissions.includes(
                        'terminal_group.create',
                      ) && (
                        <Route
                          path='/terminal-group/create'
                          element={
                            <ProtectedRoute>
                              <CreateTerminalGroup />
                            </ProtectedRoute>
                          }
                        />
                      )}
                    </>
                  )}

                  {userInformation.menus.includes('report') && (
                    <>
                      <Route
                        path='report'
                        element={
                          <ProtectedRoute>
                            <ReportList />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='report/:id/setting'
                        element={
                          <ProtectedRoute>
                            <ReportSetting />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='report/:id/data'
                        element={
                          <ProtectedRoute>
                            <ReportData />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='report/:reportId/automated-report'
                        element={
                          <ProtectedRoute>
                            <AutomatedReportList />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='report/:reportId/automated-report/create'
                        element={
                          <ProtectedRoute>
                            <CreateAutomatedReport />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='report/:reportId/automated-report/:automatedReportId'
                        element={
                          <ProtectedRoute>
                            <AutomatedReportDetail />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='report/:reportId/automated-report/:automatedReportId/mail-logs'
                        element={
                          <ProtectedRoute>
                            <AutomatedReportMailLogs />
                          </ProtectedRoute>
                        }
                      />
                      {userInformation.permissions.includes(
                        'report.create',
                      ) && (
                        <Route
                          path='report/create'
                          element={
                            <ProtectedRoute>
                              <CreateReport />
                            </ProtectedRoute>
                          }
                        />
                      )}
                    </>
                  )}

                  {userInformation.menus.includes('bottler group') && (
                    <>
                      <Route
                        path='/bottler-group'
                        element={
                          <ProtectedRoute>
                            <BottlerGroupList />
                          </ProtectedRoute>
                        }
                      />
                      {userInformation.permissions.includes(
                        'bottler.create',
                      ) && (
                        <Route
                          path='/bottler-group/create'
                          element={
                            <ProtectedRoute>
                              <CreateBottlerGroup />
                            </ProtectedRoute>
                          }
                        />
                      )}
                      <Route
                        path='bottler-group/:id'
                        element={
                          <ProtectedRoute>
                            <BottlerGroupDetail />
                          </ProtectedRoute>
                        }
                      />
                    </>
                  )}

                  {userInformation.menus.includes('sales center') && (
                    <>
                      <Route
                        path='/sales-center'
                        element={
                          <ProtectedRoute>
                            <SalesCenterList />
                          </ProtectedRoute>
                        }
                      />
                      {userInformation.permissions.includes(
                        'sales_center.create',
                      ) && (
                        <Route
                          path='/sales-center/create'
                          element={
                            <ProtectedRoute>
                              <CreateSalesCenter />
                            </ProtectedRoute>
                          }
                        />
                      )}
                      <Route
                        path='/sales-center/:id'
                        element={
                          <ProtectedRoute>
                            <SalesCenterDetail />
                          </ProtectedRoute>
                        }
                      />
                    </>
                  )}

                  {userInformation.menus.includes('terminal') && (
                    <>
                      <Route
                        path='terminal'
                        element={
                          <ProtectedRoute>
                            <TerminalList />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='/terminal/:id'
                        element={
                          <ProtectedRoute>
                            <TerminalDetail />
                          </ProtectedRoute>
                        }
                      />
                      {userInformation.permissions.includes(
                        'terminal.create',
                      ) && (
                        <>
                          <Route
                            path='/terminal/import'
                            element={
                              <ProtectedRoute>
                                <ImportTerminal />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path='/terminal/create'
                            element={
                              <ProtectedRoute>
                                <CreateTerminal />
                              </ProtectedRoute>
                            }
                          />
                        </>
                      )}
                    </>
                  )}

                  {userInformation.menus.includes('role') && (
                    <>
                      <Route
                        path='/role'
                        element={
                          <ProtectedRoute>
                            <RoleList />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path='role/:id'
                        element={
                          <ProtectedRoute>
                            <RoleDetail />
                          </ProtectedRoute>
                        }
                      />
                      {userInformation.permissions.includes('role.create') && (
                        <Route
                          path='role/create'
                          element={
                            <ProtectedRoute>
                              <CreateRole />
                            </ProtectedRoute>
                          }
                        />
                      )}
                    </>
                  )}

                  {userInformation.menus.includes('user') && (
                    <>
                      <Route
                        path='user'
                        element={
                          <ProtectedRoute>
                            <UserList />
                          </ProtectedRoute>
                        }
                      />
                      {userInformation.permissions.includes(
                        'administrator.create',
                      ) && (
                        <Route
                          path='user/invite'
                          element={
                            <ProtectedRoute>
                              <InviteUser />
                            </ProtectedRoute>
                          }
                        />
                      )}
                      <Route
                        path='user/:id'
                        element={
                          <ProtectedRoute>
                            <UserDetail />
                          </ProtectedRoute>
                        }
                      />
                    </>
                  )}

                  <Route
                    path='promotions'
                    element={
                      <ProtectedRoute>
                        <Promotions />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/profile'
                    element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/profile/update'
                    element={
                      <ProtectedRoute>
                        <ProfileUpdate />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='*'
                    element={<Navigate to='/404' replace={true} />}
                  />
                </>
              )}

            <Route path='*' element={<ProtectedRoute />} />
          </Route>
        )}
      </Routes>

      <Loader $loading={loading} />
      <CustomToastContainer />
    </>
  );
};

const ProtectedRoute = ({ children }) => {
  const { authToken } = useContext(AuthenticateContext);

  if (!authToken) {
    return <Navigate to='/login' replace={true} />;
  }

  return children;
};

const DefaultRoute = () => {
  const { userInformation } = useContext(AuthenticateContext);

  if (!userInformation) return;

  return <Navigate to='/dashboard' replace={true} />;
};

export default Router;
