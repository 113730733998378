import { callApi } from '../services/api';

/**
 * call osm nominatim reverse geolocation service
 *
 * @param {Object} coordinates The coordinates to convert
 * @param {Object.latitude}
 * @param {Object.longitude}
 *
 * @fires cashub.reverse.geo.done
 */
const getReverseGeolocation = (coordinates) => {
  return callApi.get('https://nominatim.openstreetmap.org/reverse', {
    params: {
      lat: coordinates.lat,
      lon: coordinates.lng,
      format: 'json',
      'accept-language':
        window.navigator.language || window.navigator.languages[0],
    },
    external: true,
  });
};

/**
 * call osm nominatim search geolocation service
 *
 * @param {String} address The address to convert
 *
 * @fires cashub.reverse.geo.done
 */
const getGeolocationByAddress = (address) => {
  return callApi.get('https://nominatim.openstreetmap.org/search', {
    params: {
      q: address,
      format: 'json',
    },
    external: true,
  });
};

export { getReverseGeolocation, getGeolocationByAddress };
