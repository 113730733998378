import { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { USER_STATUS } from '../../provider/constant';
import { userInfo, modifyUser } from '../../module/user';
import { getRoleSimpleList } from '../../module/role';
import { showSuccessMessage } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import {
  Section,
  SectionHeader,
  SectionBody,
} from '../../components/section/index';
import { Heading1, Heading2 } from '../../components/heading/index';
import { Grid, Column } from '../../components/grid/index';
import { Select } from '../../components/Select/index';
import { FormItem, Input, Label } from '../../components/form/index';
import { Button, ButtonGroup } from '../../components/button/index';

const UserDetail = () => {
  const { t: trans } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { userInformation } = useContext(AuthenticateContext);
  const [isOnModify, setIsOnModify] = useState(false);
  const [roleList, setRoleList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [roleSimpleList, setRoleSimpleList] = useState([]);
  const [data, setData] = useState({
    username: '',
    email: '',
    telephone: '',
    status: '',
    roleId: '',
  });
  const [savedData, setSavedData] = useState(data);

  const userStatusOption = useMemo(() => {
    return USER_STATUS.map((status) => {
      return { id: status.id, text: trans(status.text) };
    });
  }, [trans]);

  const handleGetUserInfo = useCallback(() => {
    handleApiResponse(userInfo(id), (response) => {
      const { data } = response.data;
      setData({
        username: data.username,
        email: data.email,
        telephone: data.telephone,
        status: data.status,
        roleId: data.roleIds?.length > 0 ? data.roleIds[0] : '',
      });
      setSavedData({
        username: data.username,
        email: data.email,
        telephone: data.telephone,
        status: data.status,
        roleId: data.roleIds?.length > 0 ? data.roleIds[0] : '',
      });
    });
  }, [id]);

  const handleChangeValue = (key, value) => {
    const newData = { ...data };
    newData[key] = value;

    setData(newData);
  };

  const handleCancel = () => {
    setData(savedData);
    setIsOnModify(false);
  };

  const handleModify = () => {
    const requestData = {
      status: parseInt(data.status, 10),
      roleIds: [data.roleId],
    };
    handleApiResponse(modifyUser(id, requestData), () => {
      setIsOnModify(false);
      handleGetUserInfo();
      showSuccessMessage({ message: trans('success:ModifyUser') });
    });
  };

  const handleGetRoleList = useCallback((currentPage) => {
    const parameters = {
      page: currentPage,
      limit: 100,
    };
    handleApiResponse(getRoleSimpleList(parameters), (response) => {
      const { list, total } = response.data.data;

      setRoleList((previous) => {
        const newRoleList = [...previous.data, ...list];

        return {
          data: newRoleList,
          currentPage: currentPage + 1,
          lastPage: Math.ceil(total / 100),
        };
      });
    });
  }, []);

  useEffect(() => {
    if (roleList.currentPage <= roleList.lastPage) {
      handleGetRoleList(roleList.currentPage);
    } else {
      setRoleSimpleList(
        roleList.data.map((role) => {
          return { id: role.id, text: role.name };
        }),
      );
    }
  }, [
    handleGetRoleList,
    roleList.data,
    roleList.currentPage,
    roleList.lastPage,
  ]);

  useEffect(() => {
    handleGetUserInfo();
  }, [handleGetUserInfo]);

  return (
    <Section noPadding>
      <SectionHeader>
        <Heading1>{trans('UserDetails')}</Heading1>
      </SectionHeader>
      <SectionBody>
        <Section backgroundReverse noPaddingBottom>
          <SectionBody noPadding>
            <Heading2 whiteColor marginBottom>
              {trans('BasicInformation')}
            </Heading2>
            <Grid columns={2}>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='username'>{trans('UserName')}</Label>
                  <Input
                    type='text'
                    id='username'
                    value={data.username}
                    maxLength='128'
                    disabled={true}
                  />
                </FormItem>
              </Column>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='email'>{trans('Email')}</Label>
                  <Input
                    type='text'
                    id='email'
                    value={data.email}
                    maxLength='128'
                    disabled={true}
                  />
                </FormItem>
              </Column>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='telephone'>{trans('Telephone')}</Label>
                  <Input
                    type='text'
                    id='telephone'
                    value={data.telephone}
                    maxLength='128'
                    disabled={true}
                  />
                </FormItem>
              </Column>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='status'>{trans('Status')}</Label>
                  <Select
                    fullWidth
                    options={userStatusOption}
                    selected={data.status}
                    onSelect={(status) => {
                      handleChangeValue('status', status);
                    }}
                    disabled={!isOnModify}
                  />
                </FormItem>
              </Column>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='role' required={isOnModify}>
                    {trans('Role')}
                  </Label>
                  <Select
                    fullWidth
                    options={roleSimpleList}
                    selected={data.roleId}
                    onSelect={(id) => {
                      handleChangeValue('roleId', id);
                    }}
                    disabled={!isOnModify}
                  />
                </FormItem>
              </Column>
            </Grid>
          </SectionBody>
        </Section>

        <ButtonGroup alignRight>
          {isOnModify ? (
            <>
              <Button danger onClick={handleCancel}>
                {trans('button:Cancel')}
              </Button>
              <Button success onClick={handleModify}>
                {trans('button:Save')}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => navigate(-1)}>
                {trans('button:Back')}
              </Button>
              {userInformation.permissions.includes('administrator.update') && (
                <Button
                  warning
                  onClick={() => {
                    setIsOnModify(true);
                  }}
                >
                  {trans('button:Modify')}
                </Button>
              )}
            </>
          )}
        </ButtonGroup>
      </SectionBody>
    </Section>
  );
};

export default UserDetail;
