import { useRef, useEffect } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import Searchbox from '../../form/Searchbox';

const MapSearchBoxControl = ({ changeLatlng, onSearch, onError }) => {
  const map = useMap();
  const barRef = useRef(null);

  const handleError = (errorCode) => {
    if (onError) {
      onError(errorCode);
    }
  };

  const handleSearch = async (address) => {
    try {
      const latlng = await onSearch(address);
      if (latlng) {
        map.flyTo(latlng, 15);
        changeLatlng(latlng);
      } else {
        handleError('NO_RESULT');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  useEffect(() => {
    L.DomEvent.disableClickPropagation(barRef.current);
  }, []);

  return (
    <div className='leaflet-top leaflet-right' ref={barRef}>
      <div className='leaflet-control leaflet-bar'>
        <Searchbox onSearch={handleSearch} autoFocus />
      </div>
    </div>
  );
};

export default MapSearchBoxControl;
