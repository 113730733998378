import { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useCalculateDataRange, useGenerateSort } from './hooks';
import scrollbar from '../../styles/mixin/scrollbar';
import TH from './subComponent/GridTableHeadCell';
import Resizer from './subComponent/Resizer';
import GridTableFooter from './subComponent/GridTableFooter';
import TableFooterInfo from './subComponent/TableFooterInfo';
import TableFooterPager from './subComponent/TableFooterPager';
import Paginate from '../paginate/Paginate';
import { Select } from '../Select';
import Popover from '../popover/Popover';

const GridTable = ({
  columns,
  data,
  currentPage,
  limit,
  total,
  onPageChange,
  onLimitChange,
  sortColumn,
  sortType,
  onSortChange,
  backgroundReverse,
  translation,
}) => {
  const wrapperRef = useRef(null);
  const tableRef = useRef(null);
  const { start, end } = useCalculateDataRange(currentPage, limit, total);
  const [tableWidth, setTableWidth] = useState(0);
  const [restWidth, setRestWidth] = useState(0);

  const texts = useMemo(() => {
    const defaultTexts = {
      info: 'Showing _START_ to _END_ of _TOTAL_ entries',
      empty: 'No data available in table',
    };

    return { ...defaultTexts, ...translation };
  }, [translation]);

  const handleSortChange = useCallback(
    (columnName) => {
      if (onSortChange) {
        onSortChange(columnName);
      }
    },
    [onSortChange],
  );

  const handleResize = useCallback(() => {
    setTableWidth(tableRef.current.offsetWidth);
    setRestWidth((previousWidth) => {
      const containerWidth = wrapperRef.current.offsetWidth;
      const tableWidth = tableRef.current.offsetWidth - previousWidth;
      if (containerWidth > tableWidth) {
        return containerWidth - tableWidth;
      }

      return 0;
    });
  }, []);

  const handleScroll = useCallback((event) => {
    const { target } = event;
    wrapperRef.current.scrollLeft = target.scrollLeft;
  }, []);

  const showFullValue = useCallback((event) => {
    let { id } = event.target;
    if (!id) {
      ({ id } = event.currentTarget);
      event.target.id = id;
    }

    const { offsetWidth, scrollWidth, innerText } = event.target;
    if (scrollWidth >= offsetWidth && innerText) {
      event.stopPropagation();
      Popover({
        id,
        target: event.target,
        content: innerText,
        boundary: tableRef.current,
      });
    }
  }, []);

  // generate sort icon area
  const generateSort = useGenerateSort(sortColumn, sortType);

  const tableHeads = useMemo(() => {
    const tableHeads = columns.map((column, index) => {
      const { title, fieldName, width, sortable, align } = column;

      return (
        <TH
          key={`TH${index}`}
          width={width}
          align={align}
          backgroundReverse={backgroundReverse}
          sortable={sortable}
          onClick={() => {
            sortable && handleSortChange(fieldName);
          }}
        >
          <div>
            <span id={fieldName} onClick={showFullValue}>
              {title}
            </span>

            {sortable && generateSort(fieldName)}
          </div>
          <Resizer onResize={handleResize} />
        </TH>
      );
    });

    tableHeads.push(
      <TH
        key='rest'
        role='presentation'
        width={restWidth}
        backgroundReverse={backgroundReverse}
      ></TH>,
    );

    return tableHeads;
  }, [
    backgroundReverse,
    columns,
    handleSortChange,
    generateSort,
    showFullValue,
    handleResize,
    restWidth,
  ]);

  const tableRows = useMemo(() => {
    if (data.length === 0) {
      return null;
    }

    return data.map((row, rowIndex) => {
      const tds = columns.map((column, columnIndex) => {
        const { title, fieldName, render, custom, ...props } = column;
        const key = rowIndex + columnIndex.toString();

        let renderedData = null;
        if (custom && render) {
          // call render function to get custom data(fully)
          renderedData = render(row);
        } else {
          // call render function to get custom data(part)
          renderedData = (
            <span id={key} onClick={showFullValue}>
              {render ? render(row[fieldName], row) : String(row[fieldName])}
            </span>
          );
        }

        return (
          <TD
            key={columnIndex}
            data-label={title}
            backgroundReverse={backgroundReverse}
            {...props}
          >
            {renderedData}
          </TD>
        );
      });

      tds.push(
        <TD
          key='rest'
          role='presentation'
          backgroundReverse={backgroundReverse}
        ></TD>,
      );

      return <tr key={rowIndex}>{tds}</tr>;
    });
  }, [backgroundReverse, columns, data, showFullValue]);

  const generateEmptyMessage = useCallback(() => {
    return (
      <tr>
        <TD
          center
          colSpan={columns.length}
          backgroundReverse={backgroundReverse}
        >
          {texts.empty}
        </TD>
      </tr>
    );
  }, [backgroundReverse, columns, texts]);

  useEffect(() => {
    handleResize();
  }, [columns, handleResize]);

  useEffect(() => {
    if (tableRef.current) {
      setTableWidth(tableRef.current.offsetWidth);
    }
  }, [columns]);

  return (
    <>
      <TableWrapper ref={wrapperRef} backgroundReverse={backgroundReverse}>
        <ResponsiveTable ref={tableRef}>
          <thead>
            <tr>{tableHeads}</tr>
          </thead>
          <tbody>{tableRows || generateEmptyMessage()}</tbody>
        </ResponsiveTable>
      </TableWrapper>
      <Scrollbar
        backgroundReverse={backgroundReverse}
        width={tableWidth}
        onScroll={handleScroll}
      />
      <GridTableFooter backgroundReverse={backgroundReverse}>
        <TableFooterInfo>
          {texts.info
            .replace('_START_', start)
            .replace('_END_', end)
            .replace('_TOTAL_', total)}
        </TableFooterInfo>
        <TableFooterPager>
          <Paginate
            currentPage={currentPage}
            totalCount={total}
            pageSize={limit}
            onPageChange={onPageChange}
          />
          {/* fix: page limit style */}
          <Select
            fill
            fillReverse={!backgroundReverse}
            allowSearch={false}
            options={[
              { id: 10, text: '10' },
              { id: 20, text: '20' },
              { id: 30, text: '30' },
              { id: 40, text: '40' },
              { id: 50, text: '50' },
            ]}
            selected={limit}
            onSelect={onLimitChange}
          />
        </TableFooterPager>
      </GridTableFooter>
    </>
  );
};

const TableWrapper = styled.div`
  overflow: hidden;
  background: ${({ backgroundReverse }) => {
    return backgroundReverse
      ? 'var(--color-background1)'
      : 'var(--color-background2)';
  }};
`;

const ResponsiveTable = styled.table`
  /* make fixed width work */
  width: 0px;
  color: var(--font-on-background);
  table-layout: fixed;
  border-collapse: collapse;
`;

const TD = styled.td`
  height: 48px;
  padding: 0 var(--spacing-xs);
  border-top: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  background: ${({ backgroundReverse }) => {
    return backgroundReverse
      ? 'var(--color-background1)'
      : 'var(--color-background2)';
  }};

  &:last-child {
    border-right: none;
  }

  > span {
    display: inline-block;
    width: 100%;
    padding: 0 2px;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }
`;

const Scrollbar = styled.div`
  position: sticky;
  bottom: 48px;
  width: 100%;
  overflow: auto;
  background: ${({ backgroundReverse }) => {
    return backgroundReverse
      ? 'var(--color-background1)'
      : 'var(--color-background2)';
  }};

  ::before {
    display: block;
    content: '';
    width: ${({ width }) => `${width}px`};
    height: 1px;
  }

  ${scrollbar({ size: 'large' })}
`;

export default GridTable;
