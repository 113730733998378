import styled from 'styled-components';
import media from '../styles/mixin/media';
import MenuIcon from './MenuIcon';

const AsideHeader = ({ toggleSidebar }) => {
  return (
    <Brand>
      <MenuIcon toggleSidebar={toggleSidebar} />
    </Brand>
  );
};

const Brand = styled.div`
  display: none;
  height: 64px;

  ${media.tablet`
    padding: 0 0.5rem;
    align-items: center;
    display: flex
  `}
`;

export default AsideHeader;
