import { css } from 'styled-components';
import breakpoints from '../breakpoint.style';

// Iterate through the breakpoint and create a media template
const media = Object.keys(breakpoints).reduce((func, label) => {
  func[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)}
    }
  `;
  return func;
}, {});

export default media;
