import { callApi } from '../services/api';

const userList = ({ page, limit }) => {
  const params = { page, limit };

  return callApi.get('/administrators', { params });
};

const userInfo = (id) => {
  return callApi.get(`/administrators/${id}`);
};

const createUser = (data) => {
  return callApi.post('/administrators', data);
};

const modifyUser = (id, data) => {
  return callApi.patch(`/administrators/${id}`, data);
};

const deleteUser = (id) => {
  return callApi.delete(`/administrators/${id}`);
};

export { userList, userInfo, createUser, modifyUser, deleteUser };
