import styled from 'styled-components';
import media from '../styles/mixin/media';

const Backdrop = styled.div`
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;

  ${media.tablet`
    visibility: ${({ $display }) => ($display === true ? 'visible' : 'hidden')};
  `}
`;

export default Backdrop;
