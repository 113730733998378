import { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { FiFileText } from 'react-icons/fi';
import { MdMoreHoriz } from 'react-icons/md';
import {
  getBottlerGroupDetails,
  modifyBottlerGroup,
} from '../../module/bottler';
import { getSalesCenterList } from '../../module/salesCenter';
import { showErrorMessage, showSuccessMessage } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { Section, SectionBody, SectionHeader } from '../../components/section';
import { Heading1, Heading2 } from '../../components/heading';
import { Grid, Column } from '../../components/grid';
import { FormItem, Input, Label, Textarea } from '../../components/form';
import { Table } from '../../components/table';
import { useLimitChange, usePageChange } from '../../components/table/hooks';
import Dropdown, {
  DropdownItem,
  DropdownLinkOption,
} from '../../components/dropdown';
import { Button, IconButton, ButtonGroup } from '../../components/button';

const BottlerGroupDetail = () => {
  const { t: trans } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { userInformation } = useContext(AuthenticateContext);
  const [isOnModify, setIsOnModify] = useState(false);
  const [data, setData] = useState({
    name: '',
    remark: '',
  });
  const [savedData, setSavedData] = useState(data);

  const handleGetBottlerGroupDetails = useCallback(() => {
    getBottlerGroupDetails(id)
      .then((response) => {
        if (response) {
          const { data } = response.data;
          setData({
            name: data.name,
            remark: data.remark,
          });
          setSavedData({
            name: data.name,
            remark: data.remark,
          });
        }
      })
      .catch((error) => {
        showErrorMessage({ title: error.name, message: error.message });
      });
  }, [id]);

  const handleChangeValue = (key, value) => {
    setData((previous) => {
      const newData = { ...previous };
      newData[key] = value;

      return newData;
    });
  };

  const handleCancel = () => {
    setData(savedData);
    setIsOnModify(false);
  };

  const handleModify = () => {
    if (!data.name.trim()) {
      showErrorMessage({ message: trans('error:PleaseFillUpName') });
      return;
    }

    const requestData = {
      name: data.name.trim(),
      remark: data.remark.trim(),
    };

    handleApiResponse(modifyBottlerGroup(id, requestData), () => {
      setIsOnModify(false);
      handleGetBottlerGroupDetails();
      showSuccessMessage({ message: trans('success:ModifyBottlerGroup') });
    });
  };

  useEffect(() => {
    handleGetBottlerGroupDetails();
  }, [handleGetBottlerGroupDetails]);

  return (
    <>
      <Section noPadding>
        <SectionHeader>
          <Heading1>{trans('BottlerGroup')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionBody noPadding>
              <Grid columns={12}>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='name' required={isOnModify}>
                      {trans('Name')}
                    </Label>
                    <Input
                      id='name'
                      type='text'
                      value={data.name}
                      onChange={(event) =>
                        handleChangeValue('name', event.target.value)
                      }
                      maxLength='100'
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='description'>{trans('Description')}</Label>
                    <Textarea
                      id='description'
                      rows='4'
                      value={data.remark}
                      onChange={(event) =>
                        handleChangeValue('remark', event.target.value)
                      }
                      maxLength='200'
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
              </Grid>
            </SectionBody>
          </Section>

          <AssociatedSalesCenter id={id} />
        </SectionBody>
        <ButtonGroup alignRight>
          {isOnModify ? (
            <>
              <Button danger onClick={handleCancel}>
                {trans('button:Cancel')}
              </Button>
              <Button success onClick={handleModify}>
                {trans('button:Save')}
              </Button>
            </>
          ) : (
            <>
              <Button danger onClick={() => navigate(-1)}>
                {trans('button:Back')}
              </Button>
              {userInformation.permissions.includes('bottler.update') && (
                <Button
                  warning
                  onClick={() => {
                    setIsOnModify(true);
                  }}
                >
                  {trans('button:Modify')}
                </Button>
              )}
            </>
          )}
        </ButtonGroup>
      </Section>
    </>
  );
};

const AssociatedSalesCenter = ({ id }) => {
  const { t: trans } = useTranslation();
  const [listData, setListData] = useState([]);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
  });
  const [total, setTotal] = useState(1);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);

  const handleGetSalesCenterList = useCallback(() => {
    const requestData = {
      page: listParams.page,
      limit: listParams.limit,
      bottlerId: id,
    };

    handleApiResponse(getSalesCenterList(requestData), (response) => {
      const { total, list } = response.data.data;
      setTotal(total);
      setListData(list);
    });
  }, [listParams, id]);

  const generateActionDropdown = ({ id }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <IconButton>
            <MdMoreHoriz />
          </IconButton>
        </Dropdown.Toggle>
        <Dropdown.Content>
          <DropdownItem>
            <DropdownLinkOption to={`/sales-center/${id}`}>
              <FiFileText />
              {trans('button:Details')}
            </DropdownLinkOption>
          </DropdownItem>
        </Dropdown.Content>
      </Dropdown>
    );
  };

  useEffect(() => {
    handleGetSalesCenterList();
  }, [handleGetSalesCenterList]);

  return (
    <Section backgroundReverse>
      <SectionHeader>
        <Heading2 whiteColor>{trans('SalesCenterList')}</Heading2>
      </SectionHeader>
      <SectionBody noPadding>
        <Table
          backgroundReverse
          columns={[
            {
              title: trans('Name'),
              fieldName: 'name',
            },
            {
              title: trans('TerminalCount'),
              fieldName: 'terminalCount',
            },
            {
              title: trans('Description'),
              fieldName: 'description',
            },
            {
              title: trans('Action'),
              custom: true,
              width: '10%',
              align: 'center',
              render(data) {
                return generateActionDropdown(data);
              },
            },
          ]}
          data={listData}
          currentPage={listParams.page}
          limit={listParams.limit}
          total={total}
          onLimitChange={onLimitChange}
          onPageChange={onPageChange}
        />
      </SectionBody>
    </Section>
  );
};

export default BottlerGroupDetail;
