import styled, { css } from 'styled-components';

const Option = styled.div`
  max-width: 100%;
  word-break: break-all;
  padding: 12px var(--spacing-s);
  background: var(--color-background1);
  transition: background 0.3s;

  &:hover {
    background: var(--color-hover);
  }

  ${({ selected, focus }) =>
    (selected || focus) &&
    css`
      background: var(--color-hover);
    `}
`;
Option.displayName = 'Option';

export default Option;
