import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage } from '../../module/message';
import { getBottlerSimpleList } from '../../module/bottler';
import { createSalesCenter } from '../../module/salesCenter';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { Heading1 } from '../../components/heading';
import { Section, SectionBody, SectionHeader } from '../../components/section';
import { Grid, Column } from '../../components/grid';
import { FormItem, Input, Label, Textarea } from '../../components/form';
import { Select } from '../../components/Select';
import { Button, ButtonGroup } from '../../components/button';

const CreateSalesCenter = () => {
  const { t: trans } = useTranslation();
  const navigate = useNavigate();
  const [bottlerList, setBottlerList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [bottlerSimpleList, setBottlerSimpleList] = useState([]);
  const [data, setData] = useState({
    name: '',
    bottlerId: '',
    description: '',
  });

  const handleChangeValue = (key, value) => {
    setData((previous) => {
      return { ...previous, [key]: value };
    });
  };

  const handleGetBottlerList = useCallback((currentPage) => {
    const parameters = {
      page: currentPage,
      limit: 100,
    };
    handleApiResponse(getBottlerSimpleList(parameters), (response) => {
      const { list, total } = response.data.data;

      setBottlerList((previous) => {
        const newBottlerList = [...previous.data, ...list];

        return {
          data: newBottlerList,
          currentPage: currentPage + 1,
          lastPage: Math.ceil(total / 100),
        };
      });
    });
  }, []);

  const handleCreate = () => {
    if (!data.name.trim()) {
      showErrorMessage({ message: trans('error:PleaseFillUpName') });
      return;
    }

    if (!data.bottlerId) {
      showErrorMessage({ message: trans('error:PleaseSelectABottler') });
      return;
    }

    const requestData = {
      name: data.name.trim(),
      bottlerId: data.bottlerId,
      description: data.description.trim(),
    };

    handleApiResponse(createSalesCenter(requestData), () => {
      showSuccessMessage({ message: trans('success:CreateSalesCenter') });
      navigate(-1);
    });
  };

  useEffect(() => {
    if (bottlerList.currentPage <= bottlerList.lastPage) {
      handleGetBottlerList(bottlerList.currentPage);
    } else {
      setBottlerSimpleList(
        bottlerList.data.map((bottler) => {
          return { id: bottler.id, text: bottler.name };
        }),
      );
    }
  }, [
    handleGetBottlerList,
    bottlerList.data,
    bottlerList.currentPage,
    bottlerList.lastPage,
  ]);

  return (
    <>
      <Section noPadding>
        <SectionHeader>
          <Heading1>{trans('CreateSalesCenter')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionBody noPadding>
              <Grid columns={12}>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='name' required>
                      {trans('Name')}
                    </Label>
                    <Input
                      id='name'
                      type='text'
                      value={data.name}
                      onChange={(event) =>
                        handleChangeValue('name', event.target.value)
                      }
                      maxLength='100'
                    />
                  </FormItem>
                </Column>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='bottler' required>
                      {trans('Bottler')}
                    </Label>
                    <Select
                      fullWidth
                      options={bottlerSimpleList}
                      selected={data.bottlerId}
                      onSelect={(id) => {
                        handleChangeValue('bottlerId', id);
                      }}
                    />
                  </FormItem>
                </Column>
                <Column desktop={12}>
                  <FormItem>
                    <Label htmlFor='description'>{trans('Description')}</Label>
                    <Textarea
                      id='description'
                      rows='4'
                      value={data.description}
                      onChange={(event) =>
                        handleChangeValue('description', event.target.value)
                      }
                      maxLength='200'
                    />
                  </FormItem>
                </Column>
              </Grid>
            </SectionBody>
          </Section>
        </SectionBody>
        <ButtonGroup alignRight>
          <Button
            danger
            onClick={() => {
              navigate(-1);
            }}
          >
            {trans('button:Cancel')}
          </Button>
          <Button success onClick={handleCreate}>
            {trans('button:Create')}
          </Button>
        </ButtonGroup>
      </Section>
    </>
  );
};

export default CreateSalesCenter;
