import styled from 'styled-components';

const DropdownButtonOption = styled.button`
  display: flex;
  padding: 12px var(--spacing-s);
  color: var(--font-on-background);
  background: var(--color-background2);
  width: 100%;
  border: none;
  cursor: pointer;

  &:hover {
    background: var(--color-hover);
  }

  > svg {
    margin-right: var(--spacing-s);
  }
`;

export default DropdownButtonOption;
