import {
  useState,
  Children,
  cloneElement,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { FaCaretRight } from 'react-icons/fa';
import styled from 'styled-components';
import isReactComponent from '../../utils/react/isReactComponent';
import media from '../styles/mixin/media';

const Wizard = (props) => {
  const {
    navNames = [],
    defaultStep = 1,
    children,
    beforeNext,
    beforePrevious,
    clickableSteps = true,
  } = props;
  const [currentStep, setCurrentStep] = useState(defaultStep);

  const goToStep = useCallback(
    async (step) => {
      if (step > currentStep) {
        if (!beforeNext) {
          setCurrentStep(step);
        } else {
          beforeNext({ current: currentStep })
            .then(() => {
              setCurrentStep(step);
            })
            .catch((error) => {});
        }
      }

      if (step < currentStep) {
        if (!beforePrevious) {
          setCurrentStep(step);
        } else {
          beforePrevious({ current: currentStep })
            .then(() => {
              setCurrentStep(step);
            })
            .catch((error) => {});
        }
      }
    },
    [currentStep, beforeNext, beforePrevious],
  );

  const nextStep = useCallback(() => {
    let next = currentStep + 1;
    if (next > navNames.length) {
      next = navNames.length;
    }
    if (!beforeNext) {
      setCurrentStep(next);
    } else {
      beforeNext({ current: currentStep })
        .then(() => {
          setCurrentStep(next);
        })
        .catch((error) => {});
    }
  }, [navNames.length, beforeNext, currentStep]);

  const previousStep = useCallback(() => {
    let previous = currentStep - 1;
    if (previous < 1) {
      previous = 1;
    }

    if (!beforePrevious) {
      setCurrentStep(previous);
    } else {
      beforePrevious({ current: currentStep })
        .then(() => {
          setCurrentStep(previous);
        })
        .catch((error) => {});
    }
  }, [beforePrevious, currentStep]);

  const isLastStep = useCallback(() => {
    return currentStep === navNames.length;
  }, [currentStep, navNames.length]);

  const api = useMemo(() => {
    return {
      goToStep,
      nextStep,
      previousStep,
      isLastStep,
    };
  }, [goToStep, isLastStep, nextStep, previousStep]);

  const childrenWithProps = useMemo(() => {
    const validChildren = children.filter((child) => !!child);

    return Children.map(validChildren, (child, index) => {
      if (!child) return null;

      const className = index + 1 === currentStep && 'active';

      return (
        <Step className={className} data-testid='step'>
          {isReactComponent(child) ? cloneElement(child, api) : child}
        </Step>
      );
    });
  }, [api, children, currentStep]);

  useEffect(() => {
    setCurrentStep(defaultStep);
  }, [defaultStep]);

  return (
    <>
      <Nav>
        {navNames.map((name, index, arr) => {
          const step = index + 1;
          const arrow =
            step === currentStep && step !== arr.length ? 'arrow' : undefined;

          let className = '';
          if (step === currentStep) {
            className += 'current';
          } else if (step < currentStep) {
            className += 'done';
          }

          return (
            <NavItem
              key={step}
              className={className}
              onClick={() => clickableSteps === true && goToStep(step)}
            >
              <NavTitle>
                <span>{step}</span>
                <p>{name}</p>
                <span className={arrow}>
                  <FaCaretRight />
                </span>
              </NavTitle>
            </NavItem>
          );
        })}
      </Nav>
      <div>{childrenWithProps}</div>
    </>
  );
};

const Nav = styled.ul`
  display: flex;
  background: var(--color-background2);
  border-radius: var(--border-radius-l);
  margin-bottom: var(--spacing);

  ${media.mobile`
      display: block;
    `}
`;

const NavItem = styled.li`
  color: var(--font-on-mute);
  font-size: var(--font-body1);
  font-weight: var(--font-bold);
  padding: 0 var(--spacing-xs);
  width: 100%;
  height: 36px;
  line-height: 36px;
  transition: 0.3s;
  overflow: hidden;
  flex: 1;
  cursor: pointer;

  &:hover {
    color: var(--font-on-background);

    > div > span:first-of-type {
      color: var(--font-on-primary);
      background: var(--font-on-background);
    }
  }

  &.current,
  &.done {
    background: var(--color-primary);
    color: var(--font-on-primary);

    > div > span:first-of-type {
      color: var(--color-primary);
      background: var(--font-on-primary);
    }
  }

  &.current {
    background: var(--color-primary);
    color: var(--font-on-primary);
    border-radius: 0 var(--border-radius-l) var(--border-radius-l) 0;

    &:first-child {
      border-radius: var(--border-radius-l);
    }

    ${media.mobile`
        border-radius: 0 0 var(--border-radius) var(--border-radius);
      `}
  }

  &.done {
    background: var(--color-primary);
    color: var(--font-on-primary);

    &:first-child {
      border-radius: var(--border-radius-l) 0 0 var(--border-radius-l);

      ${media.mobile`
          border-radius: var(--border-radius) var(--border-radius) 0 0;
        `}
    }
  }
`;

const NavTitle = styled.div`
  display: flex;
  align-items: center;

  > span:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: var(--spacing-xs);
    color: var(--font-on-mute);
    flex: 0 0;
    flex-basis: 24px;
    width: 24px;
    height: 24px;
    border-radius: var(--border-radius-l);
    background: var(--font-on-mute);
  }

  > p {
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > span:last-of-type {
    display: none;

    &.arrow {
      display: flex;
      align-self: center;
    }

    ${media.mobile`
        transform: rotate(90deg);
      `}
  }
`;

const Step = styled.div`
  display: none;

  &.active {
    display: block;
  }
`;

export default Wizard;
