import styled from 'styled-components';

const MessageContainer = ({ title, message, containHTML = false }) => {
  return (
    <div data-testid='messageContainer'>
      {title && <Title>{title}</Title>}
      {containHTML ? (
        <Message dangerouslySetInnerHTML={{ __html: message }} />
      ) : (
        <Message>{message}</Message>
      )}
    </div>
  );
};

const Title = styled.div`
  margin: 0;
  margin-bottom: 0.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
`;

const Message = styled.div`
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.2rem;

  ul {
    margin-left: 1rem;

    li {
      list-style: disc;
    }
  }
`;

export default MessageContainer;
