import styled from 'styled-components';

const PermissionTable = styled.table`
  width: 100%;
  vertical-align: middle;
  table-layout: fixed;
  border: 0;
`;

const TH = styled.th`
  padding: var(--spacing) 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: var(--font-bold);

  ${({ clickable }) => clickable && 'cursor: pointer;'}
  ${({ alignCenter }) => alignCenter && 'text-align: center;'}
`;

const TD = styled.td`
  padding: var(--spacing) 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &:nth-child(even) {
    background: var(--color-background1);
  }

  ${({ clickable }) => clickable && 'cursor: pointer;'}
  ${({ alignCenter }) => alignCenter && 'text-align: center;'}
  ${({ bold }) => bold && 'font-weight: var(--font-bold);'}
`;

export default Object.assign(PermissionTable, {
  TH,
  TD,
});
