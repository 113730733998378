import { callApi } from '../services/api';

const getProfile = () => {
  return callApi.get('/profiles');
};

const updateProfile = (data) => {
  return callApi.patch('/profiles', data);
};

export { getProfile, updateProfile };
