import styled from 'styled-components';
import scrollbar from '../styles/mixin/scrollbar';

const Textarea = styled.textarea`
  display: block;
  width: 100%;
  border: var(--border-width) solid var(--border-color);
  padding: 10px var(--spacing-s);
  border-radius: var(--border-radius);
  background: transparent;
  color: var(--color-white);
  transition: 0.3s;
  line-height: 1.5;

  /* disable resize because currently cannot fully control the resizer element */
  resize: none;

  ${scrollbar}

  &:focus {
    border: var(--border-width) solid var(--color-primary);
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

`;

export default Textarea;
