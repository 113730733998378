import { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import AuthenticateContext from '../../provider/context/authenticate.context';
import {
  getDailyCouponUsage,
  getDailyPromoCodeUsage,
  getDailyRegisteredUser,
  getDailyCouponRedeemed,
  getDailyDiscountAmount,
  getDailyPaymentAmount,
  getVendingStatistics,
} from '../../module/dashboard';
import { getBottlerSimpleList } from '../../module/bottler';
import { getSalesCenterSimpleList } from '../../module/salesCenter';
import { showErrorMessage } from '../../module/message';
import generateRange from '../../utils/array/generateRange';
import handleApiResponse from '../../utils/api/handleApiResponse';
import {
  Section,
  SectionHeader,
  SectionBody,
  SectionToolbar,
} from '../../components/section';
import { Heading1, Heading2 } from '../../components/heading';
import { Select } from '../../components/Select';
import { BarChart } from '../../components/chart';
import { Grid, Column } from '../../components/grid';
import { FormItem, Label } from '../../components/form';

const date = new Date();
const DEFAULT_YEAR = date.getFullYear();
const DEFAULT_MONTH = date.getMonth() + 1;
const yearList = generateRange(2000, DEFAULT_YEAR).map((year) => {
  return { id: year, text: year.toString() };
});

const monthList = generateRange(1, 12).map((month) => {
  return { id: month, text: month.toString() };
});

const Dashboard = () => {
  const { t: trans } = useTranslation();
  const { userInformation } = useContext(AuthenticateContext);
  const [type, setType] = useState();

  const chartOptions = useMemo(() => {
    const options = [];
    if (!userInformation) return options;

    if (userInformation.permissions.includes('coupon.view')) {
      options.push({ id: 'coupon', text: trans('Coupon') });
    }

    if (userInformation.permissions.includes('report.view')) {
      options.push({ id: 'vending', text: trans('Vending') });
    }

    return options;
  }, [trans, userInformation]);

  useEffect(() => {
    if (chartOptions.length > 0) {
      setType(chartOptions[0].id);
    }
  }, [chartOptions]);

  return (
    <Section noPadding>
      <SectionHeader sticky backgroundFill inline>
        <Heading1>{trans('Dashboard')}</Heading1>
        <SectionToolbar>
          <Select
            allowSearch={false}
            options={chartOptions}
            selected={type}
            onSelect={(type) => setType(type)}
          />
        </SectionToolbar>
      </SectionHeader>
      <SectionBody>
        {type === 'coupon' && <CouponDashboard />}
        {type === 'vending' && <VendingDashboard />}
      </SectionBody>
    </Section>
  );
};

const CouponDashboard = () => {
  return (
    <>
      <DailyCouponUsage />
      <DailyRegisteredUser />
      <DailyCouponRedeemed />
      <DailyPaymentAmount />
    </>
  );
};

const VendingDashboard = () => {
  const [bottlerList, setBottlerList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [bottlerSimpleList, setBottlerSimpleList] = useState([]);

  const handleGetBottlerList = useCallback((currentPage) => {
    const parameters = {
      page: currentPage,
      limit: 100,
    };
    handleApiResponse(getBottlerSimpleList(parameters), (response) => {
      const { list, total } = response.data.data;

      setBottlerList((previous) => {
        const newBottlerList = [...previous.data, ...list];

        return {
          data: newBottlerList,
          currentPage: currentPage + 1,
          lastPage: Math.ceil(total / 100),
        };
      });
    });
  }, []);

  useEffect(() => {
    if (bottlerList.currentPage <= bottlerList.lastPage) {
      handleGetBottlerList(bottlerList.currentPage);
    } else {
      setBottlerSimpleList(
        bottlerList.data.map((bottler) => {
          return { id: bottler.id, text: bottler.name };
        }),
      );
    }
  }, [
    handleGetBottlerList,
    bottlerList.data,
    bottlerList.currentPage,
    bottlerList.lastPage,
  ]);

  return (
    <>
      <DailyVendingAmount bottlerSimpleList={bottlerSimpleList} />
      <DailyVendingCount bottlerSimpleList={bottlerSimpleList} />
    </>
  );
};

const DailyCouponUsage = () => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const dailyCouponUsageTypeData = [
    { id: 1, text: t('Coupon') },
    { id: 2, text: t('PromoCode') },
  ];
  const [dailyCouponUsageType, setDailyCouponUsageType] = useState(1);
  const [dailyCouponUsageData, setDailyCouponUsageData] = useState(null);

  const updateDailyCouponUsageData = useCallback(
    (data) => {
      const usages = [];
      const labels = [];
      data.forEach((day) => {
        const [date, usage] = Object.entries(day)[0];
        labels.push(date);
        usages.push(usage);
      });

      setDailyCouponUsageData({
        data: {
          labels,
          datasets: [
            {
              data: usages,
              backgroundColor: theme.colorPrimary,
              borderWidth: 0,
              maxBarThickness: 50,
              minBarLength: 1,
            },
          ],
        },
        options: {
          maxBarNumber: 15,
        },
      });
    },
    [theme],
  );

  useEffect(() => {
    let dailyUsage = null;
    if (dailyCouponUsageType === 1) {
      dailyUsage = getDailyCouponUsage();
    }
    if (dailyCouponUsageType === 2) {
      dailyUsage = getDailyPromoCodeUsage();
    }

    if (!dailyUsage) return;
    dailyUsage
      .then((response) => {
        if (response) {
          const { data } = response.data;

          updateDailyCouponUsageData(data.list);
        }
      })
      .catch((error) => {
        showErrorMessage({ title: error.name, message: error.message });
      });
  }, [dailyCouponUsageType, updateDailyCouponUsageData]);

  return (
    <Section backgroundReverse backgroundMarker>
      <SectionHeader inline>
        <Heading2>{t('DailyCouponUsage')}</Heading2>
        <SectionToolbar>
          <Select
            fillReverse
            allowSearch={false}
            options={dailyCouponUsageTypeData}
            selected={dailyCouponUsageType}
            onSelect={(typeId) => setDailyCouponUsageType(parseInt(typeId, 10))}
          />
        </SectionToolbar>
      </SectionHeader>
      <SectionBody>
        {dailyCouponUsageData && (
          <BarChart
            height='300'
            data={dailyCouponUsageData.data}
            options={dailyCouponUsageData.options}
          />
        )}
      </SectionBody>
    </Section>
  );
};

const DailyRegisteredUser = () => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const [dailyRegisteredUserData, setDailyRegisteredUserData] = useState(null);

  const updateDailyRegisteredUserData = useCallback(
    (data) => {
      const registeredCounts = [];
      const labels = [];
      data.forEach((day) => {
        const [date, registeredCount] = Object.entries(day)[0];
        labels.push(date);
        registeredCounts.push(registeredCount);
      });

      setDailyRegisteredUserData({
        data: {
          labels,
          datasets: [
            {
              data: registeredCounts,
              backgroundColor: theme.colorPrimary,
              borderWidth: 0,
              maxBarThickness: 50,
              minBarLength: 1,
            },
          ],
        },
        options: {
          maxBarNumber: 15,
        },
      });
    },
    [theme],
  );

  useEffect(() => {
    getDailyRegisteredUser()
      .then((response) => {
        if (response) {
          const { data } = response.data;

          updateDailyRegisteredUserData(data.list);
        }
      })
      .catch((error) => {
        showErrorMessage({ title: error.name, message: error.message });
      });
  }, [updateDailyRegisteredUserData]);

  return (
    <Section backgroundReverse backgroundMarker>
      <SectionHeader>
        <Heading2>{t('DailyRegisteredUser')}</Heading2>
      </SectionHeader>
      <SectionBody>
        {dailyRegisteredUserData && (
          <BarChart
            height='300'
            data={dailyRegisteredUserData.data}
            options={dailyRegisteredUserData.options}
          />
        )}
      </SectionBody>
    </Section>
  );
};

const DailyCouponRedeemed = () => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const [dailyCouponRedeemedData, setDailyCouponRedeemedData] = useState(null);

  const updateDailyCouponRedeemedData = useCallback(
    (data) => {
      const redeemedCounts = [];
      const labels = [];
      data.forEach((day) => {
        const [date, redeemedCount] = Object.entries(day)[0];
        labels.push(date);
        redeemedCounts.push(redeemedCount);
      });

      setDailyCouponRedeemedData({
        data: {
          labels,
          datasets: [
            {
              data: redeemedCounts,
              backgroundColor: theme.colorPrimary,
              borderWidth: 0,
              maxBarThickness: 50,
              minBarLength: 1,
            },
          ],
        },
        options: {
          maxBarNumber: 15,
        },
      });
    },
    [theme],
  );

  useEffect(() => {
    getDailyCouponRedeemed()
      .then((response) => {
        if (response) {
          const { data } = response.data;

          updateDailyCouponRedeemedData(data.list);
        }
      })
      .catch((error) => {
        showErrorMessage({ title: error.name, message: error.message });
      });
  }, [updateDailyCouponRedeemedData]);

  return (
    <Section backgroundReverse backgroundMarker>
      <SectionHeader>
        <Heading2>{t('DailyCouponRedeemed')}</Heading2>
      </SectionHeader>
      <SectionBody>
        {dailyCouponRedeemedData && (
          <BarChart
            height='300'
            data={dailyCouponRedeemedData.data}
            options={dailyCouponRedeemedData.options}
          />
        )}
      </SectionBody>
    </Section>
  );
};

const DailyPaymentAmount = () => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const [dailyPaymentAmountData, setDailyPaymentAmountData] = useState(null);

  const updateDailyPaymentAmountData = useCallback(
    (data) => {
      const labels = [];
      const discount = [];
      const payment = [];
      Object.entries(data).forEach(([date, amount]) => {
        labels.push(date);
        discount.push(amount.discount || 0);
        payment.push(amount.payment || 0);
      });

      const datasets = [
        {
          label: 'payment',
          data: payment,
          customTitle: Array(labels.length).fill('Payment'),
          backgroundColor: theme.colorPrimary,
          borderWidth: 0,
          maxBarThickness: 50,
          minBarLength: 1,
        },
        {
          label: 'dicsount',
          data: discount,
          customTitle: Array(labels.length).fill('Discount'),
          backgroundColor: theme.colorDanger,
          borderWidth: 0,
          maxBarThickness: 50,
          minBarLength: 1,
        },
      ];

      setDailyPaymentAmountData({
        data: {
          labels,
          datasets,
        },
        options: {
          onlyFirstTooltips: false,
          maxBarNumber: 15,
          stacked: true,
        },
      });
    },
    [theme],
  );

  const getDailyAmount = useCallback(async () => {
    const dailyDiscountAmountResponse = await getDailyDiscountAmount();
    const dailyPaymentAmountResponse = await getDailyPaymentAmount();

    if (!dailyDiscountAmountResponse || !dailyPaymentAmountResponse) return;
    const { list: dailyDiscountAmount } = dailyDiscountAmountResponse.data.data;
    const { list: dailyPaymentAmount } = dailyPaymentAmountResponse.data.data;

    const dailyAmount = {};
    dailyDiscountAmount.forEach((day) => {
      const [date, amount] = Object.entries(day)[0];
      dailyAmount[date] = {
        discount: amount,
      };
    });

    dailyPaymentAmount.forEach((day) => {
      const [date, amount] = Object.entries(day)[0];
      if (!dailyAmount[date]) {
        dailyAmount[date] = {};
      }
      dailyAmount[date].payment = amount;
    });

    updateDailyPaymentAmountData(dailyAmount);
  }, [updateDailyPaymentAmountData]);

  useEffect(() => {
    getDailyAmount();
  }, [getDailyAmount]);

  return (
    <Section backgroundReverse backgroundMarker>
      <SectionHeader>
        <Heading2>{t('DailyPaymentAmount')}</Heading2>
      </SectionHeader>
      <SectionBody>
        {dailyPaymentAmountData && (
          <BarChart
            height='300'
            data={dailyPaymentAmountData.data}
            options={dailyPaymentAmountData.options}
          />
        )}
      </SectionBody>
    </Section>
  );
};

const DailyVendingAmount = ({ bottlerSimpleList }) => {
  const { t: trans } = useTranslation();
  const theme = useContext(ThemeContext);
  const [fetchingSalesCenterList, setFetchingSalesCenterList] = useState(false);
  const [salesCenterList, setSalesCenterList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [salesCenterSimpleList, setSalesCenterSimpleList] = useState([]);
  const [year, setYear] = useState(DEFAULT_YEAR);
  const [month, setMonth] = useState(DEFAULT_MONTH);
  const [bottlerID, setBottlerID] = useState('');
  const [saleCenterId, setSaleCenterId] = useState('');
  const [dailyVendingAmountData, setDailyVendingAmountData] = useState(null);

  const handleGetSalesCenterList = useCallback((bottlerID, currentPage) => {
    const parameters = {
      page: currentPage,
    };
    handleApiResponse(
      getSalesCenterSimpleList(bottlerID, parameters),
      (response) => {
        const { list, limit, total } = response.data.data;

        setSalesCenterList((previous) => {
          const newSalesCenterList = [...previous.data, ...list];

          return {
            data: newSalesCenterList,
            currentPage: currentPage + 1,
            lastPage: Math.ceil(total / limit),
          };
        });
        setFetchingSalesCenterList(false);
      },
    );
  }, []);

  const updateDailyVendingAmountData = useCallback(
    (data) => {
      const vendingAmounts = [];
      const labels = [];
      data.forEach((day) => {
        labels.push(day.day);
        vendingAmounts.push(day.amount);
      });

      setDailyVendingAmountData({
        data: {
          labels,
          datasets: [
            {
              data: vendingAmounts,
              backgroundColor: theme.colorPrimary,
              borderWidth: 0,
              maxBarThickness: 50,
              minBarLength: 1,
            },
          ],
        },
        options: {
          maxBarNumber: 15,
        },
      });
    },
    [theme],
  );

  useEffect(() => {
    if (!bottlerID) return;
    if (fetchingSalesCenterList) return;

    if (salesCenterList.currentPage <= salesCenterList.lastPage) {
      handleGetSalesCenterList(bottlerID, salesCenterList.currentPage);
      setFetchingSalesCenterList(true);
    } else {
      setSalesCenterSimpleList(
        salesCenterList.data.map((salesCenter) => {
          return { id: salesCenter.id, text: salesCenter.name };
        }),
      );
    }
  }, [
    bottlerID,
    fetchingSalesCenterList,
    handleGetSalesCenterList,
    salesCenterList.data,
    salesCenterList.currentPage,
    salesCenterList.lastPage,
  ]);

  // reset sales center related state
  useEffect(() => {
    setSalesCenterList({
      data: [],
      currentPage: 1,
      lastPage: 1,
    });
    setSalesCenterSimpleList([]);
    setSaleCenterId('');
  }, [bottlerID]);

  useEffect(() => {
    const parameters = {
      year,
      month,
    };
    if (bottlerID) {
      parameters.bottlerId = bottlerID;
    }
    if (saleCenterId) {
      parameters.saleCenterId = saleCenterId;
    }

    getVendingStatistics(parameters)
      .then((response) => {
        if (response) {
          const { data } = response.data;

          updateDailyVendingAmountData(data.list);
        }
      })
      .catch((error) => {
        showErrorMessage({ title: error.name, message: error.message });
      });
  }, [updateDailyVendingAmountData, year, month, bottlerID, saleCenterId]);

  return (
    <Section backgroundReverse backgroundMarker>
      <SectionHeader>
        <Heading2>{trans('DailyVendingAmount')}</Heading2>
      </SectionHeader>
      <SectionBody>
        <Grid columns={12}>
          <Column desktop={3} tablet={4} mobile={12}>
            <FormItem>
              <Label htmlFor='year'>{trans('Year')}</Label>
              <Select
                fullWidth
                options={yearList}
                selected={year}
                onSelect={(year) => setYear(parseInt(year, 10))}
              />
            </FormItem>
          </Column>
          <Column desktop={3} tablet={4} mobile={12}>
            <FormItem>
              <Label htmlFor='month'>{trans('Month')}</Label>
              <Select
                fullWidth
                options={monthList}
                selected={month}
                onSelect={(month) => setMonth(parseInt(month, 10))}
              />
            </FormItem>
          </Column>
          <Column desktop={3} tablet={4} mobile={12}>
            <FormItem>
              <Label htmlFor='bottler'>{trans('Bottler')}</Label>
              <Select
                fullWidth
                allowClear={true}
                options={bottlerSimpleList}
                selected={bottlerID}
                onSelect={setBottlerID}
              />
            </FormItem>
          </Column>

          {bottlerID && (
            <Column desktop={3} tablet={4} mobile={12}>
              <FormItem>
                <Label htmlFor='salesCenter'>{trans('SalesCenter')}</Label>
                <Select
                  fullWidth
                  allowClear={true}
                  options={salesCenterSimpleList}
                  selected={saleCenterId}
                  onSelect={setSaleCenterId}
                />
              </FormItem>
            </Column>
          )}

          {dailyVendingAmountData && (
            <Column desktop={12}>
              <BarChart
                height='300'
                data={dailyVendingAmountData.data}
                options={dailyVendingAmountData.options}
              />
            </Column>
          )}
        </Grid>
      </SectionBody>
    </Section>
  );
};

const DailyVendingCount = ({ bottlerSimpleList }) => {
  const { t: trans } = useTranslation();
  const theme = useContext(ThemeContext);
  const [fetchingSalesCenterList, setFetchingSalesCenterList] = useState(false);
  const [salesCenterList, setSalesCenterList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [salesCenterSimpleList, setSalesCenterSimpleList] = useState([]);
  const [year, setYear] = useState(DEFAULT_YEAR);
  const [month, setMonth] = useState(DEFAULT_MONTH);
  const [bottlerID, setBottlerID] = useState('');
  const [saleCenterId, setSaleCenterId] = useState('');
  const [dailyVendingQuantityData, setDailyVendingQuantityData] =
    useState(null);

  const handleGetSalesCenterList = useCallback((bottlerID, currentPage) => {
    const parameters = {
      page: currentPage,
    };
    handleApiResponse(
      getSalesCenterSimpleList(bottlerID, parameters),
      (response) => {
        const { list, limit, total } = response.data.data;

        setSalesCenterList((previous) => {
          const newSalesCenterList = [...previous.data, ...list];

          return {
            data: newSalesCenterList,
            currentPage: currentPage + 1,
            lastPage: Math.ceil(total / limit),
          };
        });
        setFetchingSalesCenterList(false);
      },
    );
  }, []);

  const updateDailyVendingQuantityData = useCallback(
    (data) => {
      const vendingQuantities = [];
      const labels = [];
      data.forEach((day) => {
        labels.push(day.day);
        vendingQuantities.push(day.quantity);
      });

      setDailyVendingQuantityData({
        data: {
          labels,
          datasets: [
            {
              data: vendingQuantities,
              backgroundColor: theme.colorPrimary,
              borderWidth: 0,
              maxBarThickness: 50,
              minBarLength: 1,
            },
          ],
        },
        options: {
          maxBarNumber: 15,
        },
      });
    },
    [theme],
  );

  useEffect(() => {
    if (!bottlerID) return;
    if (fetchingSalesCenterList) return;

    if (salesCenterList.currentPage <= salesCenterList.lastPage) {
      handleGetSalesCenterList(bottlerID, salesCenterList.currentPage);
      setFetchingSalesCenterList(true);
    } else {
      setSalesCenterSimpleList(
        salesCenterList.data.map((salesCenter) => {
          return { id: salesCenter.id, text: salesCenter.name };
        }),
      );
    }
  }, [
    bottlerID,
    fetchingSalesCenterList,
    handleGetSalesCenterList,
    salesCenterList.data,
    salesCenterList.currentPage,
    salesCenterList.lastPage,
  ]);

  // reset sales center related state
  useEffect(() => {
    setSalesCenterList({
      data: [],
      currentPage: 1,
      lastPage: 1,
    });
    setSalesCenterSimpleList([]);
    setSaleCenterId('');
  }, [bottlerID]);

  useEffect(() => {
    const parameters = {
      year,
      month,
    };
    if (bottlerID) {
      parameters.bottlerId = bottlerID;
    }
    if (saleCenterId) {
      parameters.saleCenterId = saleCenterId;
    }

    getVendingStatistics(parameters)
      .then((response) => {
        if (response) {
          const { data } = response.data;

          updateDailyVendingQuantityData(data.list);
        }
      })
      .catch((error) => {
        showErrorMessage({ title: error.name, message: error.message });
      });
  }, [updateDailyVendingQuantityData, year, month, bottlerID, saleCenterId]);

  return (
    <Section backgroundReverse backgroundMarker>
      <SectionHeader>
        <Heading2>{trans('DailyVendingCount')}</Heading2>
      </SectionHeader>
      <SectionBody>
        <Grid columns={12}>
          <Column desktop={3} tablet={4} mobile={12}>
            <FormItem>
              <Label htmlFor='year'>{trans('Year')}</Label>
              <Select
                fullWidth
                options={yearList}
                selected={year}
                onSelect={(year) => setYear(parseInt(year, 10))}
              />
            </FormItem>
          </Column>
          <Column desktop={3} tablet={4} mobile={12}>
            <FormItem>
              <Label htmlFor='month'>{trans('Month')}</Label>
              <Select
                fullWidth
                options={monthList}
                selected={month}
                onSelect={(month) => setMonth(parseInt(month, 10))}
              />
            </FormItem>
          </Column>
          <Column desktop={3} tablet={4} mobile={12}>
            <FormItem>
              <Label htmlFor='bottler'>{trans('Bottler')}</Label>
              <Select
                fullWidth
                allowClear={true}
                options={bottlerSimpleList}
                selected={bottlerID}
                onSelect={setBottlerID}
              />
            </FormItem>
          </Column>

          {bottlerID && (
            <Column desktop={3} tablet={4} mobile={12}>
              <FormItem>
                <Label htmlFor='salesCenter'>{trans('SalesCenter')}</Label>
                <Select
                  fullWidth
                  allowClear={true}
                  options={salesCenterSimpleList}
                  selected={saleCenterId}
                  onSelect={setSaleCenterId}
                />
              </FormItem>
            </Column>
          )}

          {dailyVendingQuantityData && (
            <Column desktop={12}>
              <BarChart
                height='300'
                data={dailyVendingQuantityData.data}
                options={dailyVendingQuantityData.options}
              />
            </Column>
          )}
        </Grid>
      </SectionBody>
    </Section>
  );
};

export default Dashboard;
