import { forwardRef } from 'react';
import styled from 'styled-components';
import { GoSearch } from 'react-icons/go';
import inputPlaceholder from '../../../styles/mixin/inputPlaceholder';

const SearchBox = forwardRef((props, ref) => {
  const handleClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Wrapper onClick={handleClick}>
      <GoSearch />
      <SearchInput type='text' ref={ref} {...props} />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  position: relative;
  padding: var(--spacing-xs);

  > svg {
    color: var(--font-on-mute);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(var(--spacing-s) + var(--spacing-xs));
  }
`;
Wrapper.displayName = 'Wrapper';

const SearchInput = styled.input`
  background: var(--color-background1);
  color: var(--color-white);
  border-radius: var(--border-radius-l);
  border: var(--border-width) solid var(--border-color);
  outline: none;
  width: 100%;
  padding: 10px var(--spacing-s) 10px calc(var(--spacing-s) * 2 + 8px);
  max-height: 36px;

  &:disabled {
    cursor: not-allowed;
  }

  ${inputPlaceholder`
    color: var(--font-on-mute);
  `}
`;
SearchInput.displayName = 'SearchInput';

export default SearchBox;
