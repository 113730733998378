import { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { getTerminalDetails, modifyTerminal } from '../../module/terminal';
import { getBottlerSimpleList } from '../../module/bottler';
import { getSalesCenterSimpleList } from '../../module/salesCenter';
import { showErrorMessage, showSuccessMessage } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { Section, SectionBody, SectionHeader } from '../../components/section';
import { Heading1 } from '../../components/heading';
import { TitleModal } from '../../components/modal';
import { Grid, Column } from '../../components/grid';
import { FormItem, Input, Label } from '../../components/form';
import { Select } from '../../components/Select';
import { Button, ButtonGroup } from '../../components/button';
import ReverseGeolocationModal from '../../partial/modal/ReverseGeolocationModal';
import ConfirmLocationModal from '../../partial/modal/ConfirmLocationModal';

const TerminalDetail = () => {
  const { t: trans } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { userInformation } = useContext(AuthenticateContext);
  const [isOnModify, setIsOnModify] = useState(false);
  const [bottlerList, setBottlerList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [bottlerSimpleList, setBottlerSimpleList] = useState([]);
  const [fetchingSalesCenterList, setFetchingSalesCenterList] = useState(false);
  const [salesCenterList, setSalesCenterList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [salesCenterSimpleList, setSalesCenterSimpleList] = useState([]);
  const [data, setData] = useState({
    sn: '',
    bottlerID: '',
    saleCenterId: '',
    outletNumber: '',
    outletName: '',
    nameExtension: '',
    assetNumber: '',
    address: '',
    latitude: '',
    longitude: '',
  });
  const [savedData, setSavedData] = useState(data);
  const [geolocationStep, setGeolocationStep] = useState(null);
  const [choseAddress, setChoseAddress] = useState('');
  const [choseLatlng, setChoseLatlng] = useState({
    lat: 0,
    lng: 0,
  });

  const handleGetBottlerList = useCallback((currentPage) => {
    const parameters = {
      page: currentPage,
      limit: 100,
    };
    handleApiResponse(getBottlerSimpleList(parameters), (response) => {
      const { list, total } = response.data.data;

      setBottlerList((previous) => {
        const newBottlerList = [...previous.data, ...list];

        return {
          data: newBottlerList,
          currentPage: currentPage + 1,
          lastPage: Math.ceil(total / 100),
        };
      });
    });
  }, []);

  const handleGetSalesCenterList = useCallback((bottlerID, currentPage) => {
    const parameters = {
      page: currentPage,
    };
    handleApiResponse(
      getSalesCenterSimpleList(bottlerID, parameters),
      (response) => {
        const { list, limit, total } = response.data.data;

        setSalesCenterList((previous) => {
          const newSalesCenterList = [...previous.data, ...list];

          return {
            data: newSalesCenterList,
            currentPage: currentPage + 1,
            lastPage: Math.ceil(total / limit),
          };
        });
        setFetchingSalesCenterList(false);
      },
    );
  }, []);

  const handleGetTerminalDetails = useCallback(() => {
    getTerminalDetails(id)
      .then((response) => {
        if (response) {
          const { data } = response.data;
          setData({
            sn: data.sn,
            bottlerID: data.bottlerId,
            saleCenterId: data.saleCenterId,
            outletNumber: data.outletNumber,
            outletName: data.outletName,
            nameExtension: data.nameExtension,
            assetNumber: data.assetNumber,
            address: data.locationName,
            latitude: '',
            longitude: '',
          });
          setSavedData({
            sn: data.sn,
            bottlerID: data.bottlerId,
            saleCenterId: data.saleCenterId,
            outletNumber: data.outletNumber,
            outletName: data.outletName,
            nameExtension: data.nameExtension,
            assetNumber: data.assetNumber,
            address: data.locationName,
            latitude: '',
            longitude: '',
          });
        }
      })
      .catch((error) => {
        showErrorMessage({ title: error.name, message: error.message });
      });
  }, [id]);

  const handleChangeBottler = (value) => {
    handleChangeValue('bottlerID', value);
    handleChangeValue('saleCenterId', '');
  };

  const handleChangeValue = (key, value) => {
    setData((previous) => {
      const newData = { ...previous, [key]: value };

      return newData;
    });
  };

  const handleCancel = () => {
    setData(savedData);
    setIsOnModify(false);
  };

  const handleModify = () => {
    if (data.saleCenterId && !data.bottlerID) {
      showErrorMessage({
        message: trans('error:PleaseSelectABottler'),
      });
      return;
    }

    const trimmedOutletNumber = data.outletNumber.trim();
    if (trimmedOutletNumber && !data.saleCenterId) {
      showErrorMessage({
        message: trans('error:PleaseFillUpSalesCenter'),
      });
      return;
    }

    const trimmedOutletName = data.outletName.trim();
    if (trimmedOutletName && !data.saleCenterId) {
      showErrorMessage({
        message: trans('error:PleaseFillUpSalesCenter'),
      });
      return;
    }

    const trimmedNameExtension = data.nameExtension.trim();
    if (trimmedNameExtension && !data.saleCenterId) {
      showErrorMessage({
        message: trans('error:PleaseFillUpSalesCenter'),
      });
      return;
    }

    const trimmedAssetNumber = data.assetNumber.trim();
    if (trimmedAssetNumber && !data.saleCenterId) {
      showErrorMessage({
        message: trans('error:PleaseFillUpSalesCenter'),
      });
      return;
    }

    const requestData = {
      sn: data.sn.trim(),
      bottlerId: data.bottlerID,
      saleCenterId: data.saleCenterId,
      outletNumber: trimmedOutletNumber,
      outletName: trimmedOutletName,
      nameExtension: trimmedNameExtension,
      assetNumber: trimmedAssetNumber,
      locationName: data.address,
    };
    handleApiResponse(modifyTerminal(id, requestData), () => {
      setIsOnModify(false);
      handleGetTerminalDetails();
      showSuccessMessage({ message: trans('success:ModifyTerminal') });
    });
  };

  const handleFocusAddressField = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setGeolocationStep('map');
  };

  const handleResetAddress = () => {
    setData({ ...data, address: '' });
    setChoseLatlng({
      lat: 0,
      lng: 0,
    });
    setChoseAddress('');
  };

  useEffect(() => {
    if (bottlerList.currentPage <= bottlerList.lastPage) {
      handleGetBottlerList(bottlerList.currentPage);
    } else {
      setBottlerSimpleList(
        bottlerList.data.map((bottler) => {
          return { id: bottler.id, text: bottler.name };
        }),
      );
    }
  }, [
    handleGetBottlerList,
    bottlerList.data,
    bottlerList.currentPage,
    bottlerList.lastPage,
  ]);

  useEffect(() => {
    if (!data.bottlerID) return;
    if (fetchingSalesCenterList) return;

    if (salesCenterList.currentPage <= salesCenterList.lastPage) {
      handleGetSalesCenterList(data.bottlerID, salesCenterList.currentPage);
      setFetchingSalesCenterList(true);
    } else {
      setSalesCenterSimpleList(
        salesCenterList.data.map((salesCenter) => {
          return { id: salesCenter.id, text: salesCenter.name };
        }),
      );
    }
  }, [
    data.bottlerID,
    fetchingSalesCenterList,
    handleGetSalesCenterList,
    salesCenterList.data,
    salesCenterList.currentPage,
    salesCenterList.lastPage,
  ]);

  // reset sales center related state when bottler changed
  useEffect(() => {
    setSalesCenterList({
      data: [],
      currentPage: 1,
      lastPage: 1,
    });
    setSalesCenterSimpleList([]);
  }, [data.bottlerID]);

  // clear outlet state when sales center changed
  useEffect(() => {
    if (!data.saleCenterId) {
      setData((previous) => {
        return {
          ...previous,
          outletNumber: '',
          outletName: '',
          nameExtension: '',
          assetNumber: '',
        };
      });
    }
  }, [data.saleCenterId]);

  useEffect(() => {
    if (!geolocationStep) return;

    if (geolocationStep === 'map') {
      TitleModal({
        size: 'normal',
        children: (
          <ReverseGeolocationModal
            choseLatlng={choseLatlng}
            setChoseAddress={setChoseAddress}
            setChoseLatlng={setChoseLatlng}
            setGeolocationStep={setGeolocationStep}
          />
        ),
      });
      setGeolocationStep(null);
    }

    if (geolocationStep === 'address') {
      TitleModal({
        size: 'normal',
        children: (
          <ConfirmLocationModal
            choseAddress={choseAddress}
            setChoseAddress={setChoseAddress}
            setGeolocationStep={setGeolocationStep}
          />
        ),
      });
      setGeolocationStep(null);
    }

    if (geolocationStep === 'done') {
      setData({
        ...data,
        address: choseAddress,
        latitude: choseLatlng.lat,
        longitude: choseLatlng.lng,
      });
      setGeolocationStep(null);
    }
  }, [data, setData, choseLatlng, choseAddress, geolocationStep]);

  useEffect(() => {
    handleGetTerminalDetails();
  }, [handleGetTerminalDetails]);

  return (
    <>
      <Section noPadding>
        <SectionHeader>
          <Heading1>{trans('Terminal')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionBody noPadding>
              <Grid columns={2}>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='sn'>{trans('HardwareSerialNumber')}</Label>
                    <Input
                      id='sn'
                      type='text'
                      value={data.sn}
                      disabled={true}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='address'>{trans('Address')}</Label>
                    <div style={{ position: 'relative' }}>
                      <Input
                        type='text'
                        id='address'
                        value={data.address}
                        onChange={(event) =>
                          handleChangeValue('address', event)
                        }
                        onMouseDown={handleFocusAddressField}
                        onFocus={handleFocusAddressField}
                        disabled={!isOnModify}
                      />
                      {isOnModify && data.address && (
                        <MdClose
                          onClick={handleResetAddress}
                          style={{
                            position: 'absolute',
                            right: '1rem',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            color: 'var(--color-primary)',
                          }}
                          role='button'
                          aria-label='clear button'
                        />
                      )}
                    </div>
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='bottler'>{trans('Bottler')}</Label>
                    <Select
                      fullWidth
                      allowClear={true}
                      options={bottlerSimpleList}
                      selected={data.bottlerID}
                      onSelect={handleChangeBottler}
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='salesCenter'>{trans('SalesCenter')}</Label>
                    <Select
                      fullWidth
                      allowClear={true}
                      options={salesCenterSimpleList}
                      selected={data.saleCenterId}
                      onSelect={(id) => {
                        handleChangeValue('saleCenterId', id);
                      }}
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='outletNumber'>
                      {trans('OutletNumber')}
                    </Label>
                    <Input
                      id='outletNumber'
                      type='text'
                      value={data.outletNumber}
                      onChange={(event) => {
                        handleChangeValue('outletNumber', event.target.value);
                      }}
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='outletName'>{trans('OutletName')}</Label>
                    <Input
                      id='outletName'
                      type='text'
                      value={data.outletName}
                      onChange={(event) => {
                        handleChangeValue('outletName', event.target.value);
                      }}
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='nameExtension'>
                      {trans('NameExtension')}
                    </Label>
                    <Input
                      id='nameExtension'
                      type='text'
                      value={data.nameExtension}
                      onChange={(event) => {
                        handleChangeValue('nameExtension', event.target.value);
                      }}
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='assetNumber'>{trans('AssetNumber')}</Label>
                    <Input
                      id='assetNumber'
                      type='text'
                      value={data.assetNumber}
                      onChange={(event) => {
                        handleChangeValue('assetNumber', event.target.value);
                      }}
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
              </Grid>
            </SectionBody>
          </Section>
        </SectionBody>
        <ButtonGroup alignRight>
          {isOnModify ? (
            <>
              <Button danger onClick={handleCancel}>
                {trans('button:Cancel')}
              </Button>
              <Button success onClick={handleModify}>
                {trans('button:Save')}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => navigate(-1)}>
                {trans('button:Back')}
              </Button>
              {userInformation.permissions.includes('terminal.update') && (
                <Button
                  warning
                  onClick={() => {
                    setIsOnModify(true);
                  }}
                >
                  {trans('button:Modify')}
                </Button>
              )}
            </>
          )}
        </ButtonGroup>
      </Section>
    </>
  );
};

export default TerminalDetail;
