import TitleModal from '../modal/TitleModal';
import CropImageModal from './subComponent/CropImageModal';

const CropperModalHandler = ({
  src,
  file,
  width,
  height,
  cropBoxResizable = false,
  onCrop,
  onClose,
}) => {
  TitleModal({
    size: 'normal',
    children: (
      <CropImageModal
        src={src}
        mime={file.type}
        alt={file.name}
        file={file}
        width={width}
        height={height}
        cropBoxResizable={cropBoxResizable}
        onCrop={onCrop}
        onClose={onClose}
      />
    ),
    onClose: (dismiss) => {
      if (dismiss === true && onClose) {
        onClose();
      }
    },
  });
};

export default CropperModalHandler;
