import { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { MdMoreHoriz, MdDelete } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { getRoleList, deleteRole } from '../../module/role';
import { showSuccessMessage } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import Dropdown, {
  DropdownItem,
  DropdownLinkOption,
  DropdownButtonOption,
} from '../../components/dropdown';
import { Button, IconButton } from '../../components/button';
import {
  Section,
  SectionHeader,
  SectionToolbar,
  SectionToolbarItem,
  SectionBody,
} from '../../components/section';
import { Heading1 } from '../../components/heading';
import { Table } from '../../components/table';
import { useLimitChange, usePageChange } from '../../components/table/hooks';

const RoleList = () => {
  const { t: trans } = useTranslation();
  const { userInformation } = useContext(AuthenticateContext);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(1);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
  });

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);

  const handleGetRoleList = useCallback(() => {
    const params = {
      page: listParams.page,
      limit: listParams.limit,
    };
    handleApiResponse(getRoleList(params), (response) => {
      const { list, total } = response.data.data;
      setData(list);
      setTotal(total);
    });
  }, [listParams]);

  const handleRemove = useCallback(
    (id) => {
      handleApiResponse(deleteRole(id), () => {
        showSuccessMessage({
          message: trans('success:RemoveRole'),
        });
        handleGetRoleList();
      });
    },
    [trans, handleGetRoleList],
  );

  const generateActionDropdown = useCallback(
    ({ id }) => {
      return (
        <Dropdown key={id}>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem>
              <DropdownLinkOption to={`/role/${id}`}>
                <FiFileText />
                {trans('button:Details')}
              </DropdownLinkOption>
            </DropdownItem>
            {userInformation.permissions.includes('role.delete') && (
              <DropdownItem onClick={() => handleRemove(id)}>
                <DropdownButtonOption>
                  <MdDelete />
                  {trans('button:Remove')}
                </DropdownButtonOption>
              </DropdownItem>
            )}
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [trans, userInformation, handleRemove],
  );

  useEffect(() => {
    handleGetRoleList();
  }, [handleGetRoleList]);

  return (
    <>
      <Section noPadding>
        <SectionHeader sticky backgroundFill>
          <SectionToolbar>
            <SectionToolbarItem>
              <Heading1>{trans('RoleList')}</Heading1>
            </SectionToolbarItem>
            {userInformation.permissions.includes('role.create') && (
              <SectionToolbarItem>
                <Link to={'/role/create'}>
                  <Button>
                    <FaPlus />
                    {trans('button:Create')}
                  </Button>
                </Link>
              </SectionToolbarItem>
            )}
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            columns={[
              {
                title: trans('Name'),
                fieldName: 'name',
                sortable: false,
              },
              {
                title: trans('Action'),
                custom: true,
                width: '10%',
                align: 'center',
                render(data) {
                  return generateActionDropdown(data);
                },
              },
            ]}
            data={data}
            currentPage={listParams.page}
            limit={listParams.limit}
            onLimitChange={onLimitChange}
            total={total}
            sortColumn={listParams.sort_column}
            sortType={listParams.sort_type}
            onPageChange={onPageChange}
          />
        </SectionBody>
      </Section>
    </>
  );
};

export default RoleList;
