import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MdMoreHoriz, MdDelete } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AUTOMATED_REPORT_TYPE } from '../../../provider/constant';
import {
  getAutomatedReportList,
  deleteAutomatedReport,
} from '../../../module/report';
import { showSuccessMessage } from '../../../module/message';
import handleApiResponse from '../../../utils/api/handleApiResponse';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionToolbar,
  SectionToolbarItem,
} from '../../../components/section';
import Dropdown, {
  DropdownItem,
  DropdownLinkOption,
  DropdownButtonOption,
} from '../../../components/dropdown';
import { Heading1 } from '../../../components/heading';
import { Button, IconButton } from '../../../components/button';
import { Table } from '../../../components/table';
import { useLimitChange, usePageChange } from '../../../components/table/hooks';

const AutomatedReportList = () => {
  const { t: trans } = useTranslation();
  const { reportId } = useParams();
  const [listData, setListData] = useState([]);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
  });
  const [total, setTotal] = useState(1);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);

  const handleDeleteAutomatedReport = (id) => {
    handleApiResponse(deleteAutomatedReport(id), () => {
      showSuccessMessage({ message: trans('success:RemoveAutomatedReport') });
      handleGetAutomatedReportList();
    });
  };

  const handleGetAutomatedReportList = useCallback(() => {
    const requestData = {
      page: listParams.page,
      limit: listParams.limit,
    };

    handleApiResponse(
      getAutomatedReportList(reportId, requestData),
      (response) => {
        const { total, list } = response.data.data;
        setTotal(total);
        setListData(list);
      },
    );
  }, [reportId, listParams]);

  const generateActionDropdown = ({ id }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <IconButton>
            <MdMoreHoriz />
          </IconButton>
        </Dropdown.Toggle>
        <Dropdown.Content>
          <DropdownItem>
            <DropdownLinkOption
              to={`/report/${reportId}/automated-report/${id}`}
            >
              <FiFileText />
              {trans('button:Details')}
            </DropdownLinkOption>
          </DropdownItem>
          <DropdownItem onClick={() => handleDeleteAutomatedReport(id)}>
            <DropdownButtonOption>
              <MdDelete />
              {trans('button:Remove')}
            </DropdownButtonOption>
          </DropdownItem>
          <DropdownItem>
            <DropdownLinkOption
              to={`/report/${reportId}/automated-report/${id}/mail-logs`}
            >
              <FiFileText />
              {trans('button:Logs')}
            </DropdownLinkOption>
          </DropdownItem>
        </Dropdown.Content>
      </Dropdown>
    );
  };

  useEffect(() => {
    handleGetAutomatedReportList();
  }, [handleGetAutomatedReportList]);

  return (
    <>
      <Section noPadding>
        <SectionHeader sticky backgroundFill>
          <SectionToolbar>
            <SectionToolbarItem>
              <Heading1>{trans('AutomatedReportList')}</Heading1>
            </SectionToolbarItem>
            <SectionToolbarItem>
              <Link to={`/report/${reportId}/automated-report/create`}>
                <Button>
                  <FaPlus />
                  {trans('button:Create')}
                </Button>
              </Link>
            </SectionToolbarItem>
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            columns={[
              {
                title: trans('Name'),
                fieldName: 'name',
              },
              {
                title: trans('Timezone'),
                fieldName: 'timezone',
              },
              {
                title: trans('Bottler'),
                fieldName: 'bottlerName',
              },
              {
                title: trans('SalesCenter'),
                fieldName: 'saleCenterName',
              },
              {
                title: trans('ReportType'),
                fieldName: 'reportType',
                render(data) {
                  const found = AUTOMATED_REPORT_TYPE.find((type) => {
                    return type.id === data;
                  });
                  if (found) {
                    return found.text;
                  }

                  return '';
                },
              },
              {
                title: trans('Action'),
                custom: true,
                width: '10%',
                align: 'center',
                render(data) {
                  return generateActionDropdown(data);
                },
              },
            ]}
            data={listData}
            currentPage={listParams.page}
            limit={listParams.limit}
            total={total}
            onLimitChange={onLimitChange}
            onPageChange={onPageChange}
          />
        </SectionBody>
      </Section>
    </>
  );
};

export default AutomatedReportList;
