import { useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import uniqueId from 'lodash.uniqueid';
import { FaPlus } from 'react-icons/fa';
import { MdOutlineRemoveCircleOutline } from 'react-icons/md';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { REPORT_COLUMNS_TYPE } from '../../provider/constant';
import {
  getReportInfo,
  modifyReport,
  getAggregateList,
} from '../../module/report';
import { showErrorMessage, showSuccessMessage } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { isInteger } from '../../utils/check';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionToolbar,
} from '../../components/section';
import { Heading1, Heading2 } from '../../components/heading';
import { FormItem, Input, Label, Textarea } from '../../components/form';
import { Select } from '../../components/Select';
import { Button, ButtonGroup, IconButton } from '../../components/button';
import { Table } from '../../components/table';

const ReportSetting = () => {
  const { t: trans } = useTranslation();
  const { userInformation } = useContext(AuthenticateContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOnModify, setIsOnModify] = useState(false);
  const [information, setInformation] = useState({
    name: '',
    memo: '',
  });
  const [savedInformation, setSavedInformation] = useState(information);
  const [allColumnsData, setAllColumnsData] = useState([]);
  const [savedAllColumnsData, setSavedAllColumnsData] = useState([]);
  const [allGroupByData, setAllGroupByData] = useState([]);
  const [savedAllGroupByData, setSavedAllGroupByData] = useState([]);
  const [allFunctionData, setAllFunctionData] = useState([]);
  const [savedAllFunctionData, setSavedAllFunctionData] = useState([]);
  const [currentColumnsData, setCurrentColumnsData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);

  const canUpdateColumn = useMemo(() => {
    return userInformation.permissions.includes('report.update_advance');
  }, [userInformation.permissions]);

  const handleGetReportInfo = useCallback(() => {
    handleApiResponse(getReportInfo(id), (response) => {
      const { name, memo, fields, groups, aggregates } = response.data.data;
      const information = { name, memo };
      const columns = fields.map((field) => {
        return {
          ...field,
          columnId: field.id,
          displayWidth: field.displayWidth.toString(),
          orderIndex: field.orderIndex.toString(),
        };
      });
      const groupBy = groups.map((group) => {
        return { ...group, rowId: group.id };
      });
      const functions = aggregates.map((aggregate) => {
        return { ...aggregate, rowId: aggregate.id };
      });
      setInformation({ ...information });
      setSavedInformation({ ...information });
      setAllColumnsData([...columns]);
      setSavedAllColumnsData([...columns]);
      setAllGroupByData([...groupBy]);
      setSavedAllGroupByData([...groupBy]);
      setAllFunctionData([...functions]);
      setSavedAllFunctionData([...functions]);
    });
  }, [id]);

  const checkColumnName = useCallback(
    (name) => {
      const trimmedName = name.trim();
      if (!trimmedName) {
        showErrorMessage({ message: trans('error:PleaseFillUpColumnName') });
        return false;
      }

      return true;
    },
    [trans],
  );

  const checkColumnWidth = useCallback(
    (width) => {
      if (width.trim() === '') return true;

      const widthNum = Number(width);
      if (
        isNaN(widthNum) ||
        widthNum > 1000 ||
        widthNum < 0 ||
        !isInteger(widthNum)
      ) {
        showErrorMessage({ message: trans('error:ColumnWidthFormat') });
        return false;
      }

      return true;
    },
    [trans],
  );

  const checkColumnField = useCallback(
    (field) => {
      const trimmedField = field.trim();
      if (!trimmedField) {
        showErrorMessage({ message: trans('error:PleaseFillUpColumnField') });
        return false;
      }

      return true;
    },
    [trans],
  );

  const checkColumnIndex = useCallback(
    (index) => {
      const indexNum = Number(index);
      if (
        isNaN(indexNum) ||
        indexNum > 1000 ||
        indexNum < 0 ||
        !isInteger(indexNum)
      ) {
        showErrorMessage({ message: trans('error:ColumnIndexFormat') });
        return false;
      } else if (index.trim() === '') {
        showErrorMessage({ message: trans('error:PleaseFillUpColumnIndex') });
        return false;
      }

      return true;
    },
    [trans],
  );

  const isColumnIndexDuplicate = useCallback((columns) => {
    const checkedIndexes = [];
    for (const column of columns) {
      // make sure index is number to prevent compare between string and number
      const index = Number(column.orderIndex);

      // ignore non-number value
      if (!isNaN(index)) {
        // duplicate
        if (checkedIndexes.includes(index)) {
          return true;
        }

        checkedIndexes.push(index);
      }
    }

    return false;
  }, []);

  const handleChangeInformation = (key, value) => {
    setInformation((previous) => {
      const newData = { ...previous, [key]: value };

      return newData;
    });
  };

  const handleChangeColumnData = useCallback((key, value, columnId) => {
    setAllColumnsData((previous) => {
      const newAllColumnsData = previous.map((row) => {
        const newRow = { ...row };
        if (row.columnId === columnId) {
          newRow[key] = value;
        }

        return newRow;
      });

      return newAllColumnsData;
    });
  }, []);

  const handleCancel = () => {
    setInformation(savedInformation);
    setAllColumnsData(savedAllColumnsData);
    setAllGroupByData(savedAllGroupByData);
    setAllFunctionData(savedAllFunctionData);
    setIsOnModify(false);
  };

  const handleAddRow = () => {
    const columnId = uniqueId('column-');
    const row = {
      columnId,
      displayName: '',
      displayWidth: '300',
      keyName: '',
      fieldKind: '',
      orderIndex: '',
    };
    allColumnsData.push(row);
    setAllColumnsData([...allColumnsData]);
  };

  const handleRemoveRow = useCallback(
    (columnId) => {
      setAllColumnsData((previous) => {
        const newAllColumnsData = previous
          .filter((row) => {
            return row.columnId !== columnId;
          })
          .map((row) => {
            return { ...row };
          });

        // max page should at least is 1
        const maxPage = Math.ceil(newAllColumnsData.length / limit) || 1;
        if (page > maxPage) {
          setPage(maxPage);
        }

        return newAllColumnsData;
      });
    },
    [limit, page],
  );

  const handleModify = () => {
    if (!information.name.trim()) {
      showErrorMessage({ message: trans('error:PleaseFillUpName') });
      return;
    }

    const hasInvalid = allColumnsData.some((column) => {
      return (
        !checkColumnIndex(column.orderIndex) ||
        !checkColumnName(column.displayName) ||
        !checkColumnWidth(column.displayWidth) ||
        !checkColumnField(column.keyName)
      );
    });

    if (hasInvalid) {
      return;
    }

    for (let index = 0; index < allColumnsData.length; index += 1) {
      if (allColumnsData[index].fieldKind === '') {
        showErrorMessage({ message: trans('error:PleaseSelectColumnType') });
        return;
      }
    }

    if (isColumnIndexDuplicate(allColumnsData)) {
      showErrorMessage({ message: trans('error:ColumnIndexDuplicate') });
      return;
    }

    const columnFields = allColumnsData.map((columnData) => {
      return columnData.keyName;
    });
    for (let index = 0; index < allGroupByData.length; index += 1) {
      const trimmedKeyName = allGroupByData[index].keyName.trim();
      if (!trimmedKeyName) {
        showErrorMessage({ message: trans('error:InvalidGroupByField') });
        return;
      }

      if (!columnFields.includes(trimmedKeyName)) {
        showErrorMessage({ message: trans('error:InvalidGroupByField') });
        return;
      }
    }

    for (let index = 0; index < allFunctionData.length; index += 1) {
      if (allFunctionData[index].aggregateKind === '') {
        showErrorMessage({ message: trans('error:PleaseSelectFunction') });
        return;
      }

      const trimmedKeyName = allFunctionData[index].keyName.trim();
      if (!trimmedKeyName) {
        showErrorMessage({ message: trans('error:InvalidFunctionField') });
        return;
      }

      if (!columnFields.includes(trimmedKeyName)) {
        showErrorMessage({ message: trans('error:InvalidFunctionField') });
        return;
      }
    }

    const columns = allColumnsData.map((columnData) => {
      const newColumn = {};
      for (const prop in columnData) {
        if (prop !== 'columnId') {
          if (prop === 'orderIndex') {
            newColumn[prop] = Number(columnData[prop]);
          } else if (prop === 'displayWidth') {
            if (typeof columnData[prop] === 'string') {
              newColumn[prop] =
                columnData[prop].trim() === '' ? 300 : Number(columnData[prop]);
            } else {
              newColumn[prop] = columnData[prop];
            }
          } else {
            newColumn[prop] = columnData[prop].trim();
          }
        }
      }

      return newColumn;
    });

    const requestData = {
      name: information.name.trim(),
      memo: information.memo.trim(),
    };
    if (canUpdateColumn) {
      requestData.fields = columns;
      requestData.groups = allGroupByData.map((groupByData) => {
        return { keyName: groupByData.keyName.trim() };
      });
      requestData.aggregates = allFunctionData.map((functionData) => {
        return {
          aggregateKind: functionData.aggregateKind,
          keyName: functionData.keyName.trim(),
        };
      });
    }
    handleApiResponse(modifyReport(id, requestData), () => {
      setIsOnModify(false);
      handleGetReportInfo();
      showSuccessMessage({ message: trans('success:ModifyReport') });
    });
  };

  const tableColumns = useMemo(() => {
    if (!canUpdateColumn || !isOnModify) {
      return [
        {
          title: trans('Name'),
          fieldName: 'displayName',
        },
        {
          title: trans('Width'),
          fieldName: 'displayWidth',
        },
        {
          title: trans('Field'),
          fieldName: 'keyName',
        },
        {
          title: trans('Type'),
          fieldName: 'fieldKind',
        },
        {
          title: trans('Index'),
          fieldName: 'orderIndex',
        },
      ];
    }

    return [
      {
        title: `${trans('Name')} *`,
        fieldName: 'displayName',
        render(value, { columnId }) {
          return (
            <Input
              borderRadiusS
              type='text'
              value={value || ''}
              onChange={(event) => {
                handleChangeColumnData(
                  'displayName',
                  event.target.value,
                  columnId,
                );
              }}
            />
          );
        },
      },
      {
        title: trans('Width'),
        fieldName: 'displayWidth',
        render(value, { columnId }) {
          return (
            <Input
              borderRadiusS
              type='text'
              value={value || ''}
              onChange={(event) => {
                handleChangeColumnData(
                  'displayWidth',
                  event.target.value,
                  columnId,
                );
              }}
            />
          );
        },
      },
      {
        title: `${trans('Field')} *`,
        fieldName: 'keyName',
        render(value, { columnId }) {
          return (
            <Input
              borderRadiusS
              type='text'
              value={value || ''}
              maxLength='50'
              onChange={(event) => {
                handleChangeColumnData('keyName', event.target.value, columnId);
              }}
            />
          );
        },
      },
      {
        title: `${trans('Type')} *`,
        fieldName: 'fieldKind',
        render(value, { columnId }) {
          return (
            <Select
              options={REPORT_COLUMNS_TYPE}
              selected={value || ''}
              onSelect={(id) => {
                handleChangeColumnData('fieldKind', id, columnId);
              }}
            />
          );
        },
      },
      {
        title: `${trans('Index')} *`,
        fieldName: 'orderIndex',
        render(value, { columnId }) {
          return (
            <Input
              borderRadiusS
              type='text'
              value={value || ''}
              onChange={(event) => {
                handleChangeColumnData(
                  'orderIndex',
                  event.target.value,
                  columnId,
                );
              }}
            />
          );
        },
      },
      {
        title: trans('Action'),
        custom: true,
        width: '10%',
        align: 'center',
        render({ columnId }) {
          return (
            <IconButton
              onClick={() => {
                handleRemoveRow(columnId);
              }}
            >
              <MdOutlineRemoveCircleOutline />
            </IconButton>
          );
        },
      },
    ];
  }, [
    canUpdateColumn,
    isOnModify,
    trans,
    handleChangeColumnData,
    handleRemoveRow,
  ]);

  useEffect(() => {
    setTotal(allColumnsData.length);
  }, [allColumnsData]);

  useEffect(() => {
    const start = (page - 1) * limit;
    const end = page * limit;
    const tmpListData = allColumnsData.slice(start, end);
    setCurrentColumnsData(tmpListData);
  }, [allColumnsData, page, limit]);

  useEffect(() => {
    setPage(1);
  }, [limit]);

  useEffect(() => {
    if (total / limit > 1) {
      setPage(Math.ceil(total / limit));
    }
  }, [total, limit]);

  useEffect(() => {
    handleGetReportInfo();
  }, [handleGetReportInfo]);

  return (
    <>
      <Section noPadding>
        <SectionHeader>
          <Heading1>{trans('ReportSetting')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse>
            <SectionBody noPadding>
              <FormItem>
                <Label htmlFor='name' required={isOnModify}>
                  {trans('Name')}
                </Label>
                <Input
                  id='name'
                  value={information.name}
                  maxLength='128'
                  onChange={(event) => {
                    handleChangeInformation('name', event.target.value);
                  }}
                  disabled={!isOnModify}
                />
              </FormItem>
              <FormItem>
                <Label htmlFor='memo'>{trans('Memo')}</Label>
                <Textarea
                  id='memo'
                  value={information.memo}
                  maxLength='200'
                  onChange={(event) => {
                    handleChangeInformation('memo', event.target.value);
                  }}
                  rows='4'
                  disabled={!isOnModify}
                />
              </FormItem>
            </SectionBody>
          </Section>

          {canUpdateColumn && (
            <>
              <Section backgroundReverse>
                <SectionHeader sticky backgroundReverse>
                  <Heading2>{trans('Columns')}</Heading2>
                  {isOnModify && (
                    <SectionToolbar>
                      <Button onClick={handleAddRow}>
                        <FaPlus />
                        {trans('button:CreateARow')}
                      </Button>
                    </SectionToolbar>
                  )}
                </SectionHeader>
                <SectionBody>
                  <Table
                    backgroundReverse
                    columns={tableColumns}
                    data={currentColumnsData}
                    currentPage={page}
                    limit={limit}
                    total={total}
                    onLimitChange={setLimit}
                    onPageChange={setPage}
                    translation={{
                      info: trans('table.info'),
                      empty: trans('table.empty'),
                    }}
                  />
                </SectionBody>
              </Section>

              <GroupBySection
                allGroupByData={allGroupByData}
                setAllGroupByData={setAllGroupByData}
                canUpdateColumn={canUpdateColumn}
                isOnModify={isOnModify}
              />

              <FunctionSection
                allFunctionData={allFunctionData}
                setAllFunctionData={setAllFunctionData}
                canUpdateColumn={canUpdateColumn}
                isOnModify={isOnModify}
              />
            </>
          )}

          <ButtonGroup alignRight>
            {isOnModify ? (
              <>
                <Button danger onClick={handleCancel}>
                  {trans('button:Cancel')}
                </Button>
                <Button success onClick={handleModify}>
                  {trans('button:Save')}
                </Button>
              </>
            ) : (
              <>
                <Button danger onClick={() => navigate(-1)}>
                  {trans('button:Back')}
                </Button>
                {(userInformation.permissions.includes('report.update') ||
                  canUpdateColumn) && (
                  <Button
                    warning
                    onClick={() => {
                      setIsOnModify(true);
                    }}
                  >
                    {trans('button:Modify')}
                  </Button>
                )}
              </>
            )}
          </ButtonGroup>
        </SectionBody>
      </Section>
    </>
  );
};

const GroupBySection = ({
  allGroupByData,
  setAllGroupByData,
  canUpdateColumn,
  isOnModify,
}) => {
  const { t: trans } = useTranslation();
  const [currentColumnsData, setCurrentColumnsData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);

  const handleAddRow = () => {
    setAllGroupByData((previous) => {
      const row = {
        rowId: uniqueId('row-'),
        keyName: '',
      };

      return [...previous, row];
    });
  };

  const handleRemoveRow = useCallback(
    (rowId) => {
      setAllGroupByData((previous) => {
        const newAllGroupByData = previous
          .filter((row) => {
            return row.rowId !== rowId;
          })
          .map((row) => {
            return { ...row };
          });

        // max page should at least is 1
        const maxPage = Math.ceil(newAllGroupByData.length / limit) || 1;
        if (page > maxPage) {
          setPage(maxPage);
        }

        return newAllGroupByData;
      });
    },
    [setAllGroupByData, limit, page],
  );

  const handleChangeRowData = useCallback(
    (key, value, rowId) => {
      setAllGroupByData((previous) => {
        const newAllGroupByData = previous.map((row) => {
          const newRow = { ...row };
          if (row.rowId === rowId) {
            newRow[key] = value;
          }

          return newRow;
        });

        return newAllGroupByData;
      });
    },
    [setAllGroupByData],
  );

  const tableColumns = useMemo(() => {
    if (!canUpdateColumn || !isOnModify) {
      return [
        {
          title: trans('Field'),
          fieldName: 'keyName',
        },
      ];
    }

    return [
      {
        title: `${trans('Field')}`,
        fieldName: 'keyName',
        render(value, { rowId }) {
          return (
            <Input
              borderRadiusS
              type='text'
              value={value || ''}
              onChange={(event) => {
                handleChangeRowData('keyName', event.target.value, rowId);
              }}
            />
          );
        },
      },
      {
        title: trans('Action'),
        custom: true,
        width: '10%',
        align: 'center',
        render({ rowId }) {
          return (
            <IconButton
              onClick={() => {
                handleRemoveRow(rowId);
              }}
            >
              <MdOutlineRemoveCircleOutline />
            </IconButton>
          );
        },
      },
    ];
  }, [
    trans,
    canUpdateColumn,
    isOnModify,
    handleChangeRowData,
    handleRemoveRow,
  ]);

  useEffect(() => {
    setTotal(allGroupByData.length);
  }, [allGroupByData]);

  useEffect(() => {
    const start = (page - 1) * limit;
    const end = page * limit;
    const tmpListData = allGroupByData.slice(start, end);
    setCurrentColumnsData(tmpListData);
  }, [allGroupByData, page, limit]);

  useEffect(() => {
    setPage(1);
  }, [limit]);

  useEffect(() => {
    if (total / limit > 1) {
      setPage(Math.ceil(total / limit));
    }
  }, [total, limit]);

  return (
    <Section backgroundReverse>
      <SectionHeader sticky backgroundReverse>
        <Heading2>{trans('GroupBy')}</Heading2>
        {isOnModify && (
          <SectionToolbar>
            <Button onClick={handleAddRow}>
              <FaPlus />
              {trans('button:CreateARow')}
            </Button>
          </SectionToolbar>
        )}
      </SectionHeader>
      <SectionBody>
        <Table
          backgroundReverse
          columns={tableColumns}
          data={currentColumnsData}
          currentPage={page}
          limit={limit}
          total={total}
          onLimitChange={setLimit}
          onPageChange={setPage}
          translation={{
            info: trans('table.info'),
            empty: trans('table.empty'),
          }}
        />
      </SectionBody>
    </Section>
  );
};

const FunctionSection = ({
  allFunctionData,
  setAllFunctionData,
  canUpdateColumn,
  isOnModify,
}) => {
  const { t: trans } = useTranslation();
  const [aggregateList, setAggregateList] = useState([]);
  const [currentColumnsData, setCurrentColumnsData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);

  const handleAddRow = () => {
    setAllFunctionData((previous) => {
      const row = {
        rowId: uniqueId('row-'),
        aggregateKind: '',
        aggregateName: '',
        keyName: '',
      };

      return [...previous, row];
    });
  };

  const handleRemoveRow = useCallback(
    (rowId) => {
      setAllFunctionData((previous) => {
        const newAllFunctionData = previous
          .filter((row) => {
            return row.rowId !== rowId;
          })
          .map((row) => {
            return { ...row };
          });

        // max page should at least is 1
        const maxPage = Math.ceil(newAllFunctionData.length / limit) || 1;
        if (page > maxPage) {
          setPage(maxPage);
        }

        return newAllFunctionData;
      });
    },
    [setAllFunctionData, limit, page],
  );

  const handleChangeRowData = useCallback(
    (key, value, rowId) => {
      setAllFunctionData((previous) => {
        const newAllFunctionData = previous.map((row) => {
          const newRow = { ...row };
          if (row.rowId === rowId) {
            newRow[key] = value;
          }

          return newRow;
        });

        return newAllFunctionData;
      });
    },
    [setAllFunctionData],
  );

  const tableColumns = useMemo(() => {
    if (!canUpdateColumn || !isOnModify) {
      return [
        {
          title: trans('Function'),
          fieldName: 'aggregateName',
        },
        {
          title: trans('Field'),
          fieldName: 'keyName',
        },
      ];
    }

    return [
      {
        title: `${trans('Function')}`,
        fieldName: 'aggregateKind',
        render(value, { rowId }) {
          return (
            <Select
              fullWidth
              options={aggregateList}
              selected={value || ''}
              onSelect={(id) => {
                handleChangeRowData('aggregateKind', id, rowId);
              }}
            />
          );
        },
      },
      {
        title: `${trans('Field')}`,
        fieldName: 'keyName',
        render(value, { rowId }) {
          return (
            <Input
              borderRadiusS
              type='text'
              value={value || ''}
              onChange={(event) => {
                handleChangeRowData('keyName', event.target.value, rowId);
              }}
            />
          );
        },
      },
      {
        title: trans('Action'),
        custom: true,
        width: '10%',
        align: 'center',
        render({ rowId }) {
          return (
            <IconButton
              onClick={() => {
                handleRemoveRow(rowId);
              }}
            >
              <MdOutlineRemoveCircleOutline />
            </IconButton>
          );
        },
      },
    ];
  }, [
    trans,
    canUpdateColumn,
    isOnModify,
    aggregateList,
    handleChangeRowData,
    handleRemoveRow,
  ]);

  useEffect(() => {
    setTotal(allFunctionData.length);
  }, [allFunctionData]);

  useEffect(() => {
    const start = (page - 1) * limit;
    const end = page * limit;
    const tmpListData = allFunctionData.slice(start, end);
    setCurrentColumnsData(tmpListData);
  }, [allFunctionData, page, limit]);

  useEffect(() => {
    setPage(1);
  }, [limit]);

  useEffect(() => {
    if (total / limit > 1) {
      setPage(Math.ceil(total / limit));
    }
  }, [total, limit]);

  useEffect(() => {
    handleApiResponse(getAggregateList(), (response) => {
      const { list } = response.data.data;
      setAggregateList(
        list.map((aggregate) => {
          return { id: aggregate.kind, text: aggregate.aggregate };
        }),
      );
    });
  }, []);

  return (
    <Section backgroundReverse>
      <SectionHeader sticky backgroundReverse>
        <Heading2>{trans('Function')}</Heading2>
        {isOnModify && (
          <SectionToolbar>
            <Button onClick={handleAddRow}>
              <FaPlus />
              {trans('button:CreateARow')}
            </Button>
          </SectionToolbar>
        )}
      </SectionHeader>
      <SectionBody>
        <Table
          backgroundReverse
          columns={tableColumns}
          data={currentColumnsData}
          currentPage={page}
          limit={limit}
          total={total}
          onLimitChange={setLimit}
          onPageChange={setPage}
          translation={{
            info: trans('table.info'),
            empty: trans('table.empty'),
          }}
        />
      </SectionBody>
    </Section>
  );
};

export default ReportSetting;
