import { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import {
  AUTOMATED_REPORT_TYPE,
  AUTOMATED_REPORT_SEND_WEEK,
  AUTOMATED_REPORT_SEND_HOUR,
  AUTOMATED_REPORT_SEND_DAY,
} from '../../../provider/constant';
import { getTimezoneList } from '../../../module/coupon';
import { getBottlerSimpleList } from '../../../module/bottler';
import { getSalesCenterSimpleList } from '../../../module/salesCenter';
import {
  getAutomatedReportDetails,
  modifyAutomatedReport,
} from '../../../module/report';
import { showErrorMessage, showSuccessMessage } from '../../../module/message';
import handleApiResponse from '../../../utils/api/handleApiResponse';
import { Heading1 } from '../../../components/heading';
import {
  Section,
  SectionBody,
  SectionHeader,
} from '../../../components/section';
import { Grid, Column } from '../../../components/grid';
import {
  FormItem,
  FormGroupItem,
  Input,
  Label,
} from '../../../components/form';
import { Select } from '../../../components/Select';
import { Button, ButtonGroup } from '../../../components/button';
import { Tags } from '../../../components/tagify';

const AutomatedReportDetail = () => {
  const { t: trans } = useTranslation();
  const { automatedReportId } = useParams();
  const navigate = useNavigate();
  const [timezoneList, setTimezoneList] = useState([]);
  const tagifyRef = useRef();
  const [bottlerList, setBottlerList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [bottlerSimpleList, setBottlerSimpleList] = useState([]);
  const [fetchingSalesCenterList, setFetchingSalesCenterList] = useState(false);
  const [salesCenterList, setSalesCenterList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [salesCenterSimpleList, setSalesCenterSimpleList] = useState([]);
  const [data, setData] = useState({
    name: '',
    timezoneId: '',
    bottlerId: '',
    saleCenterId: '',
    reportType: '',
    sendTimeHour: '00',
    sendTimeMinute: '00',
    sendWeek: '',
    sendDay: '',
    contactMails: [],
  });
  const [savedData, setSavedData] = useState(data);
  const [isOnModify, setIsOnModify] = useState(false);

  const handleGetAutomatedReportDetails = useCallback(() => {
    getAutomatedReportDetails(automatedReportId)
      .then((response) => {
        if (response) {
          const { data } = response.data;
          const defaultData = {
            name: data.name,
            timezoneId: data.timezoneId,
            bottlerId: data.bottlerId,
            saleCenterId: data.saleCenterId,
            reportType: data.reportType,
            sendTimeHour: data.sendTime.split(':')[0],
            sendTimeMinute: data.sendTime.split(':')[1],
            sendWeek: data.sendWeek,
            sendDay: data.sendDay,
            contactMails: data.contactMails,
          };
          setData({ ...defaultData });
          setSavedData({ ...defaultData });
        }
      })
      .catch((error) => {
        showErrorMessage({ title: error.name, message: error.message });
      });
  }, [automatedReportId]);

  const handleGetBottlerList = useCallback((currentPage) => {
    const parameters = {
      page: currentPage,
      limit: 100,
    };
    handleApiResponse(getBottlerSimpleList(parameters), (response) => {
      const { list, total } = response.data.data;

      setBottlerList((previous) => {
        const newBottlerList = [...previous.data, ...list];

        return {
          data: newBottlerList,
          currentPage: currentPage + 1,
          lastPage: Math.ceil(total / 100),
        };
      });
    });
  }, []);

  const handleGetSalesCenterList = useCallback((bottlerId, currentPage) => {
    const parameters = {
      page: currentPage,
    };
    handleApiResponse(
      getSalesCenterSimpleList(bottlerId, parameters),
      (response) => {
        const { list, limit, total } = response.data.data;

        setSalesCenterList((previous) => {
          const newSalesCenterList = [...previous.data, ...list];

          return {
            data: newSalesCenterList,
            currentPage: currentPage + 1,
            lastPage: Math.ceil(total / limit),
          };
        });
        setFetchingSalesCenterList(false);
      },
    );
  }, []);

  const handleChangeValue = (key, value) => {
    setData((previous) => {
      return { ...previous, [key]: value };
    });
  };

  const resetSalesCenterState = () => {
    setSalesCenterList({
      data: [],
      currentPage: 1,
      lastPage: 1,
    });
    setSalesCenterSimpleList([]);
    setData((previous) => {
      return {
        ...previous,
        saleCenterId: '',
      };
    });
  };

  const handleCancel = () => {
    setData({ ...savedData, contactMails: [...savedData.contactMails] });
    tagifyRef.current.removeAllTags();
    tagifyRef.current.setReadonly(true);

    setIsOnModify(false);
  };

  const handleModify = () => {
    if (!data.name.trim()) {
      showErrorMessage({ message: trans('error:PleaseFillUpName') });
      return;
    }

    if (!data.timezoneId) {
      showErrorMessage({ message: trans('error:PleaseSelectTimezone') });
      return;
    }

    if (!data.bottlerId) {
      showErrorMessage({ message: trans('error:PleaseSelectABottler') });
      return false;
    }

    if (!data.reportType) {
      showErrorMessage({ message: trans('error:PleaseSelectReportType') });
      return;
    }

    if ((data.reportType === 2 || data.reportType === 4) && !data.sendWeek) {
      showErrorMessage({ message: trans('error:PleaseSelectSendTime') });
      return;
    }

    if (data.reportType === 3 && !data.sendDay) {
      showErrorMessage({ message: trans('error:PleaseSelectSendTime') });
      return;
    }

    if (!data.sendTimeHour || !data.sendTimeMinute) {
      showErrorMessage({ message: trans('error:PleaseSelectSendTime') });
      return;
    }

    if (data.contactMails.length === 0) {
      showErrorMessage({
        message: trans('error:PleaseFillUpAtLeastOneEmailAddress'),
      });
      return false;
    }

    const requestData = {
      name: data.name.trim(),
      timezoneId: data.timezoneId,
      bottlerId: data.bottlerId,
      saleCenterId: data.saleCenterId,
      reportType: data.reportType,
      sendTime: `${data.sendTimeHour}:${data.sendTimeMinute}`,
      contactMails: data.contactMails,
    };
    if (data.reportType === 2 || data.reportType === 4) {
      requestData.sendWeek = data.sendWeek;
    }
    if (data.reportType === 3) {
      requestData.sendDay = data.sendDay;
    }

    handleApiResponse(
      modifyAutomatedReport(automatedReportId, requestData),
      (response) => {
        if (response) {
          tagifyRef.current.setReadonly(true);
          setIsOnModify(false);
          setSavedData(data);
          showSuccessMessage({
            message: trans('success:ModifyAutomatedReport'),
          });
        }
      },
    );
  };

  useEffect(() => {
    handleApiResponse(getTimezoneList({ status: 1 }), (response) => {
      const { data } = response.data;

      setTimezoneList(
        data.map(({ id, name, offset }) => {
          return {
            id,
            text: `${name} ${offset}`,
          };
        }),
      );
    });
  }, []);

  useEffect(() => {
    if (bottlerList.currentPage <= bottlerList.lastPage) {
      handleGetBottlerList(bottlerList.currentPage);
    } else {
      setBottlerSimpleList(
        bottlerList.data.map((bottler) => {
          return { id: bottler.id, text: bottler.name };
        }),
      );
    }
  }, [
    handleGetBottlerList,
    bottlerList.data,
    bottlerList.currentPage,
    bottlerList.lastPage,
  ]);

  useEffect(() => {
    if (!data.bottlerId) return;
    if (fetchingSalesCenterList) return;

    if (salesCenterList.currentPage <= salesCenterList.lastPage) {
      handleGetSalesCenterList(data.bottlerId, salesCenterList.currentPage);
      setFetchingSalesCenterList(true);
    } else {
      setSalesCenterSimpleList(
        salesCenterList.data.map((salesCenter) => {
          return { id: salesCenter.id, text: salesCenter.name };
        }),
      );
    }
  }, [
    data.bottlerId,
    fetchingSalesCenterList,
    handleGetSalesCenterList,
    salesCenterList.data,
    salesCenterList.currentPage,
    salesCenterList.lastPage,
  ]);

  useEffect(() => {
    handleGetAutomatedReportDetails();
  }, [handleGetAutomatedReportDetails]);

  useEffect(() => {
    if (tagifyRef?.current?.getTagElms()?.length === 0) {
      tagifyRef.current.addTags(data.contactMails);
      tagifyRef.current.setReadonly(true);
    }
  }, [data.contactMails]);

  return (
    <Section noPadding>
      <SectionHeader>
        <Heading1>
          {trans('AutomatedReport')} - {data.name}
        </Heading1>
      </SectionHeader>
      <SectionBody>
        <Section backgroundReverse>
          <SectionBody noPadding>
            <Grid columns={12}>
              <Column desktop={6}>
                <FormItem>
                  <Label htmlFor='name' required>
                    {trans('Name')}
                  </Label>
                  <Input
                    id='name'
                    type='text'
                    value={data.name}
                    onChange={(event) =>
                      handleChangeValue('name', event.target.value)
                    }
                    maxLength='100'
                    disabled={!isOnModify}
                  />
                </FormItem>
              </Column>
              <Column desktop={6}>
                <FormItem>
                  <Label htmlFor='timezone' required>
                    {trans('Timezone')}
                  </Label>
                  <Select
                    fullWidth
                    options={timezoneList}
                    selected={data.timezoneId}
                    onSelect={(id) => {
                      handleChangeValue('timezoneId', id);
                    }}
                    disabled={!isOnModify}
                  />
                </FormItem>
              </Column>
              <Column desktop={6}>
                <FormItem>
                  <Label htmlFor='bottler' required>
                    {trans('Bottler')}
                  </Label>
                  <Select
                    fullWidth
                    options={bottlerSimpleList}
                    selected={data.bottlerId}
                    onSelect={(id) => {
                      handleChangeValue('bottlerId', id);
                      resetSalesCenterState();
                    }}
                    disabled={!isOnModify}
                  />
                </FormItem>
              </Column>
              <Column desktop={6}>
                <FormItem>
                  <Label htmlFor='salesCenter'>{trans('SalesCenter')}</Label>
                  <Select
                    fullWidth
                    allowClear={true}
                    options={salesCenterSimpleList}
                    selected={data.saleCenterId}
                    onSelect={(id) => {
                      handleChangeValue('saleCenterId', id);
                    }}
                    disabled={!isOnModify}
                  />
                </FormItem>
              </Column>
              <Column desktop={6}>
                <FormItem>
                  <Label htmlFor='reportType' required>
                    {trans('ReportType')}
                  </Label>
                  <Select
                    fullWidth
                    options={AUTOMATED_REPORT_TYPE}
                    selected={data.reportType}
                    onSelect={(id) => {
                      handleChangeValue('reportType', id);
                    }}
                    disabled={!isOnModify}
                  />
                </FormItem>
              </Column>
              <Column desktop={6}>
                <FormGroupItem required>
                  <legend>{trans('SendTime')}</legend>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {(data.reportType === 2 || data.reportType === 4) && (
                      <>
                        <Label htmlFor='day'>{trans('Day')}:&nbsp;</Label>
                        <Select
                          fullWidth
                          allowSearch={false}
                          options={AUTOMATED_REPORT_SEND_WEEK}
                          selected={data.sendWeek}
                          onSelect={(id) => {
                            handleChangeValue('sendWeek', id);
                          }}
                          disabled={!isOnModify}
                        />
                        &nbsp;-&nbsp;
                      </>
                    )}
                    {data.reportType === 3 && (
                      <>
                        <Label htmlFor='date'>{trans('Date')}:&nbsp;</Label>
                        <Select
                          fullWidth
                          allowSearch={false}
                          options={AUTOMATED_REPORT_SEND_DAY}
                          selected={data.sendDay}
                          onSelect={(id) => {
                            handleChangeValue('sendDay', id);
                          }}
                          disabled={!isOnModify}
                        />
                        &nbsp;-&nbsp;
                      </>
                    )}
                    <Label htmlFor='time'>{trans('Time')}(HH/MM):&nbsp;</Label>
                    <Select
                      fullWidth
                      allowSearch={false}
                      options={AUTOMATED_REPORT_SEND_HOUR}
                      selected={data.sendTimeHour}
                      onSelect={(id) => {
                        handleChangeValue('sendTimeHour', id);
                      }}
                      disabled={!isOnModify}
                    />
                    &nbsp;:&nbsp;
                    <Select
                      fullWidth
                      allowSearch={false}
                      options={[
                        { id: '00', text: '00' },
                        { id: '30', text: '30' },
                      ]}
                      selected={data.sendTimeMinute}
                      onSelect={(id) => {
                        handleChangeValue('sendTimeMinute', id);
                      }}
                      disabled={!isOnModify}
                    />
                  </div>
                </FormGroupItem>
              </Column>
              <Column desktop={6}>
                <FormItem>
                  <Label htmlFor='contactEmail' required>
                    {trans('ContactEmail')}
                  </Label>
                  <Tags
                    ref={tagifyRef}
                    onChange={(event) => {
                      handleChangeValue(
                        'contactMails',
                        event.detail.tagify.getCleanValue().map((item) => {
                          return item.value;
                        }),
                      );
                    }}
                    settings={{
                      pattern:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/,
                    }}
                  />
                </FormItem>
              </Column>
            </Grid>
          </SectionBody>
          <ButtonGroup alignRight>
            {isOnModify ? (
              <>
                <Button danger onClick={handleCancel}>
                  {trans('button:Cancel')}
                </Button>
                <Button success onClick={handleModify}>
                  {trans('button:Save')}
                </Button>
              </>
            ) : (
              <>
                <Button onClick={() => navigate(-1)}>
                  {trans('button:Back')}
                </Button>
                <Button
                  warning
                  onClick={() => {
                    setIsOnModify(true);
                    tagifyRef.current.setReadonly(false);
                  }}
                >
                  {trans('button:Modify')}
                </Button>
              </>
            )}
          </ButtonGroup>
        </Section>
      </SectionBody>
    </Section>
  );
};

export default AutomatedReportDetail;
