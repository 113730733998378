import styled, { css } from 'styled-components';
import backgroundColor from '../../styles/mixin/backgroundColor';
import colorOnBackground from '../../styles/mixin/colorOnBackground';

const Button = styled.button.attrs(() => ({
  primary: true,
}))`
  display: flex;
  align-items: center;
  border: none;
  font-size: var(--font-button);
  line-height: var(--font-body1);
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  border-radius: var(--border-radius-l);
  padding: 10px var(--spacing-s);
  text-transform: uppercase;

  > svg {
    margin-right: calc(var(--spacing-s) - 0.25rem);
    font-size: var(--font-body1);
  }

  &:hover {
    ${({ disabled }) => {
      return (
        !disabled &&
        css`
          transition: 0.3s;
          box-shadow: var(--box-shadow);
          opacity: 0.9;
        `
      );
    }}
  }

  ${backgroundColor}
  ${colorOnBackground}

  ${({ secondary }) =>
    secondary &&
    css`
      background: var(--color-background2);
      color: var(--font-on-background);
    `}

  ${({ reverse }) =>
    reverse &&
    css`
      background: var(--color-background1);
      color: var(--font-on-background);
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  ${({ iconOnly }) =>
    iconOnly &&
    css`
      padding: 10px;

      > svg {
        margin-right: 0;
      }
    `}

  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

  ${({ alignCenter }) => alignCenter && 'justify-content: center;'}
`;

export default Button;
