const KEY_CODE = {
  ENTER: 13,
  ESCAPE: 27,
  UP: 38,
  DOWN: 40,
  END: 35,
  HOME: 36,
};

export { KEY_CODE };
