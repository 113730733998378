import { css } from 'styled-components';

const scrollbar = () => {
  return css`
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-scroll-bar);
      background-clip: padding-box;
      border: 4px solid transparent;
      border-radius: var(--border-radius);
    }
  `;
};

export default scrollbar;
