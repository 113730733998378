import { useContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { MdMoreHoriz, MdDelete, MdSend } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import AuthenticateContext from '../../provider/context/authenticate.context';
import {
  checkNumberRule,
  isInteger,
  isValidEmail,
  isValidTelephone,
} from '../../utils/check/index';
import { Button, ButtonGroup, IconButton } from '../../components/button';
import { DatetimePickerV2 } from '../../components/datetimePicker';
import { FormItem, Input, Label, Textarea } from '../../components/form';
import Heading1 from '../../components/heading/Heading1';
import Heading2 from '../../components/heading/Heading2';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionToolbar,
  SectionToolbarItem,
} from '../../components/section';
import Dropdown, {
  DropdownItem,
  DropdownLinkOption,
  DropdownButtonOption,
} from '../../components/dropdown/index';
import { Table } from '../../components/table';
import { Select } from '../../components/Select/index';
import { useNavigate, useParams } from 'react-router-dom';
import handleApiResponse from '../../utils/api/handleApiResponse';
import {
  assignDiscountCode,
  getCoupon,
  getDiscountCodeList,
  getTimezoneList,
  modifyCouponInfo,
  exportDiscountCodeLog,
  checkDiscountCodeLogStatus,
  downdloadDiscountCodeTemplate,
  ImportDiscountCodeList,
  deleteMemberCoupon,
  resendRedeemCode,
  getPromotionsAnalyze,
} from '../../module/coupon';
import { getTerminalGroupList } from '../../module/terminal';
import { Column, Grid } from '../../components/grid';
import { useLimitChange, usePageChange } from '../../components/table/hooks';
import { TitleModal, StateModal } from '../../components/modal';
import { FileDropzone } from '../../components/dropzone';
import { showErrorMessage, showSuccessMessage } from '../../module/message';
import {
  COUPON_CATEGORY,
  COUPON_TYPE,
  DISCOUNT_CODE,
  DISCOUNT_CODE_STATUS,
} from '../../provider/constant';

const CouponDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { userInformation } = useContext(AuthenticateContext);
  const [isOnModify, setIsOnModify] = useState(false);
  const [terminalGrouptData, setTerminalGrouptData] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [couponDetails, setCouponDetails] = useState({
    title: '',
    total: '',
    startDate: '',
    endDate: '',
    couponType: 1,
    couponCategory: 1,
    remarks: '',
    // 減免金額
    discountAmount: '',
    // 折扣價格百分比
    discountFactor: '',
    timezoneId: '',
    terminalGroup: [],
  });

  const couponCategoryOption = useMemo(() => {
    return COUPON_CATEGORY.map((status) => {
      return { id: status.id, text: t(status.text) };
    });
  }, [t]);

  const couponTypeOption = useMemo(() => {
    return COUPON_TYPE.map((status) => {
      return { id: status.id, text: t(status.text) };
    });
  }, [t]);

  const terminalGroupList = useMemo(() => {
    return terminalGrouptData.map((terminalGroup) => {
      return {
        id: terminalGroup.id,
        text: terminalGroup.name,
      };
    });
  }, [terminalGrouptData]);

  const handleChangeValue = (value, key, type = null) => {
    const newData = { ...couponDetails };
    if (key === 'terminalGroup') {
      if (type === 'delete') {
        const index = newData[key].indexOf(value);
        if (index > -1) {
          newData[key].splice(index, 1);
          newData[key] = [...newData[key]];
        }
      }

      if (type === 'add' && !newData[key].includes(value)) {
        newData[key] = [...newData[key], value];
      }
    } else {
      if (key === 'couponType' && value === 1) {
        // couponType為1(固定金額)
        newData.discountAmount = '';
        newData.minAmount = '';
      } else if (key === 'couponType' && value === 2) {
        // couponType為2(百分比)
        newData.discountFactor = '';
        newData.minAmount = '0';
      } else if (key === 'couponType' && value === 3) {
        // couponType為3(免費贈送)
        newData.discountFactor = '100';
        newData.minAmount = '0';
      }

      newData[key] = value;
    }

    setCouponDetails(newData);
  };

  const handleModify = () => {
    if (!couponDetails.title.trim()) {
      showErrorMessage({ message: t('error:PleaseFillUpName') });
      return;
    }

    if (!couponDetails.timezoneId) {
      showErrorMessage({ message: t('error:PleaseSelectTimezone') });
      return;
    }

    if (!couponDetails.couponCategory) {
      showErrorMessage({ message: t('error:PleaseSelectCouponCategory') });
      return;
    }

    if (!couponDetails.couponType) {
      showErrorMessage({ message: t('error:PleaseSelectCouponType') });
      return;
    }

    if (
      couponDetails.couponType === 1 &&
      !couponDetails.discountAmount.toString().trim()
    ) {
      showErrorMessage({ message: t('error:PleaseFillUpDiscountValue') });
      return;
    }

    if (
      couponDetails.couponType === 1 &&
      isNaN(couponDetails.discountAmount.toString().trim())
    ) {
      showErrorMessage({
        message: t('error:DiscountValueMustBeNumber'),
      });
      return;
    }

    if (
      couponDetails.couponType === 1 &&
      checkNumberRule(couponDetails.discountAmount.toString().trim())
    ) {
      showErrorMessage({
        message: t('error:DiscountValueOnlySupportsTwoDecimalPlaces'),
      });
      return;
    }

    if (
      couponDetails.couponType === 2 &&
      !couponDetails.discountFactor.toString().trim()
    ) {
      showErrorMessage({ message: t('error:PleaseFillUpDiscountValue') });
      return;
    }

    if (
      couponDetails.couponType === 2 &&
      isNaN(couponDetails.discountFactor.toString().trim())
    ) {
      showErrorMessage({
        message: t('error:DiscountValueMustBeNumber'),
      });
      return;
    }

    if (
      couponDetails.couponType === 2 &&
      !isInteger(couponDetails.discountFactor.toString().trim())
    ) {
      showErrorMessage({
        message: t('error:DiscountValueMustBeInteger'),
      });
      return;
    }

    if (!couponDetails.minAmount.toString().trim()) {
      showErrorMessage({ message: t('error:PleaseFillUpMinimumAmount') });
      return;
    }

    if (isNaN(couponDetails.minAmount.toString().trim())) {
      showErrorMessage({
        message: t('error:MinimumAmountMustBeNumber'),
      });
      return;
    }

    if (checkNumberRule(couponDetails.minAmount.toString().trim())) {
      showErrorMessage({
        message: t('error:MinimumAmountOnlySupportsTwoDecimalPlaces'),
      });
      return;
    }

    if (!couponDetails.startDate) {
      showErrorMessage({ message: t('error:PleaseSelectStartDate') });
      return;
    }

    if (!couponDetails.endDate) {
      showErrorMessage({ message: t('error:PleaseSelectEndDate') });
      return;
    }

    if (!couponDetails.validStartDate) {
      showErrorMessage({ message: t('error:PleaseSelectValidStartDate') });
      return;
    }

    if (!couponDetails.validEndDate) {
      showErrorMessage({ message: t('error:PleaseSelectValidEndDate') });
      return;
    }

    if (
      couponDetails.couponCategory === 1 &&
      !couponDetails.limitReceive.toString().trim()
    ) {
      showErrorMessage({
        message: t('error:PleaseFillUpLimitReceiveQuantity'),
      });
      return;
    }

    if (
      couponDetails.couponCategory === 1 &&
      isNaN(couponDetails.limitReceive.toString().trim())
    ) {
      showErrorMessage({
        message: t('error:LimitReceiveQuantityMustBeNumber'),
      });
      return;
    }

    if (
      couponDetails.couponCategory === 1 &&
      !isInteger(couponDetails.limitReceive.toString().trim())
    ) {
      showErrorMessage({
        message: t('error:LimitReceiveQuantityMustBeInteger'),
      });
      return;
    }

    if (!couponDetails.total.toString().trim()) {
      showErrorMessage({ message: t('error:PleaseFillUpQuantity') });
      return;
    }

    if (isNaN(couponDetails.total.toString().trim())) {
      showErrorMessage({
        message: t('error:QuantityMustBeNumber'),
      });
      return;
    }

    if (!isInteger(couponDetails.total.toString().trim())) {
      showErrorMessage({ message: t('error:QuantityMustBeInteger') });
      return;
    }

    const modifyData = {
      id: parseInt(id),
      title: couponDetails.title.trim(),
      timezoneId: couponDetails.timezoneId,
      couponCategory: couponDetails.couponCategory,
      couponType: couponDetails.couponType,
      discountAmount: +couponDetails.discountAmount * 100,
      discountFactor: +couponDetails.discountFactor,
      minAmount: +couponDetails.minAmount * 100,
      startDate: couponDetails.startDate,
      endDate: couponDetails.endDate,
      validStartDate: couponDetails.validStartDate,
      validEndDate: couponDetails.validEndDate,
      limitReceive: +couponDetails.limitReceive,
      total: +couponDetails.total,
    };
    if (couponDetails.terminalGroup) {
      modifyData.terminalGroup = couponDetails.terminalGroup;
    }

    if (couponDetails.remarks) {
      modifyData.remarks = couponDetails.remarks;
    }

    handleApiResponse(modifyCouponInfo(modifyData), () => {
      handGetCouponDetails();
      setIsOnModify(false);
      showSuccessMessage({ message: t('success:ModifyCoupon') });
    });
  };

  const handGetCouponDetails = useCallback(() => {
    handleApiResponse(getCoupon(id), (response) => {
      const { data } = response.data;
      setCouponDetails({
        ...data,
        discountAmount: data.discountAmount / 100,
        minAmount: data.minAmount / 100,
      });
    });
  }, [id]);

  // get coupon details
  useEffect(() => {
    handGetCouponDetails();
  }, [handGetCouponDetails]);

  // get timezone
  useEffect(() => {
    handleApiResponse(getTimezoneList({ status: 0 }), (response) => {
      const { data } = response.data;

      setTimezoneList(
        data.map(({ id, name, offset }) => {
          return {
            id,
            text: `${name} ${offset}`,
          };
        }),
      );
    });
  }, []);

  // get terminal group list
  useEffect(() => {
    const requestData = {
      page: 1,
      limit: 10,
    };

    handleApiResponse(getTerminalGroupList(requestData), (response) => {
      const { list } = response.data.data;
      setTerminalGrouptData(list);
    });
  }, []);

  return (
    <>
      <Section noPadding>
        <SectionHeader>
          <Heading1>{t('CouponDetails')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionHeader>
              <SectionToolbar>
                <SectionToolbarItem>
                  <Heading2>{t('BasicInformation')}</Heading2>
                </SectionToolbarItem>
              </SectionToolbar>
            </SectionHeader>
            <SectionBody>
              <Grid columns={2}>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='title' required={isOnModify}>
                      {t('Name')}
                    </Label>
                    <Input
                      id='title'
                      type='text'
                      value={couponDetails.title}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'title')
                      }
                      maxLength='64'
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='timezone' required={isOnModify}>
                      {t('Timezone')}
                    </Label>
                    <Select
                      fullWidth
                      options={timezoneList}
                      selected={couponDetails.timezoneId}
                      onSelect={(timezone) =>
                        handleChangeValue(timezone, 'timezoneId')
                      }
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='couponCategory' required={isOnModify}>
                      {t('CouponCategory')}
                    </Label>
                    <Select
                      fullWidth
                      allowSearch={false}
                      options={couponCategoryOption}
                      selected={couponDetails.couponCategory}
                      onSelect={(couponCategory) =>
                        handleChangeValue(couponCategory, 'couponCategory')
                      }
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='couponType' required={isOnModify}>
                      {t('CouponType')}
                    </Label>
                    <Select
                      fullWidth
                      allowSearch={false}
                      options={couponTypeOption}
                      selected={couponDetails.couponType}
                      onSelect={(couponType) =>
                        handleChangeValue(couponType, 'couponType')
                      }
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>

                {couponDetails.couponType === 1 && (
                  <Column desktop={1}>
                    <FormItem>
                      <Label htmlFor='discountAmount' required>
                        {t('DiscountValue')}
                      </Label>
                      <Input
                        type='text'
                        id='discountAmount'
                        value={couponDetails.discountAmount}
                        onChange={(event) =>
                          handleChangeValue(
                            event.target.value,
                            'discountAmount',
                          )
                        }
                        disabled={!isOnModify}
                      />
                    </FormItem>
                  </Column>
                )}
                {(couponDetails.couponType === 2 ||
                  couponDetails.couponType === 3) && (
                  <Column desktop={1}>
                    <FormItem>
                      <Label htmlFor='discountFactor' required>
                        {t('DiscountValue')}
                      </Label>
                      <Input
                        type='text'
                        id='discountFactor'
                        value={
                          couponDetails.couponType === 3
                            ? '100'
                            : couponDetails.discountFactor
                        }
                        onChange={(event) =>
                          handleChangeValue(
                            event.target.value,
                            'discountFactor',
                          )
                        }
                        disabled={!isOnModify || couponDetails.couponType === 3}
                      />
                    </FormItem>
                  </Column>
                )}

                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='minAmount' required={isOnModify}>
                      {t('RequiredMinimumAmount')}
                    </Label>
                    <Input
                      id='minAmount'
                      type='text'
                      value={couponDetails.minAmount}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'minAmount')
                      }
                      disabled={!isOnModify || couponDetails.couponType !== 1}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='startDate' equired={isOnModify}>
                      {t('StartDate')}
                    </Label>
                    <DatetimePickerV2
                      selected={couponDetails.startDate}
                      onConfirm={(startDate) => {
                        handleChangeValue(startDate, 'startDate');
                      }}
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='endDate' required={isOnModify}>
                      {t('EndDate')}
                    </Label>
                    <DatetimePickerV2
                      selected={couponDetails.endDate}
                      onConfirm={(endDate) => {
                        handleChangeValue(endDate, 'endDate');
                      }}
                      minDate={couponDetails.startDate}
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='validStartDate' required={isOnModify}>
                      {t('ValidStartDate')}
                    </Label>
                    <DatetimePickerV2
                      selected={couponDetails.validStartDate}
                      onConfirm={(validStartDate) => {
                        handleChangeValue(validStartDate, 'validStartDate');
                      }}
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='validEndDate' required={isOnModify}>
                      {t('ValidEndDate')}
                    </Label>
                    <DatetimePickerV2
                      selected={couponDetails.validEndDate}
                      onConfirm={(validEndDate) => {
                        handleChangeValue(validEndDate, 'validEndDate');
                      }}
                      minDate={couponDetails.validStartDate}
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                {couponDetails.couponCategory === 1 && (
                  <Column desktop={1}>
                    <FormItem>
                      <Label htmlFor='limitReceive' required={isOnModify}>
                        {t('LimitReceiveQuantity')}
                      </Label>
                      <Input
                        id='limitReceive'
                        type='text'
                        value={couponDetails.limitReceive}
                        onChange={(e) =>
                          handleChangeValue(e.target.value, 'limitReceive')
                        }
                        disabled={!isOnModify}
                      />
                    </FormItem>
                  </Column>
                )}
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='total' required={isOnModify}>
                      {t('Quantity')}
                    </Label>
                    <Input
                      id='total'
                      type='text'
                      value={couponDetails.total}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'total')
                      }
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='terminalGroup'>{t('TerminalGroup')}</Label>
                    <Select
                      id='terminalGroup'
                      multiple
                      fullWidth
                      options={terminalGroupList}
                      selected={couponDetails.terminalGroup}
                      onSelect={(terminalGroup) =>
                        handleChangeValue(terminalGroup, 'terminalGroup', 'add')
                      }
                      onDeselect={(terminalGroup) =>
                        handleChangeValue(
                          terminalGroup,
                          'terminalGroup',
                          'delete',
                        )
                      }
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
                <Column desktop={2}>
                  <FormItem>
                    <Label htmlFor='remarks'>{t('UsageInstructions')}</Label>
                    <Textarea
                      id='remarks'
                      rows='4'
                      value={couponDetails.remarks}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'remarks')
                      }
                      maxLength='128'
                      disabled={!isOnModify}
                    />
                  </FormItem>
                </Column>
              </Grid>
            </SectionBody>
          </Section>
        </SectionBody>

        {!isOnModify && (
          <DiscountCodeList id={id} couponDetails={couponDetails} />
        )}

        {!isOnModify && (
          <PromotionsAnalyze id={id} couponDetails={couponDetails} />
        )}
      </Section>

      <ButtonGroup alignRight>
        {isOnModify ? (
          <>
            <Button
              danger
              onClick={() => {
                setIsOnModify(false);
              }}
            >
              {t('button:Cancel')}
            </Button>
            <Button success onClick={handleModify}>
              {t('button:Save')}
            </Button>
          </>
        ) : (
          <>
            <Button onClick={() => navigate(-1)}>{t('button:Back')}</Button>
            {userInformation.permissions.includes('coupon.update') && (
              <Button
                warning
                onClick={() => {
                  setIsOnModify(true);
                }}
              >
                {t('button:Modify')}
              </Button>
            )}
          </>
        )}
      </ButtonGroup>
    </>
  );
};

const DiscountCodeList = ({ id, couponDetails }) => {
  const { t } = useTranslation();
  const { userInformation } = useContext(AuthenticateContext);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
  });
  const [listData, setListData] = useState([]);
  const [total, setTotal] = useState(1);
  const onPageChange = usePageChange(setListParams);
  const onLimitChange = useLimitChange(setListParams);

  const handleGetDiscountCodeList = useCallback(() => {
    const resquestData = {
      couponId: id,
      page: listParams.page,
      limit: listParams.limit,
    };

    handleApiResponse(getDiscountCodeList(resquestData), (response) => {
      const { total, list } = response.data.data;
      setTotal(total);
      setListData(list);
    });
  }, [listParams, id]);

  const handleRemove = useCallback(
    (id) => {
      handleApiResponse(deleteMemberCoupon(id), () => {
        showSuccessMessage({
          message: t('success:RemoveMemberCoupon'),
        });
        handleGetDiscountCodeList();
      });
    },
    [t, handleGetDiscountCodeList],
  );

  const handleResend = useCallback(
    (id) => {
      handleApiResponse(resendRedeemCode(id), () => {
        showSuccessMessage({
          message: t('success:ResendPromoCode'),
        });
        handleGetDiscountCodeList();
      });
    },
    [t, handleGetDiscountCodeList],
  );

  const generateActionDropdown = useCallback(
    ({ id }) => {
      return (
        <Dropdown>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem>
              <DropdownLinkOption to={`/coupon/redeem-code/${id}`}>
                <FiFileText />
                {t('Details')}
              </DropdownLinkOption>
            </DropdownItem>
            <DropdownItem onClick={() => handleRemove(id)}>
              <DropdownButtonOption>
                <MdDelete />
                {t('Remove')}
              </DropdownButtonOption>
            </DropdownItem>
            {couponDetails.couponCategory === 2 && (
              <DropdownItem onClick={() => handleResend(id)}>
                <DropdownButtonOption>
                  <MdSend />
                  {t('Resend')}
                </DropdownButtonOption>
              </DropdownItem>
            )}
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [t, couponDetails.couponCategory, handleRemove, handleResend],
  );

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: t('PromoCode'),
        fieldName: 'uniqueCode',
      },
      {
        title: t('Name'),
        fieldName: 'memberName',
        render(data) {
          return data ? data : '';
        },
      },
      {
        title: t('MemberPhone'),
        fieldName: 'memberPhone',
      },
      {
        title: t('MemberEmail'),
        fieldName: 'memberMail',
      },
      {
        title: t('Status'),
        fieldName: 'status',
        render(status) {
          return t(DISCOUNT_CODE[status].text);
        },
      },
      {
        title: t('HardwareSerialNumber'),
        fieldName: 'terminalSN',
      },
      {
        title: t('RedemptionTime'),
        fieldName: 'usageTime',
      },
    ];

    columns.push({
      title: t('Action'),
      custom: true,
      width: '10%',
      align: 'center',
      render(data) {
        return generateActionDropdown(data);
      },
    });

    return columns;
  }, [t, generateActionDropdown]);

  // Discount Code Export
  const showExport = () => {
    TitleModal({
      size: 'normal',
      children: <ExportModal id={id} />,
    });
  };

  // Coupon Distribution
  const showAssignPanel = () => {
    TitleModal({
      size: 'normal',
      children: (
        <AssignPanel id={id} getDiscountCodeList={handleGetDiscountCodeList} />
      ),
    });
  };

  useEffect(() => {
    handleGetDiscountCodeList();
  }, [handleGetDiscountCodeList]);

  return (
    <SectionBody>
      <Section backgroundReverse>
        <SectionHeader>
          <SectionToolbar>
            <SectionToolbarItem>
              <Heading2>
                {couponDetails.couponCategory === 1
                  ? t('CouponList')
                  : t('PromoCodeList')}
              </Heading2>
            </SectionToolbarItem>

            <SectionToolbarItem>
              <Button onClick={showExport} disabled={listData.length === 0}>
                {t('button:Export')}
              </Button>
              {couponDetails.couponCategory === 2 &&
                userInformation.permissions.includes('coupon.distribute') && (
                  <Button onClick={showAssignPanel}>
                    {t('button:Distribute')}
                  </Button>
                )}
            </SectionToolbarItem>
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            backgroundReverse
            columns={tableColumns}
            data={listData}
            currentPage={listParams.page}
            limit={listParams.limit}
            total={total}
            onPageChange={onPageChange}
            onLimitChange={onLimitChange}
          />
        </SectionBody>
      </Section>
    </SectionBody>
  );
};

const PromotionsAnalyze = ({ id, couponDetails }) => {
  const { t } = useTranslation();
  const [analyseData, setAnalyseData] = useState(null);

  const handleGetPromotionsAnalyze = useCallback(() => {
    const resquestData = {
      couponId: id,
    };

    handleApiResponse(getPromotionsAnalyze(resquestData), (response) => {
      const { data } = response.data;
      setAnalyseData(data);
    });
  }, [id]);

  useEffect(() => {
    handleGetPromotionsAnalyze();
  }, [handleGetPromotionsAnalyze]);

  return (
    <>
      <Section noPadding>
        <SectionBody>
          {analyseData !== null && (
            <Section backgroundReverse>
              <SectionHeader>
                <Heading2>{couponDetails.title}</Heading2>
              </SectionHeader>
              <SectionBody>
                <Grid columns={4}>
                  <Column desktop={2}>
                    <FormItem>
                      <Label htmlFor='totalAmount'>{t('TotalAmount')}</Label>
                      <Input
                        type='text'
                        id='totalAmount'
                        value={analyseData.totalAmount}
                        disabled
                      />
                    </FormItem>
                  </Column>
                  <Column desktop={2}>
                    <FormItem>
                      <Label htmlFor='totalDiscountedAmount'>
                        {t('TotalDiscountedAmount')}
                      </Label>
                      <Input
                        type='text'
                        id='totalDiscountedAmount'
                        value={analyseData.discountAmount}
                        disabled
                      />
                    </FormItem>
                  </Column>
                  <Column desktop={2}>
                    <FormItem>
                      <Label htmlFor='totalPaymentAmount'>
                        {t('TotalPaymentAmount')}
                      </Label>
                      <Input
                        type='text'
                        id='totalPaymentAmount'
                        value={analyseData.paymentAmount}
                        disabled
                      />
                    </FormItem>
                  </Column>
                </Grid>
              </SectionBody>
            </Section>
          )}
        </SectionBody>
      </Section>
    </>
  );
};

const ExportModal = ({ id, close }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(0);

  const handleCheckDiscountCodeLogStatus = (key) => {
    checkDiscountCodeLogStatus({ key })
      .then((response) => {
        if (response) {
          const { code } = response.data;
          const { url } = response.data.data;

          if (code === 0 && url === '') {
            setTimeout(() => {
              handleCheckDiscountCodeLogStatus(key);
            }, 10000);
          } else if (code === 0 && url !== '') {
            window.location.href = url;
          }
        }
      })
      .catch((error) => {
        showErrorMessage({ title: error.name, message: error.message });
      });
  };

  const handleExportLog = () => {
    exportDiscountCodeLog({
      status: status,
      couponId: +id,
    })
      .then((response) => {
        if (response) {
          const { key } = response.data.data;
          handleCheckDiscountCodeLogStatus(key);
        }
      })
      .catch((error) => {
        showErrorMessage({ title: error.name, message: error.message });
      });

    close();
  };

  return (
    <>
      <TitleModal.Header handleClose={close}>
        {t('ExportDiscountCodeLog')}
      </TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <FormItem>
          <Label>{t('Status')}</Label>
          <Select
            fullWidth
            allowSearch={false}
            options={DISCOUNT_CODE_STATUS}
            selected={status}
            onSelect={(status) => setStatus(status)}
            allowClear={false}
          />
        </FormItem>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Cancel')}
          </Button>
          <Button success onClick={handleExportLog}>
            {t('button:Export')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

const AssignPanel = ({ id, close, getDiscountCodeList }) => {
  const { t } = useTranslation();
  const [contactData, setContactData] = useState({
    name: '',
    phone: '',
    email: '',
  });
  const [importFile, setImportFile] = useState(false);
  const [cancelToken, setCancelToken] = useState(null);
  const [dropzoneState, setDropzoneState] = useState({
    isUploading: false,
    isRemoving: false,
  });
  const [addedFile, setAddedFile] = useState(false);

  const handleChangeValue = (value, key) => {
    const newData = { ...contactData };
    newData[key] = value;

    setContactData(newData);
  };
  const handleSend = () => {
    if (contactData.phone === '' && contactData.email === '') {
      showErrorMessage({ message: t('error:PleaseFillUpContent') });
      return;
    }

    if (contactData.phone && !isValidTelephone(contactData.phone.trim())) {
      showErrorMessage({ message: t('error:PleaseFillUpValidTelephone') });
      return;
    }

    if (contactData.email && !isValidEmail(contactData.email.trim())) {
      showErrorMessage({ message: t('error:PleaseFillUpValidEmailAddress') });
      return;
    }

    const resquestData = {
      couponId: +id,
    };
    const receiver = [];
    receiver.push(contactData);
    resquestData.receiver = receiver;

    handleApiResponse(assignDiscountCode(resquestData), (response) => {
      if (response) {
        showSuccessMessage({ message: t('success:Distribute') });
        getDiscountCodeList();
        close();
      }
    });
  };

  const handleDownloadTemplate = () => {
    handleApiResponse(downdloadDiscountCodeTemplate(), (response) => {
      exportXLSXFile(response.data);
    });
  };

  const handleDownloadErrorFile = useCallback(
    (message, errorFile) => {
      StateModal({
        type: 'error',
        title: message,
        text: t('error:DownloadErrorFile'),
        showCancelButton: true,
        onConfirm() {
          window.location.href = errorFile;
        },
      });
    },
    [t],
  );

  const handleAddedFile = useCallback(
    (file) => {
      const format = ['.csv', '.xls', '.xlsx'];
      const reg = file.name.match(/\.\w+$/);
      if (!format.includes(reg[0].toLowerCase())) {
        showErrorMessage({
          title: t('error:InputError'),
          message: t('error:ImportXLSXFileType'),
        });

        setDropzoneState((previous) => {
          return { ...previous, isRemoving: true };
        });
        return;
      }

      setAddedFile(true);
    },
    [t],
  );

  const handleRemovedFile = useCallback(() => {
    setAddedFile(false);
    setDropzoneState((previous) => {
      return { ...previous, isRemoving: false };
    });
  }, []);

  const handleCancelFile = useCallback(() => {
    cancelToken.cancel();
    setAddedFile(false);
    setDropzoneState((previous) => {
      return { ...previous, isUploading: false };
    });
  }, [cancelToken]);

  const handleUploading = useCallback(
    (chunk) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('couponId', +id);
        formData.append('file', chunk);

        ImportDiscountCodeList(formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          cancelToken: cancelToken.token,
          onUploadProgress: (progressEvent) => {
            if (progressEvent.loaded >= progressEvent.total) {
              resolve();
            }
          },
        })
          .then((response) => {
            if (response) {
              const { data, msg } = response.data;
              if (data && data.error_file) {
                handleDownloadErrorFile(msg, data.error_file);
                setDropzoneState((previous) => {
                  return { ...previous, isRemoving: true };
                });
              } else {
                showSuccessMessage({ message: t('success:Import') });
                close();
                getDiscountCodeList();
              }
            } else {
              setDropzoneState((previous) => {
                return { ...previous, isRemoving: true };
              });
            }
          })
          .catch((e) => {
            if (!axios.isCancel(e)) {
              reject();
              showErrorMessage({ title: e.title, message: e.message });
            }
          });
      });
    },
    [t, id, close, cancelToken, getDiscountCodeList, handleDownloadErrorFile],
  );

  const handleComplete = useCallback(() => {
    setDropzoneState((previous) => {
      return { ...previous, isUploading: false };
    });
  }, []);

  const handleError = useCallback(() => {
    setDropzoneState((previous) => {
      return { ...previous, isUploading: false };
    });
  }, []);

  return (
    <>
      <TitleModal.Header handleClose={close}>
        {t('CouponDistribution')}
      </TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <Grid columns={2}>
          {!importFile && (
            <>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='name'>{t('Name')}</Label>
                  <Input
                    type='text'
                    id='name'
                    value={contactData.name}
                    onChange={(event) => {
                      handleChangeValue(event.target.value, 'name');
                    }}
                    maxLength='255'
                  />
                </FormItem>
              </Column>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='phone'>{t('PhoneNumber')}</Label>
                  <Input
                    type='text'
                    id='phone'
                    value={contactData.phone}
                    onChange={(event) => {
                      handleChangeValue(event.target.value, 'phone');
                    }}
                    maxLength='20'
                  />
                </FormItem>
              </Column>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='email'>{t('EmailAddress')}</Label>
                  <Input
                    type='text'
                    id='email'
                    value={contactData.email}
                    onChange={(event) => {
                      handleChangeValue(event.target.value, 'email');
                    }}
                    maxLength='128'
                  />
                </FormItem>
              </Column>
            </>
          )}
          {importFile && (
            <Column desktop={2}>
              <FileDropzone
                message={t('DropzoneFile')}
                accept='.csv, .xls, .xlsx'
                translation={{
                  Cancel: t('Cancel'),
                  FileIcon: t('FileIcon'),
                  RemoveFile: t('RemoveFile'),
                  Retry: t('Retry'),
                }}
                chunking={false}
                state={dropzoneState}
                onAddedFile={handleAddedFile}
                onRemovedFile={handleRemovedFile}
                onCanceledFile={handleCancelFile}
                onUploading={handleUploading}
                onCompleted={handleComplete}
                onError={handleError}
              />
            </Column>
          )}
        </Grid>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button
            onClick={() => {
              setImportFile(!importFile);
            }}
          >
            {importFile ? t('button:Back') : t('button:Import')}
          </Button>
          {importFile && (
            <Button onClick={handleDownloadTemplate}>
              {t('button:DownloadTemplateFile')}
            </Button>
          )}
          <Button danger onClick={close}>
            {t('button:Cancel')}
          </Button>
          {!importFile && (
            <Button success onClick={handleSend}>
              {t('button:Distribute')}
            </Button>
          )}
          {importFile && (
            <Button
              success
              disabled={!addedFile}
              onClick={() => {
                setCancelToken(axios.CancelToken.source());
                setDropzoneState((previous) => {
                  return { ...previous, isUploading: true };
                });
              }}
            >
              {t('button:Import')}
            </Button>
          )}
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

const exportXLSXFile = (file) => {
  const exportedFilenmae =
    `coupon distribute template_1.0.xlsx` || 'export.xlsx';

  const link = document.createElement('a');
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(file);
    link.setAttribute('href', url);
    link.setAttribute('download', exportedFilenmae);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export default CouponDetail;
