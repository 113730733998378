// make chart bar seem like align left by pad empty bar at the end
const padEmptyChartBar = {
  id: 'padEmptyChartBar',
  length: -1,

  // pad empty chart bar when data less than max bar number
  beforeUpdate(chart) {
    this.length = -1;
    const { data, options } = chart.config._config;
    const validLabels = data.labels.filter((label) => label !== null);
    const { maxBarNumber } = options.plugins.padEmptyChartBar;
    for (let index = validLabels.length; index < maxBarNumber; index += 1) {
      this.length = this.length === -1 ? index : this.length;
      data.labels[index] = null;
      data.datasets[0].data[index] = 0;
    }
  },

  // prevent padded empty chart bar to be drawn
  afterUpdate(chart) {
    const { data, options } = chart.config._config;
    const { maxBarNumber } = options.plugins.padEmptyChartBar;
    if (this.length === -1) {
      // restore draw function
      for (let index = 0; index < maxBarNumber; index += 1) {
        // delete data.datasets[0]._meta[data.index].data[i].draw;
      }
    } else {
      // prevents new charts to be drawn
      for (let index = this.length; index < data.maxBarNumber; index += 1) {
        data.datasets[0]._meta[data.index].data[index].draw = () => {};
      }
    }
  },
};

export default padEmptyChartBar;
