import styled, { css } from 'styled-components';
import headerStyle from '../styles/header.style';

const SectionHeader = styled.div`
  background: transparent;
  padding: 0;
  margin-bottom: calc(var(--spacing-s) * -1);

  > * {
    padding-bottom: var(--spacing-s);
  }

  ${({ inline }) =>
    inline &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}

  ${({ flexStart }) => flexStart && 'justify-content: flex-start;'}

  ${({ border }) =>
    border && 'border-bottom: var(--border-width) solid var(--border-color);'}

  ${({ backgroundFill }) =>
    backgroundFill && 'background: var(--color-background1);'}

  ${({ backgroundReverse }) => {
    return backgroundReverse && 'background: var(--color-background2);';
  }}

  ${({ sticky }) =>
    sticky &&
    `position: sticky;
    z-index:30;
    top: ${headerStyle.height};
  `}
`;

export default SectionHeader;
