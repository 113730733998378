import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { FaFilter } from 'react-icons/fa';
import { IoMdOptions } from 'react-icons/io';
import { formatDate } from '../../utils/format';
import handleApiResponse from '../../utils/api/handleApiResponse';
import {
  getReportInfo,
  getReportDataList,
  exportReport,
  getExportReportResult,
} from '../../module/report';
import { getBottlerSimpleList } from '../../module/bottler';
import { getSalesCenterSimpleList } from '../../module/salesCenter';
import { downloadFile } from '../../module/download';
import { showErrorMessage } from '../../module/message';
import Loader from '../../components/animate/Loader';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionToolbarItem,
} from '../../components/section';
import { Heading1 } from '../../components/heading';
import Dropdown, { DropdownDivOption } from '../../components/dropdown';
import { Button, ButtonGroup } from '../../components/button';
import { Grid, Column } from '../../components/grid/index';
import { Checkbox, FormItem, Input, Label } from '../../components/form';
import { DatetimePickerV2 } from '../../components/datetimePicker';
import { Select } from '../../components/Select';
import { GridTable } from '../../components/table';
import {
  useLimitChange,
  usePageChange,
  useSortChange,
} from '../../components/table/hooks';

const ReportData = () => {
  const { t: trans } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [downloading, setDownloading] = useState(false);
  const [bottlerList, setBottlerList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [bottlerSimpleList, setBottlerSimpleList] = useState([]);
  const [fetchingSalesCenterList, setFetchingSalesCenterList] = useState(false);
  const [salesCenterList, setSalesCenterList] = useState({
    data: [],
    currentPage: 1,
    lastPage: 1,
  });
  const [salesCenterSimpleList, setSalesCenterSimpleList] = useState([]);
  const [information, setInformation] = useState({
    name: '',
    memo: '',
    fields: [],
  });
  const [filterParams, setFilterParams] = useState({
    start_time: formatDate(new Date().setHours(0, 0, 0, 0)),
    end_time: formatDate(new Date()),
    sn: '',
    bottlerId: '',
    saleCenterId: '',
    outletNumber: '',
    outletName: '',
    nameExtension: '',
    assetNumber: '',
  });
  const [listData, setListData] = useState(null);
  const [total, setTotal] = useState(1);
  const [listParams, setListParams] = useState({
    searched: false,
    page: 1,
    limit: 10,
    start_time: '',
    end_time: '',
    sn: '',
    bottlerId: '',
    saleCenterId: '',
    outletNumber: '',
    outletName: '',
    nameExtension: '',
    assetNumber: '',
    sortColumn: 'sn',
    sortType: 'asc',
  });
  const [showFilter, setShowFilter] = useState(true);
  const [tableOptions, setTableOptions] = useState([]);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);
  const onSortChange = useSortChange(
    setListParams,
    listParams.sortColumn,
    listParams.sortType,
  );

  const handleGetBottlerList = useCallback((currentPage) => {
    const parameters = {
      page: currentPage,
      limit: 100,
    };
    handleApiResponse(getBottlerSimpleList(parameters), (response) => {
      const { list, total } = response.data.data;

      setBottlerList((previous) => {
        const newBottlerList = [...previous.data, ...list];

        return {
          data: newBottlerList,
          currentPage: currentPage + 1,
          lastPage: Math.ceil(total / 100),
        };
      });
    });
  }, []);

  const handleGetSalesCenterList = useCallback((bottlerId, currentPage) => {
    const parameters = {
      page: currentPage,
    };
    handleApiResponse(
      getSalesCenterSimpleList(bottlerId, parameters),
      (response) => {
        const { list, limit, total } = response.data.data;

        setSalesCenterList((previous) => {
          const newSalesCenterList = [...previous.data, ...list];

          return {
            data: newSalesCenterList,
            currentPage: currentPage + 1,
            lastPage: Math.ceil(total / limit),
          };
        });
        setFetchingSalesCenterList(false);
      },
    );
  }, []);

  const handleGetReportData = useCallback(() => {
    if (listParams.start_time > formatDate(new Date())) {
      showErrorMessage({
        message: trans('error:StartTimeCannotExceedCurrentTime'),
      });
      return;
    }

    if (listParams.start_time > listParams.end_time) {
      showErrorMessage({
        message: trans('error:StartTimeCannotExceedEndTime'),
      });
      return;
    }

    const requestData = {
      reportTemplateId: id,
      page: listParams.page,
      limit: listParams.limit,
      dateRange: `${listParams.start_time}/${listParams.end_time}`,
      sortColumn: listParams.sortColumn,
      sortType: listParams.sortType,
    };
    if (listParams.sn.trim()) {
      requestData.sn = listParams.sn.trim();
    }
    if (listParams.bottlerId) {
      requestData.bottlerId = listParams.bottlerId;
    }
    if (listParams.saleCenterId) {
      requestData.saleCenterId = listParams.saleCenterId;
    }
    if (listParams.outletNumber.trim()) {
      requestData.outletNumber = listParams.outletNumber.trim();
    }
    if (listParams.outletName.trim()) {
      requestData.outletName = listParams.outletName.trim();
    }
    if (listParams.nameExtension.trim()) {
      requestData.nameExtension = listParams.nameExtension.trim();
    }
    if (listParams.assetNumber.trim()) {
      requestData.assetNumber = listParams.assetNumber.trim();
    }

    handleApiResponse(getReportDataList(requestData), (response) => {
      const { total, list } = response.data.data;
      setListData(
        list.map(
          ({
            sn,
            bottlerName,
            saleCenterName,
            outletNumber,
            outletName,
            nameExtension,
            assetNumber,
            locationName,
            reportTime,
            reportData,
          }) => {
            const option = {
              sn,
              bottlerName,
              saleCenterName,
              outletNumber,
              outletName,
              nameExtension,
              assetNumber,
              locationName,
              reportTime,
              ...reportData,
            };
            return option;
          },
        ),
      );
      setTotal(total);
    });
  }, [trans, id, listParams]);

  const handleChangeValue = (key, value) => {
    setFilterParams((previous) => {
      if (key === 'end_time' && value <= previous.start_time) {
        return previous;
      }

      return { ...previous, [key]: value };
    });
  };

  const handleToggleOptions = (index) => {
    tableOptions[index].checked = tableOptions[index].checked === 1 ? 0 : 1;
    setTableOptions([...tableOptions]);
  };

  const handleDownloadFile = (key) => {
    handleApiResponse(getExportReportResult(key), (response) => {
      const { code, message, data } = response.data;

      if (code === 0) {
        const { url } = data;
        handleApiResponse(downloadFile(url), (response) => {
          const urlSegment = url.split('/');
          const fileName = urlSegment[urlSegment.length - 1];
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
        setDownloading(false);
      } else if (code === 200) {
        // processing
        setTimeout(() => {
          handleDownloadFile(key);
        }, 3000);
      } else {
        // error
        showErrorMessage({
          message: message,
        });
        setDownloading(false);
      }
    });
  };

  const handleExport = () => {
    const requestData = {
      reportTemplateId: id,
      dateRange: `${listParams.start_time}/${listParams.end_time}`,
      sortColumn: listParams.sortColumn,
      sortType: listParams.sortType,
    };
    if (listParams.sn.trim()) {
      requestData.sn = listParams.sn.trim();
    }
    if (listParams.bottlerId) {
      requestData.bottlerId = listParams.bottlerId;
    }
    if (listParams.saleCenterId) {
      requestData.saleCenterId = listParams.saleCenterId;
    }
    if (listParams.outletNumber.trim()) {
      requestData.outletNumber = listParams.outletNumber.trim();
    }
    if (listParams.outletName.trim()) {
      requestData.outletName = listParams.outletName.trim();
    }
    if (listParams.nameExtension.trim()) {
      requestData.nameExtension = listParams.nameExtension.trim();
    }
    if (listParams.assetNumber.trim()) {
      requestData.assetNumber = listParams.assetNumber.trim();
    }

    handleApiResponse(exportReport(requestData), (response) => {
      const { key } = response.data.data;
      setDownloading(true);
      handleDownloadFile(key);
    });
  };

  const tableColumns = useMemo(() => {
    const columns = tableOptions
      .filter((option) => {
        return option.checked === 1;
      })
      .map((option) => {
        return option.key;
      });

    return information.fields.filter((column) => {
      return columns.includes(column.keyName);
    });
  }, [tableOptions, information.fields]);

  const handleClickSearch = useCallback(() => {
    for (let i in filterParams) {
      if (filterParams[i] !== listParams[i]) {
        setListParams((previousParams) => {
          return {
            ...previousParams,
            searched: true,
            page: 1,
            limit: 10,
            start_time: filterParams.start_time,
            end_time: filterParams.end_time,
            sn: filterParams.sn,
            bottlerId: filterParams.bottlerId,
            saleCenterId: filterParams.saleCenterId,
            outletNumber: filterParams.outletNumber,
            outletName: filterParams.outletName,
            nameExtension: filterParams.nameExtension,
            assetNumber: filterParams.assetNumber,
            sortColumn: 'sn',
            sortType: 'asc',
          };
        });
        break;
      }
    }
  }, [filterParams, listParams]);

  const handleClickReset = () => {
    setTableOptions(
      information.fields.map((column) => {
        return { key: column.keyName, text: column.displayName, checked: 1 };
      }),
    );
    setFilterParams({
      start_time: formatDate(new Date().setHours(0, 0, 0, 0)),
      end_time: formatDate(new Date()),
      sn: '',
      bottlerId: '',
      saleCenterId: '',
      outletNumber: '',
      outletName: '',
      nameExtension: '',
      assetNumber: '',
    });
    setListData(null);
  };

  useEffect(() => {
    if (!listParams.searched) return;
    handleGetReportData();
  }, [handleGetReportData, listParams.searched]);

  useEffect(() => {
    handleApiResponse(getReportInfo(id), (response) => {
      const { name, memo, fields } = response.data.data;
      setInformation({
        name,
        memo,
        fields: [
          {
            id: 'sn',
            displayName: trans('HardwareSerialNumber'),
            displayWidth: 300,
            keyName: 'sn',
          },
          {
            id: 'bottlerName',
            displayName: trans('Bottler'),
            displayWidth: 300,
            keyName: 'bottlerName',
          },
          {
            id: 'saleCenterName',
            displayName: trans('SalesCenter'),
            displayWidth: 300,
            keyName: 'saleCenterName',
          },
          {
            id: 'outletNumber',
            displayName: trans('OutletNumber'),
            displayWidth: 300,
            keyName: 'outletNumber',
          },
          {
            id: 'outletName',
            displayName: trans('OutletName'),
            displayWidth: 300,
            keyName: 'outletName',
          },
          {
            id: 'nameExtension',
            displayName: trans('NameExtension'),
            displayWidth: 300,
            keyName: 'nameExtension',
          },
          {
            id: 'assetNumber',
            displayName: trans('AssetNumber'),
            displayWidth: 300,
            keyName: 'assetNumber',
          },
          {
            id: 'locationName',
            displayName: trans('Location'),
            displayWidth: 300,
            keyName: 'locationName',
          },
          {
            id: 'reportTime',
            displayName: trans('ReportTime'),
            displayWidth: 300,
            keyName: 'reportTime',
          },
          ...fields,
        ],
      });
    });
  }, [trans, id]);

  useEffect(() => {
    setTableOptions(
      information.fields.map((column) => {
        return { key: column.keyName, text: column.displayName, checked: 1 };
      }),
    );
  }, [information.fields]);

  useEffect(() => {
    if (bottlerList.currentPage <= bottlerList.lastPage) {
      handleGetBottlerList(bottlerList.currentPage);
    } else {
      setBottlerSimpleList(
        bottlerList.data.map((bottler) => {
          return { id: bottler.id, text: bottler.name };
        }),
      );
    }
  }, [
    handleGetBottlerList,
    bottlerList.data,
    bottlerList.currentPage,
    bottlerList.lastPage,
  ]);

  useEffect(() => {
    if (!filterParams.bottlerId) return;
    if (fetchingSalesCenterList) return;

    if (salesCenterList.currentPage <= salesCenterList.lastPage) {
      handleGetSalesCenterList(
        filterParams.bottlerId,
        salesCenterList.currentPage,
      );
      setFetchingSalesCenterList(true);
    } else {
      setSalesCenterSimpleList(
        salesCenterList.data.map((salesCenter) => {
          return { id: salesCenter.id, text: salesCenter.name };
        }),
      );
    }
  }, [
    filterParams.bottlerId,
    fetchingSalesCenterList,
    handleGetSalesCenterList,
    salesCenterList.data,
    salesCenterList.currentPage,
    salesCenterList.lastPage,
  ]);

  // reset sales center related state
  useEffect(() => {
    setSalesCenterList({
      data: [],
      currentPage: 1,
      lastPage: 1,
    });
    setSalesCenterSimpleList([]);
    setFilterParams((previous) => {
      return {
        ...previous,
        saleCenterId: '',
      };
    });
  }, [filterParams.bottlerId]);

  // clear outlet state when sales center changed
  useEffect(() => {
    if (!filterParams.saleCenterId) {
      setFilterParams((previous) => {
        return {
          ...previous,
          outletNumber: '',
          outletName: '',
          nameExtension: '',
          assetNumber: '',
        };
      });
    }
  }, [filterParams.saleCenterId]);

  return (
    <>
      <Loader $loading={downloading} />
      <Section noPadding>
        <SectionHeader inline>
          <Heading1>
            {trans('Report')} - {information.name}
          </Heading1>
          <SectionToolbarItem>
            <Button
              secondary
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <FaFilter />
              {trans('Filter')}
            </Button>

            <Dropdown>
              <Dropdown.Toggle>
                <Button secondary>
                  <IoMdOptions />
                  {trans('Columns')}
                </Button>
              </Dropdown.Toggle>
              <Dropdown.Content>
                {tableOptions.map((option, index) => (
                  <DropdownDivOption
                    key={index}
                    onClick={() => handleToggleOptions(index)}
                  >
                    <Checkbox
                      onChange={() => handleToggleOptions(index)}
                      htmlFor={option.text}
                      noMargin
                      checked={option.checked}
                      text={trans(`${option.text}`)}
                    />
                  </DropdownDivOption>
                ))}
              </Dropdown.Content>
            </Dropdown>
          </SectionToolbarItem>
        </SectionHeader>

        <SectionBody noPadding>
          <Section noPadding>
            <SectionBody>
              {showFilter && (
                <Section backgroundReverse>
                  <Grid columns={2}>
                    <Column desktop={1} tablet={2}>
                      <FormItem>
                        <Label htmlFor='startTime'>{trans('StartTime')}</Label>
                        <DatetimePickerV2
                          selected={filterParams.start_time}
                          onConfirm={(date) => {
                            handleChangeValue('start_time', date);
                          }}
                        />
                      </FormItem>
                    </Column>
                    <Column desktop={1} tablet={2}>
                      <FormItem>
                        <Label htmlFor='endTime'>{trans('EndTime')}</Label>
                        <DatetimePickerV2
                          selected={filterParams.end_time}
                          onConfirm={(date) => {
                            handleChangeValue('end_time', date);
                          }}
                          minDate={formatDate(
                            new Date(filterParams.start_time).setSeconds(1),
                          )}
                        />
                      </FormItem>
                    </Column>
                    <Column desktop={1} tablet={2}>
                      <FormItem>
                        <Label htmlFor='bottler'>{trans('Bottler')}</Label>
                        <Select
                          fullWidth
                          allowClear={true}
                          options={bottlerSimpleList}
                          selected={filterParams.bottlerId}
                          onSelect={(id) => {
                            handleChangeValue('bottlerId', id);
                          }}
                        />
                      </FormItem>
                    </Column>
                    {filterParams.bottlerId && (
                      <Column desktop={1} tablet={2}>
                        <FormItem>
                          <Label htmlFor='salesCenter'>
                            {trans('SalesCenter')}
                          </Label>
                          <Select
                            fullWidth
                            allowClear={true}
                            options={salesCenterSimpleList}
                            selected={filterParams.saleCenterId}
                            onSelect={(id) => {
                              handleChangeValue('saleCenterId', id);
                            }}
                          />
                        </FormItem>
                      </Column>
                    )}
                    {filterParams.saleCenterId && (
                      <>
                        <Column desktop={1} tablet={2}>
                          <FormItem>
                            <Label htmlFor='outletNumber'>
                              {trans('OutletNumber')}
                            </Label>
                            <Input
                              type='text'
                              id='outletNumber'
                              value={filterParams.outletNumber}
                              onChange={(event) =>
                                handleChangeValue(
                                  'outletNumber',
                                  event.target.value,
                                )
                              }
                            />
                          </FormItem>
                        </Column>
                        <Column desktop={1} tablet={2}>
                          <FormItem>
                            <Label htmlFor='outletName'>
                              {trans('OutletName')}
                            </Label>
                            <Input
                              type='text'
                              id='outletName'
                              value={filterParams.outletName}
                              onChange={(event) =>
                                handleChangeValue(
                                  'outletName',
                                  event.target.value,
                                )
                              }
                            />
                          </FormItem>
                        </Column>
                        <Column desktop={1} tablet={2}>
                          <FormItem>
                            <Label htmlFor='nameExtension'>
                              {trans('NameExtension')}
                            </Label>
                            <Input
                              type='text'
                              id='nameExtension'
                              value={filterParams.nameExtension}
                              onChange={(event) =>
                                handleChangeValue(
                                  'nameExtension',
                                  event.target.value,
                                )
                              }
                            />
                          </FormItem>
                        </Column>
                        <Column desktop={1} tablet={2}>
                          <FormItem>
                            <Label htmlFor='assetNumber'>
                              {trans('AssetNumber')}
                            </Label>
                            <Input
                              type='text'
                              id='assetNumber'
                              value={filterParams.assetNumber}
                              onChange={(event) =>
                                handleChangeValue(
                                  'assetNumber',
                                  event.target.value,
                                )
                              }
                            />
                          </FormItem>
                        </Column>
                      </>
                    )}
                    <Column desktop={1} tablet={2}>
                      <FormItem>
                        <Label htmlFor='hardwareSerialNumber'>
                          {trans('HardwareSerialNumber')}
                        </Label>
                        <Input
                          type='text'
                          id='hardwareSerialNumber'
                          value={filterParams.sn}
                          onChange={(event) =>
                            handleChangeValue('sn', event.target.value)
                          }
                        />
                      </FormItem>
                    </Column>
                  </Grid>
                  <ButtonGroup alignCenter>
                    <Button onClick={handleClickSearch}>
                      {trans('button:Search')}
                    </Button>
                    <Button danger onClick={handleClickReset}>
                      {trans('button:Reset')}
                    </Button>
                  </ButtonGroup>
                </Section>
              )}

              {listData !== null && (
                <Section backgroundReverse>
                  <SectionBody noPadding>
                    <GridTable
                      columns={tableColumns.map((item) => {
                        const column = {
                          title: item.displayName,
                          fieldName: item.keyName,
                          width: `${item.displayWidth}px`,
                          render(data) {
                            if (data === undefined) {
                              return '';
                            }

                            return data;
                          },
                        };
                        if (item.keyName === 'sn') {
                          column.sortable = true;
                        }
                        return column;
                      })}
                      data={listData}
                      currentPage={listParams.page}
                      limit={listParams.limit}
                      total={total}
                      onPageChange={onPageChange}
                      onLimitChange={onLimitChange}
                      onSortChange={onSortChange}
                      sortColumn={listParams.sortColumn}
                      sortType={listParams.sortType}
                      translation={{
                        info: trans('table.info'),
                        empty: trans('table.empty'),
                      }}
                    />
                  </SectionBody>
                </Section>
              )}
            </SectionBody>
          </Section>
          <ButtonGroup alignRight>
            <Button onClick={handleExport}>{trans('button:Export')}</Button>
            <Button
              danger
              onClick={() => {
                navigate(-1);
              }}
            >
              {trans('button:Back')}
            </Button>
          </ButtonGroup>
        </SectionBody>
      </Section>
    </>
  );
};

export default ReportData;
