import styled from 'styled-components';
import { FaSortUp, FaSortDown } from 'react-icons/fa';

const TableSort = styled.div`
  position: relative;

  > svg {
    color: var(--color-primary);
    position: absolute;
  }
`;

const UpIcon = styled(FaSortUp)`
  top: -2px;
  opacity: ${({ $active }) => ($active ? 1 : 0.5)};
`;

const DownIcon = styled(FaSortDown)`
  opacity: ${({ $active }) => ($active ? 1 : 0.5)};
`;

export default Object.assign(TableSort, {
  UpIcon,
  DownIcon,
});
