import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AuthenticateContext from '../../../provider/context/authenticate.context';
import { modifyRole } from '../../../module/role';
import { showSuccessMessage, showErrorMessage } from '../../../module/message';
import handleApiResponse from '../../../utils/api/handleApiResponse';
import { Section, SectionBody } from '../../../components/section';
import { FormItem, Input, Label, Textarea } from '../../../components/form';
import { Button, ButtonGroup } from '../../../components/button';

const RoleInformation = ({
  roleId,
  informationData,
  setInformationData,
  savedInformationData,
  handleGetRoleDetails,
}) => {
  const { t: trans } = useTranslation();
  const navigate = useNavigate();
  const { userInformation } = useContext(AuthenticateContext);
  const [isOnModify, setIsOnModify] = useState(false);

  const handleChangeValue = (value, key) => {
    const newData = { ...informationData };
    newData[key] = value;

    setInformationData(newData);
  };

  const handleCancel = () => {
    setInformationData(savedInformationData);
    setIsOnModify(false);
  };

  const handleModify = () => {
    const trimmedName = informationData.name.trim();
    if (!trimmedName) {
      showErrorMessage({ message: trans('error:PleaseFillUpName') });
      return;
    }

    const requestData = {
      name: trimmedName,
      remark: informationData.remark.trim(),
    };
    handleApiResponse(modifyRole(roleId, requestData), () => {
      showSuccessMessage({ message: trans('success:ModifyRole') });
      setIsOnModify(false);
      handleGetRoleDetails();
    });
  };

  return (
    <>
      <Section backgroundReverse>
        <SectionBody noPadding>
          <FormItem>
            <Label htmlFor='name' required={isOnModify}>
              {trans('Name')}
            </Label>
            <Input
              type='text'
              id='name'
              value={informationData.name}
              onChange={(event) =>
                handleChangeValue(event.target.value, 'name')
              }
              disabled={!isOnModify}
            />
          </FormItem>
          <FormItem>
            <Label htmlFor='description'>{trans('Description')}</Label>
            <Textarea
              id='description'
              rows='4'
              value={informationData.remark}
              onChange={(event) =>
                handleChangeValue(event.target.value, 'remark')
              }
              disabled={!isOnModify}
            />
          </FormItem>
        </SectionBody>
      </Section>

      <ButtonGroup alignRight>
        {isOnModify ? (
          <>
            <Button danger onClick={handleCancel}>
              {trans('button:Cancel')}
            </Button>
            <Button success onClick={handleModify}>
              {trans('button:Save')}
            </Button>
          </>
        ) : (
          <>
            <Button danger onClick={() => navigate(-1)}>
              {trans('button:Back')}
            </Button>
            {userInformation.permissions.includes('role.update') && (
              <Button
                warning
                onClick={() => {
                  setIsOnModify(true);
                }}
              >
                {trans('button:Modify')}
              </Button>
            )}
          </>
        )}
      </ButtonGroup>
    </>
  );
};

export default RoleInformation;
