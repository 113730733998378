import { usePopper } from 'react-popper';

const useCustomPopper = (
  targetElement,
  popperElement,
  {
    strategy = 'absolute',
    arrowElement = null,
    boundary = 'clippingParents',
  } = {},
) => {
  return usePopper(targetElement, popperElement, {
    strategy,
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        enabled: true,
        options: {
          padding: 8,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          boundary,
          padding: 8,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
    ],
  });
};

export default useCustomPopper;
