import { callApi } from '../services/api';

const getBottlerList = (data) => {
  return callApi.get('/bottlers', { params: data });
};

const getBottlerSimpleList = (data) => {
  return callApi.get('/bottlers/simple', { params: data });
};

const createBottlerGroup = (data) => {
  return callApi.post('/bottlers', data);
};

const getBottlerGroupDetails = (id) => {
  return callApi.get(`/bottlers/${id}`);
};

const modifyBottlerGroup = (id, data) => {
  return callApi.patch(`/bottlers/${id}`, data);
};

const batchDeleteBottlerGroup = (ids) => {
  return callApi.post('/bottlers/delete-batch', {
    id: ids,
  });
};

const deleteBottlerGroup = (id) => {
  return callApi.delete(`/bottlers/${id}`);
};

export {
  getBottlerList,
  getBottlerSimpleList,
  createBottlerGroup,
  getBottlerGroupDetails,
  modifyBottlerGroup,
  batchDeleteBottlerGroup,
  deleteBottlerGroup,
};
