import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';
import { getPermissionsList, createRole } from '../../module/role';
import { showSuccessMessage, showErrorMessage } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import {
  Section,
  SectionBody,
  SectionHeader,
  SectionToolbar,
} from '../../components/section';
import { Heading1, Heading2 } from '../../components/heading';
import { Wizard } from '../../components/wizard';
import {
  FormItem,
  Input,
  Label,
  Textarea,
  Checkbox,
} from '../../components/form';
import { Button, ButtonGroup } from '../../components/button';
import { Tooltip } from '../../components/tooltip';
import Collapse from '../../components/animate/Collapse';
import { PermissionTable } from '../../components/table';

const CreateRole = () => {
  const { t: trans } = useTranslation();
  const navigate = useNavigate();
  const [informationData, setInformationData] = useState({
    name: '',
    remark: '',
  });
  const [permissionIDs, setPermissionIDs] = useState([]);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSubmit = () => {
    const trimmedName = informationData.name.trim();
    if (!trimmedName) {
      showErrorMessage({ message: trans('error:PleaseFillUpName') });
      return;
    }

    if (permissionIDs.length <= 0) {
      showErrorMessage({ message: trans('error:PleaseSelectPermissions') });
      return;
    }

    const requestData = {
      name: trimmedName,
      permissionIds: permissionIDs,
    };
    if (informationData.remark.trim()) {
      requestData.remark = informationData.remark;
    }

    handleApiResponse(createRole(requestData), () => {
      showSuccessMessage({ message: trans('success:CreateRole') });
      navigate(-1);
    });
  };

  return (
    <Section noPadding>
      <SectionHeader>
        <Heading1>{trans('CreateRole')}</Heading1>
      </SectionHeader>
      <SectionBody>
        <Wizard navNames={[trans('Information'), trans('Permissions')]}>
          <StepInformation
            informationData={informationData}
            setInformationData={setInformationData}
            handleCancel={handleCancel}
          />
          <StepPermissions
            permissionIDs={permissionIDs}
            setPermissionIDs={setPermissionIDs}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
          />
        </Wizard>
      </SectionBody>
    </Section>
  );
};

const StepInformation = ({
  informationData,
  setInformationData,
  handleCancel,
  nextStep,
}) => {
  const { t: trans } = useTranslation();

  const handleChangeValue = (value, key) => {
    const newData = { ...informationData };
    newData[key] = value;

    setInformationData(newData);
  };

  return (
    <>
      <Section backgroundReverse>
        <SectionBody noPadding>
          <FormItem>
            <Label htmlFor='name' required>
              {trans('Name')}
            </Label>
            <Input
              type='text'
              id='name'
              value={informationData.name}
              onChange={(event) =>
                handleChangeValue(event.target.value, 'name')
              }
            />
          </FormItem>
          <FormItem>
            <Label htmlFor='description'>{trans('Description')}</Label>
            <Textarea
              id='description'
              rows='4'
              value={informationData.remark}
              onChange={(event) =>
                handleChangeValue(event.target.value, 'remark')
              }
            />
          </FormItem>
        </SectionBody>
      </Section>

      <ButtonGroup alignRight>
        <Button danger onClick={handleCancel} id='cancelButton'>
          {trans('button:Cancel')}
        </Button>
        <Button success onClick={nextStep}>
          {trans('button:Next')}
        </Button>
      </ButtonGroup>
    </>
  );
};

const StepPermissions = ({
  permissionIDs,
  setPermissionIDs,
  handleCancel,
  handleSubmit,
  previousStep,
}) => {
  const { t: trans } = useTranslation();
  const [selectedModules, setSelectedModules] = useState([]);
  const [permissionList, setPermissionList] = useState([]);

  const handleSelectModule = (moduleName) => {
    const targetModule = permissionList.filter((module) => {
      return module.menuName === moduleName;
    });

    if (targetModule.length > 0) {
      setPermissionIDs((previous) => {
        const newPermissionIDs = [...previous];
        const index = selectedModules.indexOf(moduleName);
        if (index === -1) {
          // select module
          targetModule.forEach((module) => {
            module.permissions.forEach((permission) => {
              if (!newPermissionIDs.includes(permission.id)) {
                newPermissionIDs.push(permission.id);
              }
            });
          });

          setSelectedModules([...selectedModules, moduleName]);
        } else {
          // deselect module
          targetModule.forEach((module) => {
            module.permissions.forEach((permission) => {
              const index = newPermissionIDs.indexOf(permission.id);
              if (index !== -1) {
                newPermissionIDs.splice(index, 1);
              }
            });
          });

          const newSelectedModules = [...selectedModules];
          newSelectedModules.splice(index, 1);
          setSelectedModules(newSelectedModules);
        }

        return newPermissionIDs;
      });
    }
  };

  const handleSelectFeature = (moduleName, featureName) => {
    const targetModule = permissionList.filter((module) => {
      return module.menuName === moduleName;
    });
    if (targetModule.length > 0) {
      setPermissionIDs((previous) => {
        const newPermissionIDs = [...previous];

        targetModule.forEach((module) => {
          module.permissions.forEach((permission) => {
            if (
              permission.actionName === featureName &&
              !newPermissionIDs.includes(permission.id)
            ) {
              newPermissionIDs.push(permission.id);
            }
          });
        });

        return newPermissionIDs;
      });
    }
  };

  const handleTogglePermission = (id) => {
    setPermissionIDs((previous) => {
      const newPermissionIDs = [...previous];
      const index = newPermissionIDs.indexOf(id);
      if (index !== -1) {
        newPermissionIDs.splice(index, 1);
      } else {
        newPermissionIDs.push(id);
      }

      return newPermissionIDs;
    });
  };

  useEffect(() => {
    handleApiResponse(getPermissionsList(), (response) => {
      const { list } = response.data.data;
      setPermissionList(list);
    });
  }, []);

  return (
    <>
      {permissionList.map((module) => {
        return (
          <PermissionSection
            key={module.id}
            permissionIDs={permissionIDs}
            module={module}
            selectedModules={selectedModules}
            handleSelectModule={handleSelectModule}
            handleSelectFeature={handleSelectFeature}
            handleTogglePermission={handleTogglePermission}
          />
        );
      })}

      <ButtonGroup alignRight>
        <Button secondary onClick={previousStep}>
          {trans('button:Previous')}
        </Button>
        <Button danger onClick={handleCancel}>
          {trans('button:Cancel')}
        </Button>
        <Button success onClick={handleSubmit}>
          {trans('button:Create')}
        </Button>
      </ButtonGroup>
    </>
  );
};

const PermissionSection = ({
  permissionIDs,
  module,
  selectedModules,
  handleSelectModule,
  handleSelectFeature,
  handleTogglePermission,
}) => {
  const { t: trans } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <Section backgroundReverse>
      <SectionHeader inline>
        <Heading2>
          <Checkbox
            htmlFor={module.menuName}
            text={module.menuName}
            checked={selectedModules.includes(module.menuName)}
            onChange={() => handleSelectModule(module.menuName)}
            fontSizeBig
          />
        </Heading2>
        <SectionToolbar>
          {show ? (
            <Tooltip title={trans('Hide')}>
              <Button reverse onClick={() => setShow(!show)} iconOnly>
                <MdKeyboardArrowDown />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={trans('Show')}>
              <Button reverse onClick={() => setShow(!show)} iconOnly>
                <MdKeyboardArrowRight />
              </Button>
            </Tooltip>
          )}
        </SectionToolbar>
      </SectionHeader>
      <Collapse in={show} duration={500}>
        <SectionBody>
          <PermissionTable>
            <PermissionTableBody
              permissionIDs={permissionIDs}
              permissions={module.permissions}
              handleSelectFeature={(featureName) => {
                handleSelectFeature(module.menuName, featureName);
              }}
              handleTogglePermission={handleTogglePermission}
            />
          </PermissionTable>
        </SectionBody>
      </Collapse>
    </Section>
  );
};

const PermissionTableBody = ({
  permissionIDs,
  permissions,
  handleSelectFeature,
  handleTogglePermission,
}) => {
  return (
    <tbody>
      {permissions.map((permission) => {
        return (
          <tr key={permission.actionName}>
            <PermissionTable.TD
              clickable
              bold
              onClick={() => handleSelectFeature(permission.actionName)}
            >
              {permission.actionName}
            </PermissionTable.TD>
            <PermissionTable.TD alignCenter key={permission.id}>
              <Checkbox
                htmlFor={permission.id}
                checked={permissionIDs.includes(permission.id)}
                onChange={() => handleTogglePermission(permission.id)}
              />
            </PermissionTable.TD>
          </tr>
        );
      })}
    </tbody>
  );
};

export default CreateRole;
