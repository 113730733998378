import { toast } from 'react-toastify';
import MessageContainer from '../components/toast/MessageContainer';

const showSuccessMessage = ({ title, message, containHTML = false }) => {
  toast.success(
    <MessageContainer
      title={title}
      message={message}
      containHTML={containHTML}
    />,
  );
};

const showErrorMessage = ({ title, message, containHTML = false }) => {
  toast.error(
    <MessageContainer
      title={title}
      message={message}
      containHTML={containHTML}
    />,
  );
};

export { showSuccessMessage, showErrorMessage };
