const getBase64DataUrlFromFile = (file, callback) => {
  const reader = new FileReader();

  reader.onload = function () {
    callback(reader.result);
  };

  reader.readAsDataURL(file);
};

export default getBase64DataUrlFromFile;
